import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { FamilyService } from '../../../core/api/family.service';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { Family } from '../../../core/models/family.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '../../../core/services/form-helper.service';
import { ToastrMessageType, ToastrService } from '../../../core/services/toastr.service';
import { RouteNameService } from '../../../core/services/route-name.service';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { ItemTypeService } from '../../../core/api/item-type.service';
import { AuthService } from '../../../core/api/auth.service';
import { RightEnum } from '../../../core/enum/right.enum';

@Component({
  selector: 'esomus-family',
  templateUrl: './family.component.html',
  styleUrls: ['./family.component.sass']
})
export class FamilyComponent implements OnInit {

  entityForm: FormGroup;
  fieldType = FieldType;
  family: Family;

  itemTypeOptions: EntityOption;

  showDeleteBtns: boolean;

  constructor(
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private familyService: FamilyService,
    private toastrService: ToastrService,
    private itemTypeService: ItemTypeService,
    private cd: ChangeDetectorRef,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      label: [null, [Validators.required]],
      comment: [null],
      'picture.upload': [],
      code: [null],
      itemType: [null, [Validators.required]]
    });

    const familyID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);
    if (!isNaN(familyID)) {
      this.authService.fetchPageRights({familyId: familyID}).subscribe(() => {
        this._getFamily(familyID);

        this.showDeleteBtns = this.authService.hasRight(RightEnum.FAMILY_ADMINISTER);
      });
    } else {
      this.itemTypeOptions = {get: () => this.itemTypeService.findAllEquipments(), propName: 'label'};

      this.family = new Family();
    }
  }

  private _getFamily(familyID: number) {
    this.familyService.find(familyID)
      .subscribe((family: Family) => {
        this.family = family;

        FormHelper.initValues(this.family, this.entityForm);
        if (!this.family.itemType || [6, 7, 8].indexOf(this.family.itemType.value) !== -1) {
          this.itemTypeOptions = {get: () => this.itemTypeService.findAllEquipments(), propName: 'label'};
          this.entityForm.addControl('itemType', this.fb.control({}));
        }
        this.cd.detectChanges();
      });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }
    let entity = FormHelper.buildEntity(this.family, this.entityForm, {
      'picture.upload': {type: FieldType.FILE, multiple: false}
    }) as Family;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      ((entity.id) ? this.familyService.put(entity) : this.familyService.post(entity)),
      (result: Family) => {
        this.entityForm.reset();
        this.toastrService.open((entity.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        this.routeNameService.goTo('family_view', {id: result.id});
      }
    );
  }

  getFamilyDeleteURL() {
    return this.familyService.delete(this.family.id);
  }

  getFamilySuccessURL() {
    return this.routeNameService.path('families');
  }
}

