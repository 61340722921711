import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'esomus-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  @Input() currentPage: string;
  @Input() previousPages: BreadcrumbLink[] = [];

  constructor() { }

  ngOnInit() {
  }

}

export interface BreadcrumbOptions {
  currentPage: string;
  previousPages: BreadcrumbLink[];
}

export interface BreadcrumbLink {
  name: string;
  routeName: string;
}
