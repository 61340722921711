import { Role } from './role.model';
import { Entity } from './entity.model';
import { Person } from './person.model';

export class User extends Entity {
  username: string;
  email: string;
  active: boolean;
  role: Role | number;
  plainPassword: string;

  light: boolean;
  lastLogin: string;
  lastPasswordUpdate: string;
  lastLoginPC: string;
  profileUpdate: string;
  profileValid: string;
  profileDate: string;
  profileEnd: string;
  profile: string;
  language: number;
  preference: string;
  person: Person;
}
