<mat-accordion multi="true" class="mat-edit" *ngIf="obligation">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Obligation: {{obligation.id ? 'Edition' : 'Création'}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <form>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px">
          <div fxLayout="column" fxFlex="50">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-input [autofocus]="true" [type]="fieldType.TEXT"
                            [control]="entityForm.get('label')"
                            i18n-label="@@label" label="Libellé" flex="100"
                            fxFlex="100"></esomus-input>
              <mat-checkbox [formControl]="entityForm.get('required')" i18n-label="@@required"
                            label="Obligatoire" fxFlex="100">Obligatoire
              </mat-checkbox>
              <mat-checkbox [formControl]="entityForm.get('negation')" i18n-label="@@negation"
                            label="Négation" fxFlex="50">Négation
              </mat-checkbox>
              <esomus-ng-select *ngIf="obligationTypeOptions && entityForm.get('obligationType')"
                                [options]="obligationTypeOptions"
                                [control]="entityForm.get('obligationType')" fxFlex="100" flex="100"
                                (valueChange)="updateCategory()" label="Type d'obligation"
              ></esomus-ng-select>
              <esomus-ng-select *ngIf="categoryOptions && entityForm.get('category')"
                                [options]="categoryOptions"
                                [control]="entityForm.get('category')" fxFlex="100" flex="100"
                                label="Catégorie"
              ></esomus-ng-select>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <div class="btn-group-left" fxFlex="50">
                <esomus-delete-button *ngIf="obligation.id && obligation.active"
                                      [url]="getObligationDeleteURL()"
                                      [successUrl]="getObligationSuccessURL()"></esomus-delete-button>
              </div>
              <div class="btn-group-right" fxFlex="50">
                <esomus-back-button></esomus-back-button>
                <esomus-submit (submit)="submit()"></esomus-submit>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>
