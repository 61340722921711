<mat-accordion class="mat-edit" multi="true">
  <mat-expansion-panel expanded="true" *ngIf="listValue">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Liste: {{listValue.id ? 'Edition' : 'Création'}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <form>
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <esomus-input [autofocus]="true" [type]="fieldType.TEXT" [control]="entityForm.get('value')"
                        i18n-label="@@value" label="Valeur" flex="50" fxFlex="100"></esomus-input>
          <div class="btn-group-right" fxFlex="50">
            <esomus-back-button></esomus-back-button>
            <esomus-submit (submit)="submit()"></esomus-submit>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>
