<mat-accordion multi="true">
  <mat-expansion-panel expanded="true" *ngIf="form">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Formulaire: {{form.id ? 'Edition' : 'Création'}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
        <esomus-dashboard-print-selector *ngIf="form.id" screenID="25"
                                         [itemID]="form.id"></esomus-dashboard-print-selector>
      </div>
      <form>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px">
          <esomus-input [autofocus]="true" [type]="formFieldType.TEXT"
                        [control]="entityForm.get('name')"
                        i18n-label="@@name" label="Nom" flex="50" fxFlex="100"></esomus-input>
          <div (cdkDropListDropped)="drop($event)" class="btn-group-right" fxFlex="50">
            <esomus-delete-button *ngIf="form.id && form.active" [url]="getFormDeleteURL()"
                                  [successUrl]="getFormSuccessURL()"></esomus-delete-button>
            <esomus-back-button></esomus-back-button>
            <esomus-submit (submit)="submit()"></esomus-submit>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel expanded="true" *ngIf="itemForm">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Nouveau champ
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px">
        <esomus-ng-select [options]="fieldTypeOption" [control]="itemForm.get('fieldType')"
                          i18n-label="@@fieldType" label="Type de champ" [fxFlex]="50"
                          flex="100" (valueChange)="displayForm()"></esomus-ng-select>
        <ng-container *ngIf="fieldPlacement" [ngTemplateOutlet]="fieldInputsTemplate"
                      [ngTemplateOutletContext]="{fieldForm:itemForm}"></ng-container>
        <div class="btn-group-right" fxFlex="50">
          <esomus-submit *ngIf="fieldPlacement" label="Valider"
                         (submit)="submitForm()"></esomus-submit>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel expanded="true" *ngIf="fields">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Champs
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="start" cdkDropList (cdkDropListDropped)="drop($event)"
           fxLayoutGap="5px">
        <div *ngFor="let field of fields" style="border: 1px solid black;" fxLayout="column"
             fxFlex="100" cdkDrag>
          <div fxLayout="row wrap" fxLayoutAlign="start">
            <esomus-form-single-field fxFlex="50" flex="100" [field]="field"
                                      [fieldsOptions]="fieldsOptions"
                                      [fieldsForm]="fieldsForm"></esomus-form-single-field>
            <div class="btn-group-left" fxFlex>
              <button type="button" (click)="showFieldEditModal(field.fieldID)" color="success"
                      mat-flat-button>
                <mat-icon>edit</mat-icon>
              </button>
              <button type="button" (click)="deleteFieldEditModal(field.fieldID)" color="danger"
                      mat-flat-button>
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<ng-template #fieldInputsTemplate let-fieldForm="fieldForm">
  <esomus-input *ngIf="fieldForm.get('name')" [type]="formFieldType.TEXT"
                [control]="fieldForm.get('name')" i18n-label="@@name" label="Nom" flex="100"
                fxFlex="50"></esomus-input>
  <esomus-input *ngIf="fieldForm.get('code')" [type]="formFieldType.TEXT"
                [control]="fieldForm.get('code')" i18n-label="@@code" label="Code" flex="100"
                fxFlex="50"></esomus-input>
  <esomus-input *ngIf="fieldForm.get('min')" [type]="formFieldType.NUMBER"
                [control]="fieldForm.get('min')" i18n-label="@@min" label="Min" flex="100"
                fxFlex="50"></esomus-input>
  <esomus-input *ngIf="fieldForm.get('max')" [type]="formFieldType.NUMBER"
                [control]="fieldForm.get('max')" i18n-label="@@max" label="Max" flex="100"
                fxFlex="50"></esomus-input>
  <esomus-input *ngIf="fieldForm.get('row')" [type]="formFieldType.NUMBER"
                [control]="fieldForm.get('row')" i18n-label="@@row" label="Lignes" flex="100"
                fxFlex="50"></esomus-input>
  <esomus-ng-select *ngIf="fieldForm.get('listComponent')"
                    [options]="listComponentOption" [control]="fieldForm.get('listComponent')"
                    i18n-label="@@list" label="Liste" flex="50"
                    fxFlex="100"></esomus-ng-select>
  <mat-checkbox *ngIf="fieldForm.get('required')"
                [formControl]="fieldForm.get('required')" i18n-label="@@required"
                label="Requis" fxFlex="50">Requis
  </mat-checkbox>
  <mat-checkbox *ngIf="fieldForm.get('bold')"
                [formControl]="fieldForm.get('bold')" i18n-label="@@bold"
                label="Gras" fxFlex="50">Gras
  </mat-checkbox>
  <mat-checkbox *ngIf="fieldForm.get('bigFont')"
                [formControl]="fieldForm.get('bigFont')" i18n-label="@@bigFont"
                label="Grosse police" fxFlex="50">Grosse police
  </mat-checkbox>
</ng-template>

<ng-template #fieldEditModal>
  <form *ngIf="currentEditFieldPlacement">
    <h3 mat-dialog-title>
      <span>Edition champ</span>
    </h3>
    <div mat-dialog-content>
      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap.gt-xs="10px">
        <esomus-line-data fxFlex="100" [boldValue]="'Type'"
                          [textValue]="currentEditType.label"></esomus-line-data>
        <ng-container [ngTemplateOutlet]="fieldInputsTemplate"
                      [ngTemplateOutletContext]="{fieldForm:currentEditFieldForm}"></ng-container>
      </div>
    </div>
    <div mat-dialog-actions>
      <esomus-submit (submit)="confirmFieldEdit()"></esomus-submit>
      <button type="button" mat-stroked-button i18n="@@cancel" (click)="closeFieldEditModal()">
        Annuler
      </button>
    </div>
  </form>
</ng-template>

<ng-template #fieldDeleteModal>
  <form *ngIf="currentDeleteFieldPlacement">
    <h3 mat-dialog-title>
      <span>Suppression champ</span>
    </h3>
    <div mat-dialog-content>
      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap.gt-xs="10px">
        <p>Confirmer la suppression du champ ?</p>
      </div>
    </div>
    <div mat-dialog-actions>
      <button type="button" mat-raised-button i18n="@@confirm" (click)="confirmFieldDelete()">
        Confirmer
      </button>
      <button type="button" mat-stroked-button i18n="@@cancel" (click)="closeFieldEditModal()">
        Annuler
      </button>
    </div>
  </form>
</ng-template>

<!--<mat-accordion multi="true">-->
<!--  <mat-expansion-panel *ngIf="fields" expanded="true">-->
<!--    -->
<!--  </mat-expansion-panel>-->
<!--  <mat-expansion-panel *ngIf="itemForm" expanded="true">-->
<!--  </mat-expansion-panel>-->
<!--</mat-accordion>-->
