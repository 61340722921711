<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      Risque / obligation
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="container">
    <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px grid">
      <div fxLayout="column" fxFlex="50">
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <esomus-line-data fxFlex="100" boldValue="Risque"
                            [textValue]="itemDescription.obligationRisk"
          ></esomus-line-data>
          <esomus-line-data fxFlex="100" boldValue="Equipements"
                            [textValue]="itemDescription.obligationEquipments"
          ></esomus-line-data>
        </div>
      </div>
      <div fxLayout="column" fxFlex="50">
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <div fxLayout="column" fxFlex="50">
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <esomus-line-data fxFlex="100" [skipText]="true"
                                boldValue="Formations / Permis"></esomus-line-data>
            </div>
          </div>
          <div fxLayout="column" fxFlex="50">
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <div fxFlex="100">
                <ul class="m-5">
                  <li *ngFor="let obligation of itemDescription.obligations">
                    {{ obligation.label }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-expansion-panel>
