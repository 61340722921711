import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { Item } from '../../../core/models/item.model';
import { PictureHelper } from '../../../core/services/picture-helper.service';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'esomus-item-base-form',
  templateUrl: './item-base-form.component.html',
  styleUrls: ['./item-base-form.component.scss']
})
export class ItemBaseFormComponent implements OnInit, OnChanges {

  @Input() entityForm: FormGroup;
  @Input() item: Item;
  @Input() labelGeneral: string;
  @Input() labelResponsable: string;
  @Input() selectOptions: any;
  fieldType = FieldType;

  url: string | null | SafeUrl;

  constructor(
    private pictureHelper: PictureHelper
  ) { }

  ngOnInit() {
    this.url = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item && !changes.item.firstChange) {
      if (this.item.id !== null && this.item.id !== undefined) {
        this.url = this.pictureHelper.generateImage(this.item.picture);
      } else {
        this.url = null;
      }
    }
  }
}
