<mat-expansion-panel expanded="true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{labelGeneral}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div fxLayoutGap fxLayout="row wrap" fxLayoutAlign="start center">
    <img fxFlex="25" *ngIf="url !== null" [src]="url">
    <esomus-input-file accept="image/*" [control]="entityForm.controls['picture.upload']" i18n-label="@@picture" label="Photo" [flex]="25" fxFlex="100"></esomus-input-file>
    <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('description')" i18n-label="@@name" label="Nom" [flex]="100" fxFlex="50"></esomus-input>
    <esomus-ng-select [options]="selectOptions.itemDescription" [control]="entityForm.get('itemDescription')" i18n-label="@@itemDescription" label="Description" [fxFlex]="50" flex="100"></esomus-ng-select>

    <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('internalCode')" i18n-label="@@internalCode" label="Code interne" flex="100" fxFlex="50"></esomus-input>
    <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('serialNumber')" i18n-label="@@serialNumber" label="Numéro de série" flex="100" fxFlex="50"></esomus-input>

    <esomus-input [type]="fieldType.DATE" [control]="entityForm.get('buildingDate')" i18n-label="@@buildingDate" label="Date de construction" flex="100" [fxFlex]="100/3"></esomus-input>
    <esomus-input [type]="fieldType.DATE" [control]="entityForm.get('buyingDate')" i18n-label="@@buyingDate" label="Date d'achat" flex="100" [fxFlex]="100/3"></esomus-input>
    <esomus-input [type]="fieldType.DATE" [control]="entityForm.get('serviceDate')" i18n-label="@@serviceDate" label="Date de mise en service" flex="100" [fxFlex]="100/3"></esomus-input>

    <esomus-ng-select [options]="selectOptions.itemStatus" [control]="entityForm.get('status')" i18n-label="@@itemStatus" label="Statut" [fxFlex]="100/4" flex="100"></esomus-ng-select>

    <esomus-textarea [control]="entityForm.get('comment')" i18n-label="@@comment" label="Commentaire" flex="100" fxFlex="100"></esomus-textarea>
  </div>
</mat-expansion-panel>
<mat-expansion-panel expanded="true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{labelResponsable}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <esomus-ng-select [options]="selectOptions.user" [control]="entityForm.get('user')" i18n-label="@@responsable" label="Responsable" [fxFlex]="100" flex="100"></esomus-ng-select>
</mat-expansion-panel>
