<div>
  <span *ngIf="item.situation && item.situation.value === 4">(</span>
  <span>Appartenance :&nbsp;</span>
  <span *ngIf="item.company">
    <a class="link" [routerLink]="'company_view'| path:{id: item.company.id}">{{ item.company.name}}</a>
  </span>
  <span *ngIf="item.site">
    &nbsp;/&nbsp;<a class="link" [routerLink]="'site_view'| path:{id: item.site.id}">{{ item.site.name}}</a>
  </span>
  <span *ngIf="item.building && showAll">
    &nbsp;/&nbsp;<a class="link" [routerLink]="'building_view'| path:{id: item.building.id}">{{ item.building.name}}</a>
  </span>
  <span *ngIf="item.situation && item.situation.value === 4">)</span>
</div>
