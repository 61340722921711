import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FamilyListComponent } from './pages/family-list/family-list.component';
import { FamilyComponent } from './pages/family/family.component';
import { CategoriesModule } from '../categories/categories.module';
import { FamilyViewComponent } from './pages/family-view/family-view.component';
import { EsomusUiModule } from '../shared/esomus-ui/esomus-ui.module';
import { DashboardModule } from '../dashboard/dashboard.module';

@NgModule({
  declarations: [FamilyListComponent, FamilyComponent, FamilyViewComponent],
  imports: [
    CommonModule,
    SharedModule,
    CategoriesModule,
    EsomusUiModule,
    DashboardModule
  ]
})
export class FamiliesModule { }
