<button class="esomus-delete-button" type="button" (click)="openDeleteModal()" color="danger" mat-flat-button>{{label}}</button>

<ng-template #deleteModal>
  <h3 mat-dialog-title>
    <span>Validation suppression</span>
  </h3>
  <form>
    <div mat-dialog-content>
      <p>Voulez-vous vraiment supprimer cet élément ?</p>
    </div>
    <div mat-dialog-actions>
      <button type="button" mat-stroked-button color="success"
              i18n="@@confirmDelete" (click)="click()">Valider la suppression
      </button>
      <button type="button" mat-stroked-button i18n="@@cancel" (click)="closeDeleteModal()">Annuler
      </button>
    </div>
  </form>
</ng-template>
