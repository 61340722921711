import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Dashboard } from '../../../core/models/dashboard.model';
import { DashboardService } from '../../../core/api/dashboard.service';

@Component({
  selector: 'esomus-dashboard-information',
  templateUrl: './dashboard-information.component.html',
  styleUrls: ['./dashboard-information.component.sass']
})
export class DashboardInformationComponent implements OnInit {

  dashboards: Array<Dashboard>;

  constructor(
    private dashboardService: DashboardService,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.dashboardService.findAllInformations().subscribe((result: Array<Dashboard>) => {
      this.dashboards = result;
      this.cd.detectChanges();
    });
  }
}
