import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ItemLocation } from '../../../core/models/item-location.model';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { CompanyService } from '../../../core/api/company.service';
import { SiteService } from '../../../core/api/site.service';
import { BuildingService } from '../../../core/api/building.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Item } from '../../../core/models/item.model';
import { of } from 'rxjs';

@Component({
  selector: 'esomus-item-location-form',
  templateUrl: './item-location-form.component.html',
  styleUrls: ['./item-location-form.component.sass']
})
export class ItemLocationFormComponent implements OnInit {

  @Input() type: string;
  @Input() location: ItemLocation;
  @Input() form: FormGroup;

  @Input() companyRequired = false;
  @Input() companyEmitter: EventEmitter<any>;
  @Input() companyAutoSelect = false;
  @Input() siteRequired = false;
  @Input() siteDisabled = false;
  @Input() siteEmitter: EventEmitter<any>;
  @Input() siteAutoSelect = false;
  @Input() buildingRequired = false;
  @Input() buildingDisabled = false;
  @Input() buildingEmitter: EventEmitter<any>;
  @Input() buildingAutoSelect = false;
  @Input() localRequired = false;
  @Input() localDisabled = false;
  @Input() localEmitter: EventEmitter<any>;
  @Input() localAutoSelect = false;

  companyOptions: EntityOption;
  siteOptions: EntityOption;
  buildingOptions: EntityOption;
  localOptions: EntityOption;

  companyValidators: Array<any>;
  siteValidators: Array<any>;
  buildingValidators: Array<any>;
  localValidators: Array<any>;

  companyData: Array<Item>;
  siteData: Array<Item>;
  buildingData: Array<Item>;
  localData: Array<Item>;

  constructor(
    private companyService: CompanyService,
    private siteService: SiteService,
    private buildingService: BuildingService,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.companyValidators = [];
    if (this.companyRequired) {
      this.companyValidators.push(Validators.required);
    }
    this.siteValidators = [];
    if (this.siteRequired) {
      this.siteValidators.push(Validators.required);
    }
    this.buildingValidators = [];
    if (this.buildingRequired) {
      this.buildingValidators.push(Validators.required);
    }
    this.localValidators = [];
    if (this.localRequired) {
      this.localValidators.push(Validators.required);
    }

    if (!this.form.contains('company')) {
      this.form.addControl('company', this.fb.control(this.location.company ? this.location.company.id : null, this.companyValidators));
    }

    this.companyService.findAll('', {light: true, type: this.type}).subscribe((result: Array<Item>) => {
      this.companyData = result;

      this.companyOptions = {get: () => of(this.companyData), propName: 'label', autoSelect: this.companyAutoSelect};
      this.cd.detectChanges();
    });
  }

  updateSite() {
    if (this.form.contains('site')) {
      this.form.removeControl('site');
      this.cd.detectChanges();
    }

    const companyID = parseInt(this.form.get('company').value, 10);

    if (this.companyEmitter) {
      this.companyEmitter.emit(isNaN(companyID) ? null : companyID);
    }

    if (this.siteDisabled) {
      return;
    }

    if (isNaN(companyID)) {
      this.updateBuilding();
      return;
    }


    this.companyService.getAllSites(companyID, {light: true, type: this.type}).subscribe((result: Array<Item>) => {
      this.siteData = result;

      this.form.addControl('site', this.fb.control(this.location.site ? this.location.site.id : null, this.siteValidators));
      this.siteOptions = {get: () => of(this.siteData), propName: 'label', autoSelect: this.siteAutoSelect};

      this.cd.detectChanges();
    });
  }

  updateBuilding() {
    if (this.form.contains('building')) {
      this.form.removeControl('building');
      this.cd.detectChanges();
    }

    if (this.buildingDisabled) {
      return;
    }

    const siteID = this.form.contains('site') ? parseInt(this.form.get('site').value, 10) : NaN;

    if (isNaN(siteID)) {
      this.updateLocal();
      return;
    }

    this.siteService.getAllBuildings(siteID, {light: true, type: this.type}).subscribe((result: Array<Item>) => {
      this.buildingData = result;

      this.form.addControl('building', this.fb.control(this.location.building ? this.location.building.id : null, this.buildingValidators));
      this.buildingOptions = {get: () => of(this.buildingData), propName: 'label', autoSelect: this.buildingAutoSelect};

      this.cd.detectChanges();
    });
  }

  updateLocal() {
    if (this.form.contains('local')) {
      this.form.removeControl('local');
      this.cd.detectChanges();
    }

    if (this.localDisabled) {
      return;
    }

    const buildingID = this.form.contains('building') ? parseInt(this.form.get('building').value, 10) : NaN;

    if (isNaN(buildingID)) {
      this.cd.detectChanges();
      return;
    }

    this.buildingService.getAllLocals(buildingID, {light: true, type: this.type}).subscribe((result: Array<Item>) => {
      this.localData = result;

      this.form.addControl('local', this.fb.control(this.location.local ? this.location.local.id : null, this.localValidators));
      this.localOptions = {get: () => of(this.localData), propName: 'label', autoSelect: this.localAutoSelect};

      this.cd.detectChanges();
    });
  }
}
