<mat-accordion>
  <mat-expansion-panel expanded="true">
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="start center">
        <div *ngIf="showAddBtn" fxFlex="100" class="mb-10 ml-10">
          <button mat-raised-button (click)="createFamily()" color="success">
            <mat-icon>add</mat-icon>
            Nouvelle Famille
          </button>
        </div>
        <esomus-image-box fxFlex="20%" *ngFor="let family of families$|async"
                          [url]="routeName.path('family_view', {id: family.id})"
                          [imageSource]="family.picture" [text]="family.label"
                          [comment]="family.nbCategories + ' catégorie(s)'">
        </esomus-image-box>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
