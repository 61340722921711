import { Entity } from './entity.model';
import { Person } from './person.model';
import { Role } from './role.model';
import { Effect } from './effect.model';
import { Zone } from './zone.model';
import { Impact } from './impact.model';

export class AccidentPerson extends Entity {
  active: boolean;
  witness: boolean;
  intern: boolean;
  person: Person;
  role: Role;
  days: number;
  comment: string;
  effect: Effect;
  zones: Array<Zone>;
  impact: Impact;
}
