import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Picture } from '../../../../core/models/picture.model';
import { SafeUrl } from '@angular/platform-browser';
import { PictureHelper } from '../../../../core/services/picture-helper.service';

@Component({
  selector: 'esomus-image-box',
  templateUrl: './image-box.component.html',
  styleUrls: ['./image-box.component.scss']
})
export class ImageBoxComponent implements OnInit {

  @Input() imageSource: Picture|null;
  @Input() url: string;
  @Input() text: string;
  @Input() comment: string;

  src: string | SafeUrl;

  constructor(
    private router: Router,
    private pictureHelper: PictureHelper
  ) { }

  ngOnInit() {
    this.src = this.pictureHelper.generateImage(this.imageSource);
  }

  redirect() {
    this.router.navigateByUrl(this.url);
  }
}
