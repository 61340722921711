import { Component, Input, OnInit } from '@angular/core';
import { BreadcrumbOptions } from '../../../elastic-ui/breadcrumbs/breadcrumbs.component';
import { BreadcrumbService } from '../../../../core/api/breadcrumb.service';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Component({
  selector: 'esomus-item-breadcrumb',
  templateUrl: './item-breadcrumb.component.html',
  styleUrls: ['./item-breadcrumb.component.sass']
})
export class ItemBreadcrumbComponent implements OnInit {

  @Input() itemID: number;
  @Input() current: string|null;
  breadcrumbs: BreadcrumbOptions;
  rendering: boolean;

  constructor(
    private i18n: I18n,
    private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit() {
    this.rendering = false;

    this._getData();
  }

  private _getData() {
    this.breadcrumbs = {
      currentPage: null,
      previousPages: [
        {name: this.i18n({ value: 'Entreprise', id: 'company' }), routeName: 'companies'}
      ]
    };

    if (this.itemID === null || this.itemID === undefined) {
      // this.breadcrumbs = {
      //   currentPage: this.i18n({ value: 'Entreprise', id: 'company' }),
      //   previousPages: [{ name: this.i18n({ value: 'Personnes', id: 'people' }), routeName: 'people' }],
      // };

      this.rendering = true;
    } else {
      this.breadcrumbService.getByItem(this.itemID).subscribe((result: any) => {
        if (result !== null) {
          this.breadcrumbs.currentPage = this.current;
        }
        for (let i = 0; i < result.length; i++) {
          switch (result[i]['type']) {
            case 2:
              this.breadcrumbs.previousPages.push({name: this.i18n({ value: 'Site', id: 'site' }), routeName: null});
              break;
            case 3:
              this.breadcrumbs.previousPages.push({name: this.i18n({ value: 'Bâtiment', id: 'building' }), routeName: null});
              break;
            case 4:
              this.breadcrumbs.previousPages.push({name: this.i18n({ value: 'Local', id: 'local' }), routeName: null});
              break;
          }

          if (i === result.length - 1 && this.current === null) {
            this.breadcrumbs.currentPage = result[i]['label'];
          } else {
            switch (result[i]['type']) {
              case 1:
                  this.breadcrumbs.previousPages.push({name: result[i]['label'], routeName: null});
                  break;
              case 2:
                this.breadcrumbs.previousPages.push({name: result[i]['label'], routeName: null});
                break;
              case 3:
                this.breadcrumbs.previousPages.push({name: result[i]['label'], routeName: null});
                break;
              case 4:
                this.breadcrumbs.previousPages.push({name: result[i]['label'], routeName: null});
                break;
              case 6:
              case 7:
              case 8:
                this.breadcrumbs.previousPages.push({name: result[i]['label'], routeName: null});
                break;
            }
          }
        }

        this.rendering = true;
      });
    }
  }
}
