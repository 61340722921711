<ng-template #formVersionModal>
  <form *ngIf="previousDocument && versionForm">
    <h3 mat-dialog-title>
      <span>Mise à jour d'un document</span>
    </h3>
    <div mat-dialog-content>
      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap.gt-xs="10px">
        <esomus-input-file accept="image/*,application/pdf"
                           [control]="versionForm.controls['uploadedFile']"
                           i18n-label="@@file" label="Fichier" fxFlex="100"
                           flex="100"></esomus-input-file>
        <esomus-line-data flex="50" fxFlex="100"
                          boldValue="Catégorie" [textValue]="previousDocument.documentType.label"
        ></esomus-line-data>
        <esomus-line-data flex="50" fxFlex="100"
                          boldValue="Libellé" [textValue]="previousDocument.label"
        ></esomus-line-data>
        <esomus-input [type]="fieldType.TEXT" [control]="versionForm.get('version')"
                      i18n-label="@@version" label="Version" flex="100"
                      fxFlex="50"></esomus-input>
      </div>
    </div>
    <div mat-dialog-actions>
      <esomus-submit (submit)="submitVersion()"></esomus-submit>
      <button type="button" mat-stroked-button i18n="@@cancel" (click)="closeVersion()">Annuler</button>
    </div>
  </form>
</ng-template>
