import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccidentTypeComponent } from './pages/accident-type/accident-type.component';
import { AccidentTypeListComponent } from './pages/accident-type-list/accident-type-list.component';
import { SharedModule } from '../shared/shared.module';
import { DashboardModule } from '../dashboard/dashboard.module';


@NgModule({
  declarations: [AccidentTypeComponent, AccidentTypeListComponent],
  imports: [
    CommonModule,
    SharedModule,
    DashboardModule
  ]
})
export class AccidentTypesModule { }
