import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { ItemType } from '../../../core/models/item-type.model';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { Item } from '../../../core/models/item.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ItemTypeService } from '../../../core/api/item-type.service';
import { CategoryService } from '../../../core/api/category.service';
import { FamilyService } from '../../../core/api/family.service';
import { ToastrMessageType, ToastrService } from '../../../core/services/toastr.service';
import { EquipmentService } from '../../../core/api/equipment.service';
import { Anomaly } from '../../../core/models/anomaly.model';
import { AnomalyService } from '../../../core/api/anomaly.service';
import { AnomalyTypeService } from '../../../core/api/anomaly-type.service';
import { AnomalyType } from '../../../core/models/anomaly-type.model';
import { of } from 'rxjs';
import { FormHelper } from '../../../core/services/form-helper.service';
import { Entity } from '../../../core/models/entity.model';
import { AnomalyDataService } from '../../../core/api/anomaly-data.service';
import { FormsMap, FormsMappingMap } from '../../../forms/components/form-component/form-component.component';
import { PersonService } from '../../../core/api/person.service';
import { TaskHelper } from '../../../core/services/task-helper.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../../core/api/auth.service';

@Component({
  selector: 'esomus-anomaly',
  templateUrl: './anomaly.component.html',
  styleUrls: ['./anomaly.component.sass']
})
export class AnomalyComponent implements OnInit {
  entityForm: FormGroup;
  fieldType = FieldType;
  itemType: ItemType;

  anomaly: Anomaly;
  anomalyTypeOptions: EntityOption;
  anomalyTypes: Array<AnomalyType>;

  forms: FormsMap;
  formsMapping: FormsMappingMap;
  formsValidation: boolean[];
  nbForms: number;

  severityOptions: EntityOption;
  emergencyOptions: EntityOption;
  statusOptions: EntityOption;
  personOptions: EntityOption;

  @ViewChild('formModal', { static: true }) formModal: TemplateRef<any>;
  formModalRef: MatDialogRef<AnomalyComponent>;

  constructor(
    private i18n: I18n,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private itemTypeService: ItemTypeService,
    private categoryService: CategoryService,
    private familyService: FamilyService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private equipmentService: EquipmentService,
    private anomalyService: AnomalyService,
    private anomalyTypeService: AnomalyTypeService,
    private anomalyDataService: AnomalyDataService,
    private personService: PersonService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      description: [null],
      comment: [null],
      severity: [null],
      emergency: [null],
      deadlineDate: [null],
      resolutionDate: [null],
      'picture.upload': [null],
      person: [null],
      status: [null]
    });

    const equipmentID = parseInt(this.activatedRoute.snapshot.paramMap.get('equipmentID'), 10);
    const anomalyID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    this.emergencyOptions = {get: () => of(TaskHelper.getOptions()), propName: 'label'};
    this.severityOptions = {get: () => of(TaskHelper.getSeverityOptions()), propName: 'label'};
    this.personOptions = {get: () => this.personService.findAll(), propName: 'fullName'};

    if (!isNaN(equipmentID)) {
      this._getEquipment(equipmentID);
    } else if (!isNaN(anomalyID)) {
      this._getAnomaly(anomalyID);
    }
  }

  private _getEquipment(equipmentID: number) {
    this.equipmentService.find(equipmentID).subscribe((item: Item) => {
      this.anomaly = new Anomaly();
      this.anomaly.status = 0;
      this.anomaly.item = item;

      this.entityForm.addControl('item', this.fb.control(this.anomaly.item.id));
      console.log(this.authService.getPersonID());
      this.entityForm.get('person').setValue(this.authService.getPersonID());

      this.anomalyTypeService.findAll().subscribe((result: Array<AnomalyType>) => {
        this.anomalyTypes = result;

        this.entityForm.addControl('anomalyType', this.fb.control(null, [Validators.required]));
        this.anomalyTypeOptions = {get: () => of(this.anomalyTypes), propName: 'label', autoSelect: true};

        this.cd.detectChanges();
      });

      this.cd.detectChanges();
    });
  }

  private _getAnomaly(anomalyID: number) {
    this.anomalyService.find(anomalyID).subscribe((anomaly: Anomaly) => {
      this.anomaly = anomaly;

      if (!this.anomaly.closed) {
        this.statusOptions = {
          get: () => of(TaskHelper.getAnomalyStatusOptions()),
          propName: 'label',
          autoSelect: true
        };
      }

      FormHelper.initValues(this.anomaly, this.entityForm);

      if (this.anomaly.form) {
        this.initForm();
      }

      this.cd.detectChanges();
    });
  }

  submit() {
    const status = this.entityForm.get('status');

    if (this.anomaly.id && status !== null && status.value !== 0 && this.anomaly.nbActions > 0) {
      this.formModalRef = this.dialog.open(this.formModal);
    } else {
      this._submitAnomaly();
    }
  }

  confirmAnomaly() {
    this.closeAnomalyModal();
    this._submitAnomaly();
  }

  closeAnomalyModal() {
    this.formModalRef.close();
    this.formModalRef = null;
  }

  _submitAnomaly() {
    if (this.entityForm.invalid) {
      return;
    }

    const entity = FormHelper.buildEntity(this.anomaly, this.entityForm, {
      deadlineDate: {type: FieldType.DATE},
      resolutionDate: {type: FieldType.DATE},
      'picture.upload': {type: FieldType.FILE, multiple: false}
    }) as Anomaly;

    if (!this.anomaly.id) {
      FormHelper.submitForm(
        this.cd,
        this.entityForm,
        this.anomalyService.post(entity),
        (result: Entity) => {
          this.toastrService.open(ToastrMessageType.CREATE);
          this.routeNameService.goTo('anomaly_id', {id: result.id});
        }
      );
    } else {
      this.nbForms = 1;
      this.formsValidation = [];

      for (const key in this.forms) {
        this.nbForms++;
        if (this.forms[key].invalid) {
          return;
        }
      }

      for (const key in this.formsMapping) {
        const entityData = FormHelper.buildEntity(this.formsMapping[key], this.forms[key], {});
        FormHelper.submitForm(
          this.cd,
          this.forms[key],
          this.anomalyDataService.putData(entityData, this.anomaly.id, parseInt(key.substr(5), 10)),
          (result: Entity) => {
            this.formsValidation.push(true);
            this._handleFormsSubmit(result);
          }
        );
      }

      FormHelper.submitForm(
        this.cd,
        this.entityForm,
        this.anomalyService.put(entity),
        (result: Entity) => {
          this.formsValidation.push(true);
          this._handleFormsSubmit(result);
        }
      );
    }
  }

  private _handleFormsSubmit(result: Entity) {
    if (this.formsValidation.length === this.nbForms) {
      this.toastrService.open(ToastrMessageType.UPDATE);
      this.routeNameService.goTo('anomaly_view', {id: this.anomaly.id});
    }
  }

  updateForm() {
    const anomalyTypeID = this.entityForm.get('anomalyType').value;

    this.anomaly.anomalyType = this.anomalyTypes.filter((item: AnomalyType) => {
      return item.id === anomalyTypeID;
    })[0];

    this.entityForm.get('severity').setValue(this.anomaly.anomalyType.severity);
    this.entityForm.get('emergency').setValue(this.anomaly.anomalyType.emergency);

    if (this.anomaly.anomalyType.form) {
      this.anomaly.form = this.anomaly.anomalyType.form;
    }

    this.cd.detectChanges();
  }

  initForm() {
    this.forms = {};
    this.formsMapping = {};
  }

  getFetchDataURL(formID: number) {
    return this.anomalyDataService.getData(this.anomaly.id, formID);
  }

  getItemURL() {
    return this.routeNameService.path('equipment_view', {id: this.anomaly.item.id});
  }

  getAnomalyStatus() {
    return TaskHelper.getAnomalyStatusLabel(this.anomaly.status);
  }
}
