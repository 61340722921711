import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AnomalyType } from '../../../core/models/anomaly-type.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ToastrMessageType, ToastrService } from '../../../core/services/toastr.service';
import { AnomalyTypeService } from '../../../core/api/anomaly-type.service';
import { FormService } from '../../../core/api/form.service';
import { of } from 'rxjs';
import { TaskHelper } from '../../../core/services/task-helper.service';
import { FormHelper } from '../../../core/services/form-helper.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'esomus-anomaly-type',
  templateUrl: './anomaly-type.component.html',
  styleUrls: ['./anomaly-type.component.sass']
})
export class AnomalyTypeComponent implements OnInit {
  anomalyType: AnomalyType;

  entityForm: FormGroup;
  fieldType = FieldType;
  formOptions: EntityOption;

  severityOptions: EntityOption;
  emergencyOptions: EntityOption;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private anomalyTypeService: AnomalyTypeService,
    private formService: FormService,
  ) {
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      label: [null],
      severity: [null],
      emergency: [null],
      handled: [null],
    });

    this.emergencyOptions = {
      get: () => of(TaskHelper.getOptions()), propName: 'label'
    };
    this.severityOptions = {
      get: () => of(TaskHelper.getSeverityOptions()), propName: 'label'
    };

    const id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    if (!isNaN(id)) {
      this._getAnomalyType(id);
    }
  }

  private _getAnomalyType(id: number) {
    this.anomalyTypeService.find(id).subscribe((result: AnomalyType) => {
        this.anomalyType = result;

        if (!this.anomalyType.form) {
          this.entityForm.addControl('form', this.fb.control(null));
          this.formOptions = {get: () => this.formService.findAll(), propName: 'name'};
        }

        FormHelper.initValues(this.anomalyType, this.entityForm);
        this.cd.detectChanges();
    });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    const entity = FormHelper.buildEntity(this.anomalyType, this.entityForm, {}) as AnomalyType;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      this.anomalyTypeService.put(entity),
      (result: AnomalyType) => {
        this.toastrService.open(ToastrMessageType.UPDATE);
        this.routeNameService.goTo('anomaly_types');
      }
    );
  }

  getAnomalyTypeDeleteURL() {
    return this.anomalyTypeService.delete(this.anomalyType.id);
  }

  getAnomalyTypeSuccessURL() {
    return this.routeNameService.path('anomaly_types');
  }
}
