import { DocumentType } from '../models/document-type.model';

export class DocumentTypeHelper {

  constructor() {
  }

  static getUsageTypeLabel(documentType: DocumentType) {
    switch (documentType.usageType) {
      case 1:
        return 'Description';
      case 2:
        return 'Activité';
      default:
        return null;
    }
  }

  static getUsageTypes() {
    return [
      {id: 1, label: 'Description'}, {id: 2, label: 'Activité'}
    ];
  }

  static getTypeLabel(documentType: DocumentType) {
    switch (documentType.type) {
      case 1:
        return 'P';
      case 2:
        return 'I';
      default:
        return null;
    }
  }

  static getTypes() {
    return [
      {id: 1, label: 'P'}, {id: 2, label: 'I'}
    ];
  }
}
