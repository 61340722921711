import { Component, Input, OnInit } from '@angular/core';
import { FormField } from '../../../core/models/form-field.model';
import { FormGroup, Validators } from '@angular/forms';
import { FieldType as FormFieldType } from '../../../shared/form-builder/form-builder.component';

@Component({
  selector: 'esomus-form-single-field',
  templateUrl: './form-single-field.component.html',
  styleUrls: ['./form-single-field.component.sass']
})
export class FormSingleFieldComponent implements OnInit {

  @Input() field: FormField;
  @Input() fieldsForm: FormGroup;
  @Input() fieldsOptions: any;
  @Input() flex: string;

  formFieldType = FormFieldType;

  constructor() { }

  ngOnInit() {
    if (!this.flex) {
      this.flex = '50';
    }

    const formField = this.fieldsForm.get(this.field.fullName);

    if (formField !== null) {
      let validators = [];

      for (let option in this.field.options) {
        let value = this.field.options[option];

        switch (option) {
          case 'required':
            if (value === '1') {
              validators.push(Validators.required);
            }
            break;
          case 'min':
            if (value !== null) {
              validators.push(Validators.min(value));
            }
            break;
          case 'max':
            if (value !== null) {
              validators.push(Validators.max(value));
            }
            break;
          case 'pattern':
            if (this.field.fieldTypeName !== 'number') {

            }
            break;
          default:
            console.warn(option, value);
            break;
        }
      }

      if (validators.length) {
        formField.setValidators(validators);
      }
    }

  }
}
