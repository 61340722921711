import { Component, Input } from '@angular/core';
import { FormField, FormFieldOptions } from '../form-field';

@Component({
  selector: 'esomus-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent extends FormField {

  @Input() multiple: boolean;
  @Input() accept: string | null;

  constructor() {
    super();
  }
}

export class FileField extends FormFieldOptions {
  type: 'file';
  multiple?: boolean;
}
