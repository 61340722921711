import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FieldType } from '../../../../../form-builder/form-builder.component';
import { RouteNameService } from '../../../../../../core/services/route-name.service';

@Component({
  selector: 'esomus-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input() sidenavCollapsed: boolean;
  @Input() quickpanelOpen: boolean;
  @Output() toggledSidenav = new EventEmitter();
  @Output() toggledQuickpanel = new EventEmitter();

  env = environment;

  searchForm: FormGroup;
  fieldType = FieldType;

  constructor(
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
  ) { }

  ngOnInit() {
    this.searchForm = this.fb.group({
        searchText: [localStorage.getItem('searchText'), [Validators.required]]
    });
  }

  toggleSidenav() {
    this.toggledSidenav.emit();
  }

  toggleQuickpanel() {
    this.toggledQuickpanel.emit();
  }

  search() {
    const value = this.searchForm.get('searchText').value;
    localStorage.setItem('searchText', value);
    this.routeNameService.goTo('search', {searchText: encodeURIComponent(value)});
  }
}
