import { Component, OnInit } from '@angular/core';
import { ColumnSortDirection, TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { ZoneService } from '../../../core/api/zone.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RouteNameService } from '../../../core/services/route-name.service';
import { Params } from '@angular/router';
import { Entity } from '../../../core/models/entity.model';

@Component({
  selector: 'esomus-zone-list',
  templateUrl: './zone-list.component.html',
  styleUrls: ['./zone-list.component.sass']
})
export class ZoneListComponent implements OnInit {
  zonesTable: TableOptions;

  constructor(
    private zoneService: ZoneService,
    private i18n: I18n,
    private routeName: RouteNameService
  ) {
  }

  ngOnInit() {
    this.zonesTable = {
      columnDefs: [
        {
          prop: 'description', width: '75%',
          name: this.i18n({value: 'Description', id: 'description'})
        },
        {
          prop: 'order', width: '20%',
          name: this.i18n({value: 'Ordre', id: 'order'})
        },
      ],
      findDataCb: (searchData: Params) => this.zoneService.findAll(),
      actions: {
        columnWidth: '5%',
        create: [
          {
            btnName: this.i18n({value: 'Ajouter une zone', id: 'btnAddZone'}),
            createCb: () => this.routeName.goTo('zone_add')
          }
        ],
        updateCb: (entity: Entity) => {
          this.routeName.goTo('zone_id', {id: entity.id});
        },
      },
      defaultSortActive: 'description',
      defaultSortDirection: ColumnSortDirection.ASC
    };
  }
}
