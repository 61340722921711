import { Picture } from '../models/picture.model';
import { DomSanitizer } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PictureHelper {

  constructor(
    private domSanitizationService: DomSanitizer
  ) {}

  generateImage(imageSource: Picture|null) {
    if (imageSource !== null && imageSource !== undefined && imageSource.data !== null) {
      return this.domSanitizationService.bypassSecurityTrustUrl(`data:${imageSource.mimeType};base64, ${imageSource.data}`);
    }
    return `assets/img/No_image.png`;
  }
}
