import { ChangeDetectorRef, Component, EventEmitter, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { Item } from '../../../core/models/item.model';
import { Family } from '../../../core/models/family.model';
import { Category } from '../../../core/models/category.model';
import { ItemSituation } from '../../../core/models/item-situation.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ToastrService, ToastrType } from '../../../core/services/toastr.service';
import { CategoryService } from '../../../core/api/category.service';
import { FamilyService } from '../../../core/api/family.service';
import { PersonService } from '../../../core/api/person.service';
import { CompanyService } from '../../../core/api/company.service';
import { SiteService } from '../../../core/api/site.service';
import { BuildingService } from '../../../core/api/building.service';
import { ItemSituationService } from '../../../core/api/item-situation.service';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { of } from 'rxjs';
import { EquipmentService } from '../../../core/api/equipment.service';
import { FormHelper } from '../../../core/services/form-helper.service';
import { Entity } from '../../../core/models/entity.model';
import { ColumnType, TableOptions } from '../../../shared/form-builder/components/table/table.component';

@Component({
  selector: 'esomus-equipment-link',
  templateUrl: './equipment-link.component.html',
  styleUrls: ['./equipment-link.component.sass']
})
export class EquipmentLinkComponent implements OnInit {
  fieldType = FieldType;
  scopeForm: FormGroup;
  equipment: Item;

  equipmentTable: TableOptions;
  @ViewChild('familyCategoryCell', {static: true}) familyCategoryCell: TemplateRef<any>;
  equipmentTableEmitter = new EventEmitter<any>();
  nbEquipments: number;

  emergencyOptions: EntityOption;
  personOptions: EntityOption;
  formOptions: EntityOption;
  companyOptions: EntityOption;
  siteOptions: EntityOption;
  buildingOptions: EntityOption;
  localOptions: EntityOption;
  familyOptions: EntityOption;
  categoryOptions: EntityOption;
  situationOptions: EntityOption;

  companyData: Array<Item>;
  siteData: Array<Item>;
  buildingData: Array<Item>;
  localData: Array<Item>;
  familyData: Array<Family>;
  categoryData: Array<Category>;
  situtationData: Array<ItemSituation>;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private categoryService: CategoryService,
    private familyService: FamilyService,
    private personService: PersonService,
    private companyService: CompanyService,
    private siteService: SiteService,
    private buildingService: BuildingService,
    private situationService: ItemSituationService,
    private equipmentService: EquipmentService
  ) {
  }

  ngOnInit() {
    this.nbEquipments = null;

    this.activatedRoute.paramMap.subscribe(() => {
      const id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

      if (!isNaN(id)) {
        this.equipmentService.find(id).subscribe((result: Item) => {
          this.equipment = result;

          this.scopeForm = this.fb.group({
            company: [null],
            family: [null],
            situation: [null]
          });

          this.companyService.findAll('', {light: true}).subscribe((result: Array<Item>) => {
            this.companyData = result;

            this.companyOptions = {get: () => of(this.companyData), propName: 'label'};
            this.cd.detectChanges();
          });

          this.familyService.findAll('', {light: true, 'no-location': true}).subscribe((result: Array<Family>) => {
            this.familyData = result;

            this.familyOptions = {get: () => of(this.familyData), propName: 'label'};
            this.cd.detectChanges();
          });

          this.situationService.findAll().subscribe((result: Array<ItemSituation>) => {
            this.situtationData = result;

            this.situationOptions = {get: () => of(this.situtationData), propName: 'label'};
            this.cd.detectChanges();
          });

          this.equipmentTable = {
            columnDefs: [
              {
                name: this.i18n({value: 'Photo', id: 'picture'}),
                type: ColumnType.PICTURE, prop: 'picture', width: '10%'
              },
              {
                name: this.i18n({value: 'Nom', id: 'name'}),
                prop: 'label', width: '25%'
              },
              {
                name: this.i18n({value: 'Modèle', id: 'model'}),
                prop: 'itemDescription.model', width: '15%'
              },
              {
                name: this.i18n({value: 'Type', id: 'type'}),
                prop: 'itemDescription.type', width: '15%'
              },
              {
                name: this.i18n({value: 'Famille / Catégorie', id: 'familyCategory'}), width: '20%',
                type: ColumnType.TEMPLATE, template: this.familyCategoryCell, prop: 'templateFamilyCategory'
              },
              {
                name: this.i18n({value: 'En ordre', id: 'inOrder'}),
                prop: 'inOrder', width: '20%', type: ColumnType.CHECKBOX,
              }
            ],
            findDataCb: (searchData: Params) => {
              this.nbEquipments = null;

              if (!Object.keys(searchData).length) {
                return of([]);
              }

              return this.equipmentService.findByScope(this.equipment.id, searchData);
            },
            actions: {
              custom: [
                {
                  icon: 'attach_file', visibleCb: (equipment: Item) => equipment.link === null,
                  customCb: (entity: Item) => {
                    this.equipmentService.linkChild(this.equipment.id, entity.id).subscribe(() => {
                      this.toastrService.openCustom(ToastrType.SUCCESS, 'Liaison effectuée');
                      this.search();
                    });
                  }
                }
              ],
              readCb: (entity: Entity) => this.routeNameService.goTo('equipment_view', {id: entity.id}),
              columnWidth: '5%'
            },
          };

          this.cd.detectChanges();
        });
      }
    });
  }

  search() {
    const data = FormHelper.buildEntity(new Entity(), this.scopeForm);

    if (data['situation']) {
      data['status'] = data['situation'].map((item: Entity) => item.id).join(',');
    }

    this.equipmentTableEmitter.emit(data);
  }

  updateSite() {
    if (this.scopeForm.contains('site')) {
      this.scopeForm.removeControl('site');
    }
    const companyID = parseInt(this.scopeForm.get('company').value, 10);

    if (isNaN(companyID)) {
      this.updateBuilding();
      return;
    }

    this.companyService.getAllSites(companyID, {light: true}).subscribe((result: Array<Item>) => {
      this.siteData = result;

      this.scopeForm.addControl('site', this.fb.control(null));
      this.siteOptions = {get: () => of(this.siteData), propName: 'label'};

      this.cd.detectChanges();
    });
  }

  updateBuilding() {
    if (this.scopeForm.contains('building')) {
      this.scopeForm.removeControl('building');
    }

    const siteID = parseInt(this.scopeForm.get('site').value, 10);

    if (isNaN(siteID)) {
      this.updateLocal();
      return;
    }

    this.siteService.getAllBuildings(siteID, {light: true}).subscribe((result: Array<Item>) => {
      this.buildingData = result;

      this.scopeForm.addControl('building', this.fb.control(null));
      this.buildingOptions = {get: () => of(this.buildingData), propName: 'label'};

      this.cd.detectChanges();
    });
  }

  updateLocal() {
    if (this.scopeForm.contains('local')) {
      this.scopeForm.removeControl('local');
    }

    const buildingID = parseInt(this.scopeForm.get('building').value, 10);

    if (isNaN(buildingID)) {
      this.cd.detectChanges();
      return;
    }

    this.buildingService.getAllLocals(buildingID, {light: true}).subscribe((result: Array<Item>) => {
      this.localData = result;

      this.scopeForm.addControl('local', this.fb.control(null));
      this.localOptions = {get: () => of(this.localData), propName: 'label'};

      this.cd.detectChanges();
    });
  }

  updateCategory() {
    if (this.scopeForm.contains('category')) {
      this.scopeForm.removeControl('category');
    }

    const familyID = parseInt(this.scopeForm.get('family').value, 10);

    if (isNaN(familyID)) {
      this.cd.detectChanges();
      return;
    }

    this.categoryService.findAllByParent(familyID, {light: true}).subscribe((result: Array<Category>) => {
      this.categoryData = result;

      this.scopeForm.addControl('category', this.fb.control(null));
      this.categoryOptions = {get: () => of(this.categoryData), propName: 'label'};

      this.cd.detectChanges();
    });
  }

  updateNbEquipments(nbEquipments: number) {
    this.nbEquipments = nbEquipments;
  }
}
