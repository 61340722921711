import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FieldType as FormFieldType } from '../../../shared/form-builder/form-builder.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrMessageType, ToastrService } from '../../../core/services/toastr.service';
import { FormHelper } from '../../../core/services/form-helper.service';
import { FormService } from '../../../core/api/form.service';
import { Form } from '../../../core/models/form.model';
import { FieldTypeService } from '../../../core/api/field-type.service';
import { FieldType } from '../../../core/models/field-type.model';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { of } from 'rxjs';
import { FormField } from '../../../core/models/form-field.model';
import { FormFieldService } from '../../../core/api/form-field.service';
import { ListComponentService } from '../../../core/api/list-component.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'esomus-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.sass']
})
export class FormComponent implements OnInit {
  entityForm: FormGroup;
  itemForm: FormGroup;
  formFieldType = FormFieldType;
  form: Form;

  fieldTypeOption: EntityOption;
  fieldPlacement: boolean;

  private fieldTypes: Array<FieldType>;
  fields: Array<FormField>;
  fieldsForm: FormGroup;
  listComponentOption: EntityOption;
  emptyOption: EntityOption;
  fieldsOptions: any;

  currentEditFieldForm: FormGroup;
  currentEditField: FormField;
  currentEditType: FieldType;
  currentEditFieldPlacement: boolean;

  currentDeleteField: FormField;
  currentDeleteFieldPlacement: boolean;

  @ViewChild('fieldEditModal', {static: true}) formModal: TemplateRef<any>;
  fieldEditModalRef: MatDialogRef<FormComponent>;

  @ViewChild('fieldDeleteModal', {static: true}) deleteModal: TemplateRef<any>;
  fieldDeleteModalRef: MatDialogRef<FormComponent>;

  constructor(
    private i18n: I18n,
    private dialog: MatDialog,
    private routeNameService: RouteNameService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private formService: FormService,
    private toastrService: ToastrService,
    private fieldTypeService: FieldTypeService,
    private formFieldService: FormFieldService,
    private listComponentService: ListComponentService,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((routeParams) => {
      this.fieldPlacement = false;
      this.listComponentOption = null;
      this.emptyOption = {get: () => of(), propName: 'empty'};

      this.entityForm = this.fb.group({
        name: [null],
      });

      const formID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);
      if (!isNaN(formID)) {
        this._getForm(formID);
        this._getFields(formID);
      } else {
        this.form = new Form();
      }
    });
  }

  private _getForm(formID: number) {
    this.formService.find(formID)
      .subscribe((form: Form) => {
        this.form = form;
        FormHelper.initValues(this.form, this.entityForm);

        this.cd.detectChanges();
      });

    this.fieldTypeService.findAll()
      .subscribe((results: Array<FieldType>) => {
        this.fieldTypes = results;

        this.fieldTypeOption = {
          get: () => of(this.fieldTypes), propName: 'label'
        };

        this.itemForm = this.fb.group({
          fieldType: [null]
        });
        this.cd.detectChanges();
      });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }
    let entity = FormHelper.buildEntity(this.form, this.entityForm, {}) as Form;
    ((entity.id) ? this.formService.put(entity) : this.formService.post(entity))
      .subscribe((result: Form) => {
        this.entityForm.reset();
        this.toastrService.open((entity.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        if (this.form.id) {
          this.routeNameService.goTo('forms');
        } else {
          this.routeNameService.goTo('form_id', {id: result.id});
        }
      });

  }

  displayForm(form?: FormGroup) {
    const type = this.itemForm.get('fieldType');

    this._resetForm(false);

    if (type === null || type === undefined || type.value === null) {
      this.fieldPlacement = false;
      this.cd.detectChanges();
      return;
    }

    const inputType = this.fieldTypes.find((item: FieldType) => {
      return item.id === type.value;
    });

    if (!inputType) {
      return;
    }

    if (!form) {
      form = this.itemForm;
    }

    this._createFields(form, inputType);

    this.fieldPlacement = true;
  }

  _createFields(form: FormGroup, inputType: FieldType) {
    let withRequired = true;
    form.addControl('name', this.fb.control(null, [Validators.required]));
    form.addControl('code', this.fb.control(null));

    switch (inputType.name) {
      case 'text':
      case 'checkbox':
      case 'calendar':
        break;
      case 'number':
        form.addControl('min', this.fb.control(null));
        form.addControl('max', this.fb.control(null));
        break;
      case 'textarea':
        form.addControl('row', this.fb.control(null));
        break;
      case 'label':
        form.addControl('bold', this.fb.control(null));
        form.addControl('bigFont', this.fb.control(null));
        withRequired = false;
        break;
      case 'selectlist':
      case 'radio':
        this.listComponentOption = {get: () => this.listComponentService.findAll(), propName: 'name'};
        form.addControl('listComponent', this.fb.control(null, [Validators.required]));
        break;
    }

    if (withRequired) {
      form.addControl('required', this.fb.control(null));
    }
  }

  private _resetForm(displayForm: boolean = true) {
    let controls = this.itemForm.controls;
    for (let control in controls) {
      if (control !== 'fieldType') {
        this.itemForm.removeControl(control);
      }
    }

    if (displayForm) {
      this.itemForm.get('fieldType').setValue(null);
      this.displayForm();
    }
  }

  submitForm() {
    if (this.itemForm.invalid) {
      return;
    }

    let formField = new FormField();
    let entity = FormHelper.buildEntity(formField, this.itemForm, {}) as FormField;

    const baseURL = `/forms/${this.form.id}/field_type/${this.itemForm.get('fieldType').value}`;

    FormHelper.submitForm(
      this.cd,
      this.itemForm,
      this.formFieldService.post(entity, baseURL),
      (result: FormField) => {
        this._resetForm();

        this.toastrService.open((entity.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        this._getFields(this.form.id);
        // this.routeNameService.goTo('families');
      }
    );
  }

  private _getFields(formID: number) {
    this.formFieldService.findAll(`/forms/${formID}/field_type/0`).subscribe((items: Array<FormField>) => {
      this.fieldsOptions = [];
      this.fields = items;
      this.listComponentOption = null;

      this.fieldsForm = this.fb.group({});

      for (let field of this.fields) {
        field.fullName = `form_${field.groupID}_${field.fieldID}`;

        switch (field.fieldTypeName) {
          case 'radio':
          case 'selectlist':
            this.fieldsOptions[field.fullName] = {get: () => of(field.selectData), propName: 'value'};
            this.fieldsForm.addControl(field.fullName, this.fb.control(null));
            break;
          case 'label':
            break;
          default:
            this.fieldsForm.addControl(field.fullName, this.fb.control(null));
        }
      }

      this.cd.detectChanges();
    });
  }

  getFormDeleteURL() {
    return this.formService.delete(this.form.id);
  }

  getFormSuccessURL() {
    return this.routeNameService.path('forms');
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log(event);
    const previousItem = this.fields.find((item: FormField) => item.sequence === event.previousIndex + 1);
    const nextItem = this.fields.find((item: FormField) => item.sequence === event.currentIndex + 1);

    if (previousItem !== null && nextItem !== null && previousItem !== nextItem) {
      this.formFieldService.swap(this.form.id, previousItem.fieldID, nextItem.fieldID).subscribe(() => {
        previousItem.sequence = event.currentIndex + 1;
        nextItem.sequence = event.previousIndex + 1;

        this.fields.sort((a: FormField, b: FormField) => a.sequence - b.sequence);
        this.cd.detectChanges();
      });
    }
  }

  showFieldEditModal(fieldID: number) {
    this.currentEditFieldPlacement = false;

    this.currentEditField = this.fields.find((field: FormField) => field.fieldID === fieldID);
    this.currentEditField.listComponent = this.currentEditField.listID;
    if (!this.currentEditField) {
      return;
    }

    this.currentEditType = this.fieldTypes.find((fieldType: FieldType) => fieldType.id === this.currentEditField.fieldTypeID);
    if (!this.currentEditField) {
      return;
    }

    this.currentEditFieldForm = this.fb.group({});
    this._createFields(this.currentEditFieldForm, this.currentEditType);
    FormHelper.initValues(this.currentEditField, this.currentEditFieldForm);
    this.currentEditFieldPlacement = true;

    this.fieldEditModalRef = this.dialog.open(this.formModal);
  }

  deleteFieldEditModal(fieldID: number) {
    this.currentDeleteFieldPlacement = false;

    this.currentDeleteField = this.fields.find((field: FormField) => field.fieldID === fieldID);
    if (!this.currentDeleteField) {
      return;
    }

    this.currentDeleteFieldPlacement = true;

    this.fieldDeleteModalRef = this.dialog.open(this.deleteModal);
  }

  confirmFieldEdit() {
    this._submitFieldEdit();
  }

  closeFieldEditModal() {
    this.fieldEditModalRef.close();
    this.fieldEditModalRef = null;
  }

  confirmFieldDelete() {
    this.closeFieldDeleteModal();

    const baseURL = `/forms/${this.form.id}/field_type/0`;

    this.formFieldService.delete(this.currentDeleteField.fieldID, baseURL).subscribe(() => {
      this._getFields(this.form.id);
    });
  }

  closeFieldDeleteModal() {
    this.fieldDeleteModalRef.close();
    this.fieldDeleteModalRef = null;
  }

  _submitFieldEdit() {
    if (this.currentEditFieldForm.invalid) {
      return;
    }

    let entity = FormHelper.buildEntity(this.currentEditField, this.currentEditFieldForm, {}) as FormField;

    entity.id = this.currentEditField.fieldID;

    const baseURL = `/forms/${this.form.id}/field_type/${this.currentEditType.id}`;

    this.closeFieldEditModal();

    FormHelper.submitForm(
      this.cd,
      this.currentEditFieldForm,
      this.formFieldService.put(entity, baseURL),
      (result: FormField) => {
        this.currentEditFieldPlacement = false;
        this.currentEditType = null;
        this.currentEditField = null;
        this.currentEditFieldForm = null;

        this.toastrService.open(ToastrMessageType.UPDATE);
        this._getFields(this.form.id);
        // this.routeNameService.goTo('families');
      }
    );
  }
}
