<ng-container *ngIf="equipment">
  <h1 mat-dialog-title>
    Changement de la conformité
  </h1>
  <form (submit)="submit()">
    <div mat-dialog-content>
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <mat-checkbox [formControl]="entityForm.get('conformity')">Conforme
        </mat-checkbox>
      </div>
    </div>
    <div mat-dialog-actions>
      <esomus-submit label="Changer"></esomus-submit>
      <button type="button" mat-stroked-button mat-dialog-close i18n="@@cancel">
        Annuler
      </button>
    </div>
  </form>
</ng-container>
