import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserLogged } from '../models/user-logged.model';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { RouteNameService } from '../services/route-name.service';
import { RightEnum, TOKEN_RIGHTS } from '../enum/right.enum';
import { Params } from '@angular/router';
import { Logger } from '../services/logger.service';

const apiUrl = environment.api.url;
const requestPath = {
  login: `${apiUrl}/login`
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user: UserLogged = null;
  private token: string = null;
  private pageRights: object;

  constructor(
    private http: HttpWrapperService,
    private router: RouteNameService,
    private jwtService: JwtHelperService,
  ) {
  }

  getToken() {
    if (this.token === null) {
      this.token = localStorage.getItem('token');
    }
    if (this.jwtService.isTokenExpired(this.token)) {
      this.logout();
      return null;
    }
    return this.token;
  }

  getUser() {
    if (this.user) {
      return this.user;
    }
    const token = this.getToken();

    if (token !== null) {
      this.user = this.jwtService.decodeToken(token);
      this.user.jsonRights = JSON.parse(this.user.rights);
      Logger.log('User global rights', this.user.jsonRights);
    }
    return this.user;
  }

  getPersonID() {
    if (!this.user || !this.user.personID) {
      return null;
    }

    return this.user.personID;
  }

  fetchPageRights(params?: Params) {
    return this.http.post(`${environment.api.url}/rights/compute`, params).pipe(tap((rights: object) => {
      for (let key in rights) {
        if (rights.hasOwnProperty(key)) {
          rights[key.toLowerCase()] = rights[key];
        }
      }
      Logger.log('Page rights', rights);
      this.pageRights = rights;
    }));
  }

  hasPageRight(right: RightEnum) {
    if (!this.pageRights.hasOwnProperty(right)) {
      console.error(`PageRights does not have the right => ${right}`);
      return false;
    }
    return this.pageRights && this.pageRights.hasOwnProperty(right) && this.pageRights[right];
  }

  hasRight(right: RightEnum) {
    if (TOKEN_RIGHTS.indexOf(right) === -1) {
      console.error(`Right missing from TokenRights => ${right}`);
      return false;
    }
    return this.user && this.user.jsonRights && this.user.jsonRights.hasOwnProperty(right) && this.user.jsonRights[right];
  }

  login(username: string, password: string): Observable<UserLogged> {
    const body = {
      username,
      password
    };
    return this.http.post<Token>(requestPath.login, body)
      .pipe(
        map<Token, UserLogged>((token: Token) => {
          localStorage.setItem('token', token.token);
          this.token = token.token;
          return this.jwtService.decodeToken(this.token) as UserLogged;
        }),
        // tap((user: UserLogged) => {
        //   console.log('TAPPING', user);
        //   this.user = user;
        //   this.user.jsonRights = JSON.parse(this.user.rights);
        // })
      );
  }

  logout(withRedirection?: boolean) {
    this.token = null;
    this.user = null;
    this.pageRights = {};
    // this.paramService.clear();
    localStorage.removeItem('token');
    if (withRedirection) {
      this.router.goTo('login');
    }
  }
}

export interface Token {
  token: string;
}
