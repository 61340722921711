import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressPanelComponent } from './components/address-panel/address-panel.component';
import { AddressModalComponent } from './components/address-modal/address-modal.component';
import { SharedModule } from '../shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [AddressPanelComponent, AddressModalComponent],
  exports: [
    AddressPanelComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatDialogModule
  ]
})
export class AddressesModule { }
