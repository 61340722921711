import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RouteNameService } from '../../../core/services/route-name.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { UserService } from '../../../core/api/user.service';
import { ToastrService } from '../../../core/services/toastr.service';
import { AccidentPerson } from '../../../core/models/accident-person.model';
import { AccidentPersonService } from '../../../core/api/accident-person.service';

@Component({
  selector: 'esomus-accident-person-view',
  templateUrl: './accident-person-view.component.html',
  styleUrls: ['./accident-person-view.component.sass']
})
export class AccidentPersonViewComponent implements OnInit {

  accidentID: number;
  accidentPerson: AccidentPerson;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private accidentPersonService: AccidentPersonService,
    private userService: UserService,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(() => {
      this.accidentID = parseInt(this.activatedRoute.snapshot.paramMap.get('accidentID'), 10);
      if (isNaN(this.accidentID)) {
        return;
      }

      const accidentPersonID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

      this._getAccidentPerson(accidentPersonID);
    });
  }

  private _getAccidentPerson(id: number) {
    this.accidentPersonService.findAccidentPerson(this.accidentID, id).subscribe((accidentPerson: AccidentPerson) => {
      this.accidentPerson = accidentPerson;

      if (this.accidentPerson.zones && this.accidentPerson.zones.length > 0) {
        this.accidentPerson.zones.sort((a, b) => a.order - b.order);
      }

      this.cd.detectChanges();
    });
  }

  editAccidentPerson() {
    this.routeNameService.goTo('accident_person_id', {accidentID: this.accidentID, id: this.accidentPerson.id});
  }
}
