import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObligationTypeComponent } from './pages/obligation-type/obligation-type.component';
import { ObligationTypeListComponent } from './pages/obligation-type-list/obligation-type-list.component';
import { SharedModule } from '../shared/shared.module';
import { DashboardModule } from '../dashboard/dashboard.module';

@NgModule({
  declarations: [ObligationTypeComponent, ObligationTypeListComponent],
  imports: [
    CommonModule,
    SharedModule,
    DashboardModule
  ]
})
export class ObligationTypesModule { }
