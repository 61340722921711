import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'esomus-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = environment.appName;

  // Change the lang attributes in the document with the language of the application (<html lang="locale"></html>)
  constructor(@Inject(DOCUMENT) doc: Document, @Inject(LOCALE_ID) locale: string, renderer: Renderer2) {
    renderer.setAttribute(doc.documentElement, 'lang', locale);
  }

  @HostListener('window:beforeunload')
  onBeforeUnload(e: any) {
    return true;
    // if (isDevMode()) {
    //   return true;
    // }
    //
    // const txt = 'Etes vous sur de vouloir quitter ?';
    // e = e || window.event;
    //
    // // For IE and Firefox
    // if (e) {
    //   e.returnValue = txt;
    // }
    //
    // // For Safari
    // return txt;
  }
}
