import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentTypeComponent } from './pages/document-type/document-type.component';
import { DocumentTypeListComponent } from './pages/document-type-list/document-type-list.component';
import { DocumentTypeViewComponent } from './pages/document-type-view/document-type-view.component';
import { MaterialModule } from '../shared/material.module';
import { SharedModule } from '../shared/shared.module';
import { DashboardModule } from '../dashboard/dashboard.module';

@NgModule({
  declarations: [DocumentTypeComponent, DocumentTypeListComponent, DocumentTypeViewComponent],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    DashboardModule
  ]
})
export class DocumentTypesModule { }
