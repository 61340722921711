import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { Effect } from '../models/effect.model';

const crudPath: CRUDPath = {
  many: `/effects/`,
  single: (id: number) => `/effects/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class EffectService extends CRUD<Effect> {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }
}
