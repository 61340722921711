import { Injectable } from '@angular/core';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService  {

  constructor(
    protected http: HttpWrapperService
  ) {
  }

  private baseURL() {
    return `${environment.api.url}/breadcrumb/`;
  }

  getByItem(itemID: number) {
    return this.http.get<any>(`${this.baseURL()}items/${itemID}`);
  }
}
