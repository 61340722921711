import { Entity } from './entity.model';
import { Picture } from './picture.model';
import { EsomusCode } from './esomus-code.model';
import { ItemType } from './item-type.model';

export class Family extends Entity {
  label: string;
  comment: string;
  nbCategories: number;
  picture: Picture;
  active: boolean;
  code: string;
  esomusCode: EsomusCode;
  itemType: ItemType;
}
