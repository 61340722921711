import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AccidentType } from '../../../core/models/accident-type.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ToastrMessageType, ToastrService } from '../../../core/services/toastr.service';
import { AccidentTypeService } from '../../../core/api/accident-type.service';
import { FormHelper } from '../../../core/services/form-helper.service';
import { FormService } from '../../../core/api/form.service';

@Component({
  selector: 'esomus-accident-type',
  templateUrl: './accident-type.component.html',
  styleUrls: ['./accident-type.component.sass']
})
export class AccidentTypeComponent implements OnInit {
  accidentType: AccidentType;

  entityForm: FormGroup;
  fieldType = FieldType;
  formOptions: EntityOption;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private accidentTypeService: AccidentTypeService,
    private formService: FormService
  ) {
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      group: [null, [Validators.required, Validators.maxLength(20)]],
      description: [null, [Validators.required, Validators.maxLength(50)]],
    });

    const id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    if (!isNaN(id)) {
      this._getAccidentType(id);
    } else {
      this.accidentType = new AccidentType();
      this._formHandler();
    }
  }

  private _formHandler() {
    if (!this.accidentType.form) {
      this.formOptions = {get: () => this.formService.findAll(), propName: 'name'};
      this.entityForm.addControl('form', this.fb.control(null));
    }
  }

  private _getAccidentType(id: number) {
    this.accidentTypeService.find(id).subscribe((result: AccidentType) => {
      this.accidentType = result;

      this._formHandler();

      FormHelper.initValues(this.accidentType, this.entityForm);
      this.cd.detectChanges();
    });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    const entity = FormHelper.buildEntity(this.accidentType, this.entityForm, {}) as AccidentType;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      (this.accidentType.id) ? this.accidentTypeService.put(entity) : this.accidentTypeService.post(entity),
      (result: AccidentType) => {
        this.toastrService.open((this.accidentType.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        this.routeNameService.goTo('accident_types');
      }
    );
  }

  getAccidentTypeDeleteURL() {
    return this.accidentTypeService.delete(this.accidentType.id);
  }

  getAccidentTypeSuccessURL() {
    return this.routeNameService.path('accident_types');
  }
}
