import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { ItemType } from '../models/item-type.model';
import { environment } from '../../../environments/environment';

const crudPath: CRUDPath = {
  many: `/item_types/`,
  single: (id: number) => `/item_types/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class ItemTypeService extends CRUD<ItemType>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  findByValue(value: number) {
    return this.http.get<ItemType>(`${environment.api.url}${this.crudPath.many}${value}`);
  }

  findAllEquipments() {
    return this.http.get<Array<ItemType>>(`${environment.api.url}${this.crudPath.many}equipments`);
  }

  findWithoutVehicule() {
    return this.http.get<Array<ItemType>>(`${environment.api.url}${this.crudPath.many}in_container`);
  }
}
