import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ToastrMessageType, ToastrService } from '../../../core/services/toastr.service';
import { Parameter } from '../../../core/models/parameter.model';
import { FormHelper } from '../../../core/services/form-helper.service';
import { ParameterService } from '../../../core/api/parameter.service';

@Component({
  selector: 'esomus-parameter',
  templateUrl: './parameter.component.html',
  styleUrls: ['./parameter.component.sass']
})
export class ParameterComponent implements OnInit {
  parameter: Parameter;

  entityForm: FormGroup;
  fieldType = FieldType;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private parameterService: ParameterService,
  ) {
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      description: [null, [Validators.required, Validators.maxLength(100)]],
      key: [null, [Validators.required, Validators.maxLength(20)]],
      value: [null, [Validators.required]],
    });

    const id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    if (!isNaN(id)) {
      this._getParameter(id);
    } else {
      this.parameter = new Parameter();
    }
  }

  private _getParameter(id: number) {
    this.parameterService.find(id).subscribe((result: Parameter) => {
      this.parameter = result;

      FormHelper.initValues(this.parameter, this.entityForm);

      this.cd.detectChanges();
    });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    const entity = FormHelper.buildEntity(this.parameter, this.entityForm, {}) as Parameter;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      (this.parameter.id) ? this.parameterService.put(entity) : this.parameterService.post(entity),
      (result: Parameter) => {
        this.toastrService.open((this.parameter.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        this.routeNameService.goTo('parameters');
      }
    );
  }
}
