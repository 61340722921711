<mat-accordion multi="true" *ngIf="user">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Utilisateur : {{user.username}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayoutAlign="center center">
          <button mat-flat-button (click)="editUser()" color="success">
            <mat-icon>edit</mat-icon>
            Editer
          </button>
        </div>
        <esomus-dashboard-print-selector *ngIf="user.id" screenID="24"
                                         [itemID]="user.id"></esomus-dashboard-print-selector>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <esomus-line-data flex="50" fxFlex="100" boldValue="Username"
                          [textValue]="user.username"></esomus-line-data>
        <esomus-line-data flex="50" fxFlex="100" boldValue="Email"
                          [textValue]="user.email"></esomus-line-data>
        <esomus-line-data flex="50" fxFlex="100" boldValue="Personne"
                          [textValue]="user.person ? user.person.fullName : null"></esomus-line-data>
        <esomus-line-data flex="50" fxFlex="100" boldValue="Langue"
                          [textValue]="getUserLanguageLabel()"></esomus-line-data>
        <esomus-line-data flex="50" fxFlex="100" boldValue="Light"
                          [boolValue]="user.light"></esomus-line-data>
        <esomus-line-data flex="50" fxFlex="100" boldValue="Actif"
                          [boolValue]="user.active"></esomus-line-data>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
