import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgrammingComponent } from './pages/programming/programming.component';
import { ProgrammingListComponent } from './pages/programming-list/programming-list.component';
import { ProgrammingViewComponent } from './pages/programming-view/programming-view.component';
import { SharedModule } from '../shared/shared.module';
import { TasksModule } from '../tasks/tasks.module';
import { DashboardModule } from '../dashboard/dashboard.module';

@NgModule({
  declarations: [ProgrammingComponent, ProgrammingListComponent, ProgrammingViewComponent],
  imports: [
    CommonModule,
    SharedModule,
    TasksModule,
    DashboardModule
  ]
})
export class ProgrammingsModule { }
