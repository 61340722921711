import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormListComponent } from './pages/form-list/form-list.component';
import { FormComponent } from './pages/form/form.component';
import { SharedModule } from '../shared/shared.module';
import { FormComponentComponent } from './components/form-component/form-component.component';
import { FormDisplayComponent } from './components/form-display/form-display.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormSingleFieldComponent } from './components/form-single-field/form-single-field.component';
import { DashboardModule } from '../dashboard/dashboard.module';

@NgModule({
  declarations: [FormListComponent, FormComponent, FormComponentComponent, FormDisplayComponent, FormSingleFieldComponent],
  exports: [
    FormComponentComponent,
    FormDisplayComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DragDropModule,
    DashboardModule
  ]
})
export class FormsModule { }
