import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskComponent } from './pages/task/task.component';
import { TaskViewComponent } from './pages/task-view/task-view.component';
import { TaskPlanificationComponent } from './pages/task-planification/task-planification.component';
import { SharedModule } from '../shared/shared.module';
import { ActionsAnomaliesModule } from '../actions-anomalies/actions-anomalies.module';
import { TaskPanelComponent } from './components/task-panel/task-panel.component';
import { TaskListComponent } from './pages/task-list/task-list.component';
import { FormsModule } from '../forms/forms.module';
import { DocumentsModule } from '../documents/documents.module';
import { MatDialogModule } from '@angular/material/dialog';
import { DashboardModule } from '../dashboard/dashboard.module';

@NgModule({
  declarations: [TaskComponent, TaskViewComponent, TaskPlanificationComponent, TaskPanelComponent, TaskListComponent],
  exports: [TaskPanelComponent],
  imports: [
    CommonModule,
    SharedModule,
    ActionsAnomaliesModule,
    FormsModule,
    DocumentsModule,
    MatDialogModule,
    DashboardModule,
  ]
})
export class TasksModule { }
