import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Entity } from '../../../core/models/entity.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { FormBuilder } from '@angular/forms';
import { RouteNameService } from '../../../core/services/route-name.service';
import { DocumentService } from '../../../core/api/document.service';
import { ColumnType, TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { Document } from '../../../core/models/document.model';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'esomus-document-table',
  templateUrl: './document-table.component.html',
  styleUrls: ['./document-table.component.sass']
})
export class DocumentTableComponent implements OnInit {

  @ViewChild('originCell', {static: true}) originCell: TemplateRef<any>;
  @ViewChild('companySiteCell', {static: true}) companySiteCell: TemplateRef<any>;

  nbDocuments: number;
  documentsTable: TableOptions;
  showDocumentModal: boolean;
  @Input() parentType: string;
  @Input() parent: Entity;
  @Input() label: string;
  @Input() showBtns: boolean;
  @Input() showActions: boolean;

  documentTableEmitter: EventEmitter<any> = new EventEmitter<any>();
  private url: Observable<any>;
  @ViewChild('removeModal', {static: true}) removeModal: TemplateRef<any>;
  removeModalRef: MatDialogRef<DocumentTableComponent>;

  currentDocumentID: number;

  constructor(
    private i18n: I18n,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private cd: ChangeDetectorRef,
    private documentService: DocumentService,
  ) {
  }

  ngOnInit() {
    let visibleCb = null;

    switch (this.parentType) {
      case 'item':
        visibleCb = (document: Document) => document.origin === 2 && this.showActions !== false;
        break;
      case 'itemDescription':
        visibleCb = (document: Document) => document.origin === 1 && this.showActions !== false;
        break;
    }

    let type = null;
    switch (this.parentType) {
      case 'category':
      case 'item':
      case 'itemDescription':
        type = 1;
        break;
      case 'anomaly':
      case 'action':
      case 'task':
      case 'accident':
      case 'closedAccident':
        type = 2;
        break;
    }

    if (this.parentType === 'itemActivity') {
      this.documentsTable = {
        columnDefs: [
          {
            prop: 'documentType.label',
            name: this.i18n({value: 'Catégorie', id: 'category'})
          },
          {
            prop: 'label',
            name: this.i18n({value: 'Titre', id: 'title'})
          },
          {
            prop: 'date', type: ColumnType.DATE,
            name: this.i18n({value: 'Date doc', id: 'docDate'})
          },
          {
            prop: 'origine', type: ColumnType.TEMPLATE, template: this.originCell,
            name: this.i18n({value: 'Originie', id: 'origin'})
          },
        ],
        findDataCb: (searchData: Params) => {
          this.currentDocumentID = null;
          return this.documentService.getParent(this.parent.id, this.parentType);
        },
        actions: {
          readCb: (entity: Entity) => this.documentService.openParent(this.parent.id, entity.id, this.parentType),
          custom: [
            {
              icon: 'cloud_download',
              customCb: (entity: Entity) => this.documentService.downloadParent(this.parent.id, entity.id, this.parentType)
            },
          ]
        }
      };
    } else {
      this.documentsTable = {
        columnDefs: [
          {
            prop: 'origin', type: ColumnType.TEMPLATE, template: this.originCell,
            name: this.i18n({value: 'Origine', id: 'origin'})
          },
          {
            prop: 'documentType.label',
            name: this.i18n({value: 'Catégorie', id: 'category'})
          },
          {
            prop: 'label',
            name: this.i18n({value: 'Libellé', id: 'label'})
          },
          {
            prop: 'companySite', type: ColumnType.TEMPLATE, template: this.companySiteCell,
            name: this.i18n({value: 'Société / Site', id: 'companySite'})
          }
        ],
        findDataCb: (searchData: Params) => {
          this.currentDocumentID = null;
          return this.documentService.getParent(this.parent.id, this.parentType);
        },
        actions: {
          canCreate: this.showBtns,
          create: [
            {
              btnName: this.i18n({value: 'Ajouter un document', id: 'btnAddDocument'}),
              createCb: () => {
                this.showDocumentModal = false;
                this.cd.detectChanges();
                this.showDocumentModal = true;
                this.cd.detectChanges();
              },
            },
          ],
          readCb: (entity: Entity) => this.documentService.openParent(this.parent.id, entity.id, this.parentType),
          custom: [
            {
              icon: 'cloud_download',
              customCb: (entity: Entity) => this.documentService.downloadParent(this.parent.id, entity.id, this.parentType)
            },
            {
              icon: type === 2 ? 'delete' : 'link_off',
              visibleCb,
              customCb: (entity: Entity) => {
                this.url = this.documentService.unlinkParent(this.parent.id, entity.id, this.parentType);

                switch (this.parentType) {
                  case 'category':
                  case 'item':
                  case 'itemDescription':
                    this._remove();
                    break;
                  case 'anomaly':
                  case 'action':
                  case 'task':
                  case 'accident':
                  case 'closedAccident':
                    this.removeModalRef = this.dialog.open(this.removeModal);
                    break;
                }
              }
            },
            {
              icon: 'refresh',
              visibleCb: (document: Document) => {
                if (visibleCb) {
                  if (!visibleCb(document)) {
                    return false;
                  }
                }

                if (type !== 1) {
                  return false;
                }

                return true;
              },
              customCb: (entity: Document) => {
                this.currentDocumentID = null;
                this.cd.detectChanges();
                this.currentDocumentID = entity.mainId;
                this.cd.detectChanges();
              }
            },
          ]
        }
      };
    }
  }

  updateNbDocuments(nbDocuments: number) {
    this.nbDocuments = nbDocuments;
  }

  confirmRemove() {
    this.closeRemoveModal();
    this._remove();
  }

  closeRemoveModal() {
    this.removeModalRef.close();
    this.removeModalRef = null;
  }

  private _remove() {
    this.url.subscribe(() => {
      this.url = null;
      this.documentTableEmitter.emit();
    });
  }
}
