import { Entity } from './entity.model';
import { Item } from './item.model';
import { AnomalyType } from './anomaly-type.model';
import { Form } from './form.model';
import { Picture } from './picture.model';
import { Person } from './person.model';

export class Anomaly extends Entity {
  description: string;
  comment: string;
  severity: number;
  emergency: number;
  deadlineDate: string;
  resolutionDate: string;
  closed: boolean;
  status: number;
  item: Item;
  anomalyType: AnomalyType;
  form: Form;
  picture: Picture;
  person: Person;
  nbActions: number;
}
