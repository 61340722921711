import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { ItemType } from '../../../core/models/item-type.model';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { Item } from '../../../core/models/item.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ItemTypeService } from '../../../core/api/item-type.service';
import { CategoryService } from '../../../core/api/category.service';
import { FamilyService } from '../../../core/api/family.service';
import { ToastrMessageType, ToastrService } from '../../../core/services/toastr.service';
import { SiteService } from '../../../core/api/site.service';
import { ItemDescriptionService } from '../../../core/api/item-description.service';
import { FormHelper } from '../../../core/services/form-helper.service';
import { CompanyService } from '../../../core/api/company.service';
import { Entity } from '../../../core/models/entity.model';

@Component({
  selector: 'esomus-site-add',
  templateUrl: './site-add.component.html',
  styleUrls: ['./site-add.component.sass']
})
export class SiteAddComponent implements OnInit {
  entityForm: FormGroup;
  fieldType = FieldType;
  itemType: ItemType;

  familyOptions: EntityOption;
  categoryOptions: EntityOption;
  itemDescriptionOptions: EntityOption;

  site: Item;
  company: Item;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private itemTypeService: ItemTypeService,
    private categoryService: CategoryService,
    private familyService: FamilyService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private companyService: CompanyService,
    private siteService: SiteService,
    private itemDescriptionService: ItemDescriptionService
  ) {
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      description: []
    });

    const companyID = parseInt(this.activatedRoute.snapshot.paramMap.get('companyID'), 10);

    this._getCompany(companyID);

    this.itemTypeService.findByValue(2).subscribe((result: ItemType) => {
      this.itemType = result;
      this.entityForm.addControl('itemType', this.fb.control(this.itemType.id));

      this.updateFamily();
    });
  }

  private _getCompany(companyID: number) {
    this.companyService.find(companyID).subscribe((company: Item) => {
      this.company = company;
      this.site = new Item();
      this.site.parent = company;

      this.cd.detectChanges();
    });
  }

  submit() {
    const entity = FormHelper.buildEntity(this.site, this.entityForm, {}) as Item;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      this.siteService.postFromParent(entity, this.company.id),
      (result: Entity) => {
        this.toastrService.open((entity.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        this.routeNameService.goTo('site_id', {id: result.id});
      }
    );
  }

  updateFamily() {
    if (this.entityForm.contains('family')) {
      this.entityForm.removeControl('family');
    }
    this.familyOptions = null;

    this.cd.detectChanges();
    this.entityForm.addControl('family', this.fb.control(null));
    this.familyOptions = {get: () => this.familyService.findAllByItemItem(this.itemType.value), propName: 'label', autoSelect: true};
    this.cd.detectChanges();
  }

  updateCategory() {
    const familyID = this.entityForm.get('family').value;

    if (this.entityForm.contains('category')) {
      this.entityForm.removeControl('category');
    }
    this.categoryOptions = null;
    if (!familyID) {
      return;
    }
    this.cd.detectChanges();
    this.entityForm.addControl('category', this.fb.control(null));
    this.categoryOptions = {get: () => this.categoryService.findAllByParent(familyID), propName: 'label', autoSelect: true};
    this.cd.detectChanges();
  }

  updateItemCategory() {
    const categoryID = this.entityForm.get('category').value;

    if (this.entityForm.contains('itemDescription')) {
      this.entityForm.removeControl('itemDescription');
    }
    this.itemDescriptionOptions = null;
    if (!categoryID) {
      return;
    }
    this.cd.detectChanges();
    this.entityForm.addControl('itemDescription', this.fb.control(null));
    this.itemDescriptionOptions = {get: () => this.itemDescriptionService.findByCategory(categoryID), propName: 'label', autoSelect: true};
    this.cd.detectChanges();
  }
}
