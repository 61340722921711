import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Document } from '../../../core/models/document.model';
import { FormHelper } from '../../../core/services/form-helper.service';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { DocumentService } from '../../../core/api/document.service';

@Component({
  selector: 'esomus-document-version',
  templateUrl: './document-version.component.html',
  styleUrls: ['./document-version.component.sass']
})
export class DocumentVersionComponent implements OnInit {
  fieldType = FieldType;

  @ViewChild('formVersionModal', { static: true }) formVersionModal: TemplateRef<any>;
  formVersionModalRef: MatDialogRef<DocumentVersionComponent>;
  versionForm: FormGroup;
  previousDocument: Document;
  currentDocument: Document;

  @Input() previousID: number;
  @Input() documentTableEmitter: EventEmitter<any>;

  constructor(
    private i18n: I18n,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private documentService: DocumentService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this._getPreviousDocument();
  }

  private _getPreviousDocument() {
    this.documentService.find(this.previousID).subscribe((doc: Document) => {
      this.previousDocument = doc;
      this.currentDocument = new Document();

      this.versionForm = this.fb.group({
        uploadedFile: [null, [Validators.required]],
        version: [this.previousDocument.version, [Validators.required]],
      });

      this.formVersionModalRef = this.dialog.open(this.formVersionModal);
    });
  }

  submitVersion() {
    if (this.versionForm.invalid) {
      return;
    }

    this.closeVersion();

    let entity = FormHelper.buildEntity(this.currentDocument, this.versionForm, {
      uploadedFile: {type: FieldType.FILE, multiple: false}
    }) as Document;

    FormHelper.submitForm(
      this.cd,
      this.versionForm,
      this.documentService.postVersion(this.previousDocument.id, entity),
      (result: Document) => {
        this.documentTableEmitter.emit();
      }
    );
  }

  closeVersion() {
    this.formVersionModalRef.close();
    this.formVersionModalRef = null;
  }
}
