import { Entity } from './entity.model';
import { LightItem } from './light-item.model';
import { DocumentType } from './document-type.model';

export class Document extends Entity {
  mainId: number;
  date: string;
  results: string;
  origin: number;
  label: string;
  version: string;
  active: boolean;
  uploadedFile: File;
  documentType: DocumentType;

  originid: number;
  description: string;

  company: LightItem;
  site: LightItem;
}
