import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { Item } from '../models/item.model';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';

const crudPath: CRUDPath = {
  many: `/sites/`,
  single: (id: number) => `/sites/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class SiteService extends CRUD<Item>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  postFromParent(entity: Item, parentID: number): Observable<Item> {
    const body = this._createFormData(entity);
    return this.http.post<Item>(`${environment.api.url}${this.crudPath.single(parentID)}`, body);
  }

  getAllBuildings(siteID: number, params?: Params) {
    return this.http.get<Array<Item>>(`${environment.api.url}${this.crudPath.single(siteID)}/buildings`, params);
  }
}
