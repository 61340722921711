<mat-card class="image-box-card" fxFlex="100" (click)="redirect()">
  <mat-card-header class="image-box-header">
    <esomus-img fxFlex="100" [picture]="imageSource"></esomus-img>
  </mat-card-header>
  <mat-card-content>
    <p class="center">
      <button fxFlex="100" mat-raised-button color="accent">
        {{text}}
        <ng-container *ngIf="comment">
          <small class="image-box-comment">{{comment}}</small>
        </ng-container>
      </button>
    </p>
  </mat-card-content>
</mat-card>
<!--<img width="300" height="300" [attr.src]="imageSource === null && imageSource || 'assets/img/empty.png'" alt="DEMO">-->
