import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskTypeComponent } from './pages/task-type/task-type.component';
import { TaskTypeListComponent } from './pages/task-type-list/task-type-list.component';
import { TaskTypeViewComponent } from './pages/task-type-view/task-type-view.component';
import { SharedModule } from '../shared/shared.module';
import { DashboardModule } from '../dashboard/dashboard.module';

@NgModule({
  declarations: [TaskTypeComponent, TaskTypeListComponent, TaskTypeViewComponent],
  imports: [
    CommonModule,
    SharedModule,
    DashboardModule
  ]
})
export class TaskTypesModule { }
