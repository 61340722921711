import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZoneComponent } from './pages/zone/zone.component';
import { ZoneListComponent } from './pages/zone-list/zone-list.component';
import { SharedModule } from '../shared/shared.module';
import { DashboardModule } from '../dashboard/dashboard.module';


@NgModule({
  declarations: [ZoneComponent, ZoneListComponent],
  imports: [
    CommonModule,
    SharedModule,
    DashboardModule
  ]
})
export class ZonesModule { }
