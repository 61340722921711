import { Component, ElementRef, EventEmitter, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ColumnSortDirection, ColumnType, TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ActionAnomalyService } from '../../../core/api/action-anomaly.service';
import { ActionAnomaly } from '../../../core/models/action-anomaly.model';
import { TaskHelper } from '../../../core/services/task-helper.service';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { Params } from '@angular/router';
import { BuildingService } from '../../../core/api/building.service';
import { map } from 'rxjs/operators';
import { LightItem } from '../../../core/models/light-item.model';
import { TaskService } from '../../../core/api/task.service';
import { Task } from '../../../core/models/task.model';

@Component({
  selector: 'esomus-action-anomaly-table',
  templateUrl: './action-anomaly-table.component.html',
  styleUrls: ['./action-anomaly-table.component.sass']
})
export class ActionAnomalyTableComponent implements OnInit {
  @ViewChild('typeCell', {static: true}) typeCell: TemplateRef<any>;
  @ViewChild('labelCell', {static: true}) labelCell: TemplateRef<any>;
  @ViewChild('statusCell', {static: true}) statusCell: TemplateRef<any>;
  @ViewChild('actionAnomalyCell', {static: true}) actionAnomalyCell: TemplateRef<any>;
  @ViewChild('fromAnomalyCell', {static: true}) fromAnomalyCell: TemplateRef<any>;
  @ViewChild('headerActionAnomalyTable', {static: true}) headerActionAnomalyTable: TemplateRef<any>;
  search: EventEmitter<any> = new EventEmitter<any>();
  cOptions: EntityOption;
  form: FormGroup;

  @Input() equipmentID: number;
  @Input() anomalyID: number;
  @Input() accidentID: number;
  @Input() taskID: number;
  @Input() showAnomalyBtn = true;
  @Input() showActionBtn = true;
  @Input() actionAddRouteName: string;
  @Input() expanded = false;
  @Input() label: string;
  @Input() displayAll: boolean;

  itemTable: TableOptions;
  taskHelper = TaskHelper;

  buildingOptions: EntityOption;

  nbItems: number;
  currentBuildingID = null;

  constructor(
    private element: ElementRef,
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private actionAnomalyService: ActionAnomalyService,
    private fb: FormBuilder,
    private buildingService: BuildingService,
    private taskService: TaskService
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      c: [this.displayAll ? -1 : 0]
    });
    let urlParams = {};

    if (this.equipmentID) {
      urlParams['equipmentID'] = this.equipmentID;
    } else if (this.anomalyID) {
      urlParams['anomalyID'] = this.anomalyID;
    } else if (this.accidentID) {
      urlParams['accidentID'] = this.accidentID;
    } else if (this.taskID) {
      urlParams['taskID'] = this.taskID;

      this.buildingOptions = {
        get: () =>
          this.buildingService.findAllByTask(this.taskID).pipe(
            map((items: Array<LightItem>) => {
              if (items.length) {
                let all = new LightItem();
                all.id = -1;
                all.name = 'Tous';
                items.push(all);
              }
              return items;
            })
          ),
        propName: 'name'
      };
      this.form.addControl('building', this.fb.control(null));
    }

    let createBtns = [];

    if (this.showActionBtn) {
      createBtns.push({
          btnName: 'Ajouter une action',
          createCb: () => {
            this.routeNameService.goTo(this.actionAddRouteName, urlParams);
          }
        },
      );
    }
    if (this.showAnomalyBtn) {
      createBtns.push({
        btnName: 'Ajouter une anomalie',
        createCb: () => {
          this.routeNameService.goTo('anomaly_add', urlParams);
        }
      });
    }

    this.cOptions = {
      get: () => of([
        {id: -1, label: 'Tout'}, {id: 0, label: 'Ouvert'}, {id: 1, label: 'Clôturé'}
      ]), propName: 'label'
    };

    this.itemTable = {
      columnDefs: [
        {
          name: this.i18n({value: 'C', id: 'c'}),
          prop: 'c', type: ColumnType.CHECKBOX, width: '5%'
        },
        {
          name: null, width: '5%',
          prop: 'actionOrAnomaly', type: ColumnType.TEMPLATE, template: this.actionAnomalyCell
        },
        {
          name: null, width: '5%',
          prop: 'fromAnomaly', type: ColumnType.TEMPLATE, template: this.fromAnomalyCell
        },
        {
          name: this.i18n({value: 'Catégorie', id: 'category'}), width: '15%',
          prop: 'type', type: ColumnType.TEMPLATE, template: this.typeCell
        },
        {
          name: this.i18n({value: 'Description', id: 'description'}), width: '25%',
          prop: 'label', type: ColumnType.TEMPLATE, template: this.labelCell
        },
        {
          name: this.i18n({value: 'Date', id: 'date'}),
          prop: 'date', type: ColumnType.DATE
        },
        {
          name: this.i18n({value: 'Gravité', id: 'severity'}),
          prop: 'severity', valueCb: (entity: ActionAnomaly) => TaskHelper.getSeverityLabel(entity.severity)
        },
        {
          name: this.i18n({value: 'Urgence', id: 'emergency'}),
          prop: 'emergency', valueCb: (entity: ActionAnomaly) => TaskHelper.getLabel(entity.emergency)
        },
        {
          name: this.i18n({value: 'Statut', id: 'status'}),
          prop: 'status', type: ColumnType.TEMPLATE, template: this.statusCell,
        },
      ],
      findDataCb: (params: Params) => {
        if (!params.hasOwnProperty('type') || params.type === null || params.type === undefined) {
          if (this.displayAll) {
            params.type = -1;
          } else {
            params.type = 0;
          }
        }
        let url = null;
        if (this.equipmentID) {
          url = this.actionAnomalyService.findAllByItem(this.equipmentID, params);
        } else if (this.anomalyID) {
          url = this.actionAnomalyService.findAllByAnomaly(this.anomalyID, params);
        } else if (this.accidentID) {
          url = this.actionAnomalyService.findAllByAccident(this.accidentID, params);
        } else if (this.taskID) {
          url = this.actionAnomalyService.findAllByTask(this.taskID, params);
        }
        return url;
      },
      defaultSortActive: 'c',
      defaultSortDirection: ColumnSortDirection.ASC,
      actions: {
        create: createBtns,
        readCb: (entity: ActionAnomaly) => {
          switch (entity.type) {
            case 0:
              this.routeNameService.goTo('anomaly_view', {id: entity.id});
              break;
            case 1:
              this.routeNameService.goTo('action_view', {id: entity.id});
              break;
          }
        },
        // updateCb: (entity: Entity) => this.routeNameService.goTo('item_id', { id: entity.id }),
        // deleteCb: (entity: Entity) => this.itemService.delete(entity.id)
      },
      headTemplate: this.headerActionAnomalyTable
    };
  }

  updateNbItems(nbItems: number) {
    this.nbItems = nbItems;
  }

  cChange() {
    let c = this.form.get('c').value;
    if (c === null) {
      c = -1;
    }
    this.search.emit({
      type: c
    });
  }

  buildingChange() {
    this.currentBuildingID = this.form.get('building').value;
  }

  closeActions() {
    if (!this.currentBuildingID) {
      return;
    }

    this.taskService.postValidateActionByBuilding(this.taskID, this.currentBuildingID).subscribe((task: Task) => {
      this.currentBuildingID = null;

      this.form.get('building').setValue(null);

      this.cChange();
    });
  }
}
