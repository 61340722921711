<mat-accordion multi="true" *ngIf="building">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Bâtiment : {{building.label}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
        <esomus-line-membership fxFlex="100" [item]="building"
                                [showAll]="false"></esomus-line-membership>
        <div fxLayoutAlign="center center">
          <button *ngIf="showLocationImplementBtns" mat-flat-button (click)="editBuilding()"
                  color="success">
            <mat-icon>edit</mat-icon>
            Editer
          </button>
        </div>
        <esomus-dashboard-print-selector screenID="3"
                                         [itemID]="building.id"></esomus-dashboard-print-selector>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex="30" fxFlex.xs="100">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <esomus-img fxFlex="100" [picture]="building.picture"></esomus-img>
            <esomus-line-data fxFlex="100" boldFlex="50" textFlex="50"
                              boldValue="Code esomus"
                              [textValue]="building.code"></esomus-line-data>
          </div>
        </div>
        <div fxLayout="column" fxFlex>
          <div fxLayout="row wrap" fxLayoutAlign="start">
            <esomus-line-data flex="50" fxFlex="100" boldValue="Libellé"
                              [textValue]="building.description"></esomus-line-data>
            <esomus-line-data flex="50" fxFlex="100" boldValue="Addresse"
                              [textValue]="building.address.line1"></esomus-line-data>
            <esomus-line-data flex="50" fxFlex="100" boldValue="Coordonées"
                              [textValue]="building.address.coordinates"></esomus-line-data>
            <esomus-line-data flex="50" fxFlex="100" boldValue="Commentaire"
                              [textValue]="building.comment"></esomus-line-data>
            <esomus-line-data flex="50" fxFlex="100" boldValue="Catégorie"
                              [textValue]="building.category.label"
                              [textURL]="getCategoryURL()"></esomus-line-data>
            <esomus-line-data flex="50" fxFlex="100" boldValue="Fiche"
                              [textValue]="building.itemDescription.label"
                              [textURL]="getItemDescriptionURL()"></esomus-line-data>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Locaux : {{nbLocals}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <esomus-table *ngIf="localsTable" (getLength)="updateNbLocals($event)"
                  [options]="localsTable"></esomus-table>
  </mat-expansion-panel>
  <esomus-equipment-table [showBtns]="showEquipmentImplementBtns" [parentID]="building.id"
                          [expanded]="true" label="Equipements"></esomus-equipment-table>
  <mat-expansion-panel *ngIf="building.category.form || building.itemDescription.form">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Information complémentaire
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <esomus-form-display *ngIf="building.category.form"
                               [fetchDataURL]="getFetchDataURL(building.category.form.id)"></esomus-form-display>
        </div>
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <esomus-form-display *ngIf="building.itemDescription.form"
                               [fetchDataURL]="getFetchDataURL(building.itemDescription.form.id)"></esomus-form-display>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <esomus-document-table [showBtns]="showLocationManageBtns" [showActions]="showLocationManageBtns"
                         [parent]="building" parentType="item"></esomus-document-table>
  <esomus-contact-table [showBtns]="showLocationManageBtns" [showActions]="showLocationManageBtns"
                        [parent]="building" [parentType]="'item'"></esomus-contact-table>
</mat-accordion>
