import { Entity } from './entity.model';
import { Form } from './form.model';

export class AnomalyType extends Entity {
  label: string;
  severity: number;
  emergency: number;
  handled: boolean;
  form: Form;
  active: boolean;
}
