<form>
  <mat-accordion multi="true" class="mat-edit" *ngIf="accident">
    <mat-expansion-panel expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Accident: clôture
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="container">
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px grid">
          <div fxLayout="column" fxFlex="50">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-input [type]="fieldType.DATE"
                            [control]="entityForm.get('closedDate')"
                            i18n-label="@@closedDate" label="Date de clôture" flex="100"
                            fxFlex="100"></esomus-input>
            </div>
          </div>
          <div fxLayout="column" fxFlex="100">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-textarea [control]="entityForm.get('closedComment')"
                               i18n-label="@@closedComment" label="Commentaire de clôture"
                               flex="100" fxFlex="100"></esomus-textarea>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <div fxLayout="row wrap" fxLayoutAlign="start">
      <div fxLayout="column" fxFlex="100" fxFlex.xs="100">
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <div class="btn-group-left" fxFlex="50">
          </div>
          <div class="btn-group-right" fxFlex="50">
            <esomus-submit label="Clôturer" (submit)="submit()"></esomus-submit>
          </div>
        </div>
      </div>
    </div>
  </mat-accordion>
</form>
