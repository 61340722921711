import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnomalyComponent } from './pages/anomaly/anomaly.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '../forms/forms.module';
import { AnomalyViewComponent } from './pages/anomaly-view/anomaly-view.component';
import { EquipmentsModule } from '../equipments/equipments.module';
import { ActionsAnomaliesModule } from '../actions-anomalies/actions-anomalies.module';
import { AnomalyPanelComponent } from './components/anomaly-panel/anomaly-panel.component';
import { DocumentsModule } from '../documents/documents.module';
import { DashboardModule } from '../dashboard/dashboard.module';

@NgModule({
  declarations: [AnomalyComponent, AnomalyViewComponent, AnomalyPanelComponent],
  exports: [
    AnomalyPanelComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    EquipmentsModule,
    ActionsAnomaliesModule,
    DocumentsModule,
    DashboardModule
  ]
})
export class AnomaliesModule { }
