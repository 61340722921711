<ng-template #formModal>
  <form *ngIf="currentItem">
    <h3 mat-dialog-title>
      <span>Déplacement d'un équipement</span>
    </h3>
    <div mat-dialog-content style="width: 640px; max-width: 640px;">
      <div fxLayout="row wrap" fxLayoutAlign="start center">
        <esomus-line-data fxFlex="50" boldValue="Entreprise"
                          [textValue]="currentItem.company.name"></esomus-line-data>
        <esomus-line-data fxFlex="50" boldValue="Site"
                          [textValue]="currentItem.site.name"></esomus-line-data>
        <esomus-ng-select fxFlex="100" flex="100" label="Bâtiment"
                          [control]="entityForm.get('building')" [options]="buildingOptions"
                          (valueChange)="changeBuilding($event)"></esomus-ng-select>
        <esomus-ng-select fxFlex="100" flex="100" label="Local" *ngIf="localOptions"
                          [control]="entityForm.get('local')" [options]="localOptions"
                          (valueChange)="changeLocal($event)"></esomus-ng-select>
        <esomus-ng-select fxFlex="100" flex="100" label="Conteneur" *ngIf="containerOptions"
                          [control]="entityForm.get('container')" (valueChange)="updateSituation()"
                          [options]="containerOptions"></esomus-ng-select>
        <esomus-ng-select fxFlex="100" flex="100" label="Placement" *ngIf="situationOptions && entityForm.contains('situation')"
                          [control]="entityForm.get('situation')"
                          [options]="situationOptions"></esomus-ng-select>
      </div>
    </div>
    <div mat-dialog-actions>
      <esomus-submit label="Déplacer" (submit)="confirm()"></esomus-submit>
      <button type="button" mat-stroked-button i18n="@@cancel" (click)="closeModal()">
        Annuler
      </button>
    </div>
  </form>
</ng-template>
