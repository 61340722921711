<div class="breadcrumbs">
  <!-- <div class="title">{{ currentPage }}</div> -->
  <div class="crumbs" fxLayout="row" fxLayoutAlign="start center">
    <!-- <a class="crumb home" [routerLink]="['/']">Home</a> -->
    <div class="crumb" *ngFor="let page of previousPages" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="chevron">chevron_right</mat-icon>
      <a class="link" [routerLink]="page.routeName| path">{{ page.name }}</a>
    </div>
    <div class="crumb" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="chevron">chevron_right</mat-icon>
      <div class="link">{{ currentPage }}</div>
    </div>
  </div>
</div>
