<mat-accordion multi="true" class="mat-edit" *ngIf="parameter">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Paramètre: {{parameter.id ? 'Edition' : 'Création'}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <form>
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-input [autofocus]="true" [type]="fieldType.TEXT"
                            [control]="entityForm.get('description')"
                            i18n-label="@@description" label="Description" flex="100"
                            fxFlex="100"></esomus-input>
              <esomus-input *ngIf="entityForm.contains('key')" [type]="fieldType.TEXT"
                            [control]="entityForm.get('key')"
                            i18n-label="@@key" label="Clé" flex="100"
                            fxFlex="100"></esomus-input>
              <esomus-input [type]="fieldType.TEXT"
                            [control]="entityForm.get('value')"
                            i18n-label="@@value" label="Valeur" flex="100"
                            fxFlex="100"></esomus-input>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <div class="btn-group-left" fxFlex="50">
<!--                <esomus-delete-button *ngIf="parameter.id && parameter.active"-->
<!--                                      [url]="getParameterDeleteURL()"-->
<!--                                      [successUrl]="getParameterSuccessURL()">-->
<!--                </esomus-delete-button>-->
              </div>
              <div class="btn-group-right" fxFlex="50">
                <esomus-back-button></esomus-back-button>
                <esomus-submit (submit)="submit()"></esomus-submit>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>
