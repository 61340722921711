import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Entity } from '../../../core/models/entity.model';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { Contact } from '../../../core/models/contact.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ContactService } from '../../../core/api/contact.service';
import { CompanyService } from '../../../core/api/company.service';
import { FormHelper } from '../../../core/services/form-helper.service';
import { PersonService } from '../../../core/api/person.service';
import { RoleService } from '../../../core/api/role.service';

@Component({
  selector: 'esomus-contact-link',
  templateUrl: './contact-link.component.html',
  styleUrls: ['./contact-link.component.sass']
})
export class ContactLinkComponent implements OnInit {

  @Input() parent: Entity;
  @Input() parentType: string;
  @Input() contactTableEmitter: EventEmitter<any>;

  @ViewChild('formModal', { static: true }) formModal: TemplateRef<any>;

  fieldType = FieldType;

  formModalRef: MatDialogRef<ContactLinkComponent>;

  contactForm: FormGroup;

  contactOptions: EntityOption;
  roleOptions: EntityOption;
  companyOptions: EntityOption;
  siteOptions: EntityOption;

  pickCompanyAndSite: boolean;

  contact: Contact;

  constructor(
    private i18n: I18n,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private contactService: ContactService,
    private cd: ChangeDetectorRef,
    private companyService: CompanyService,
    private personService: PersonService,
    private roleService: RoleService
  ) {
  }

  ngOnInit() {
    switch (this.parentType) {
      case 'itemDescription':
        this.pickCompanyAndSite = true;
        break;
      default:
        this.pickCompanyAndSite = false;
    }

    this.contact = new Contact();

    this.contactOptions = {get: () => this.personService.findAll(), propName: 'fullName'};
    this.roleOptions = {get: () => this.roleService.findAll(), propName: 'description'};
    this.companyOptions = {get: () => this.companyService.findAll(), propName: 'label'};

    this.contactForm = this.fb.group({
      role: [null, [Validators.required]],
      contact: [null, [Validators.required]]
    });

    if (this.pickCompanyAndSite) {
      this.contactForm.addControl('company', this.fb.control(null));
    }

    this.formModalRef = this.dialog.open(this.formModal);

    this.cd.detectChanges();
  }

  updateSite() {
    const companyID = this.contactForm.get('company').value;

    if (!companyID) {
      return;
    }

    this.contactForm.addControl('site', this.fb.control(null));
    this.siteOptions = {get: () => this.companyService.getAllSites(companyID), propName: 'label'};
    this.cd.detectChanges();
  }

  submitContact() {
    if (this.contactForm.invalid) {
      return;
    }

    let entity = FormHelper.buildEntity(this.contact, this.contactForm, {}) as Contact;

    FormHelper.submitForm(
      this.cd,
      this.contactForm,
      this.contactService.postParent(entity, this.parent, this.parentType),
      (result: Contact) => {
        this.formModalRef.close();
        this.formModalRef = null;

        this.contactTableEmitter.emit();
      }
    );
  }

  closeContact() {
    this.formModalRef.close();
    this.formModalRef = null;
  }
}
