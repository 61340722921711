<mat-accordion multi="true" class="mat-edit">
  <mat-expansion-panel expanded="true" *ngIf="documentType">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Type de document: {{documentType.id ? 'Edition' : 'Création'}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <form>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px">
          <esomus-input [autofocus]="true" [type]="formFieldType.TEXT"
                        [control]="entityForm.get('label')" i18n-label="@@label" label="Libellé"
                        flex="50" fxFlex="100"></esomus-input>
          <esomus-input [type]="formFieldType.TEXT" [control]="entityForm.get('group')"
                        i18n-label="@@group" label="Groupe" flex="50" fxFlex="100"></esomus-input>
          <esomus-input [type]="formFieldType.NUMBER" [control]="entityForm.get('duration')"
                        i18n-label="@@duration" label="Durée" flex="50" fxFlex="100"></esomus-input>
          <mat-checkbox [formControl]="entityForm.get('legal')" fxFlex="50">Légal</mat-checkbox>
          <mat-checkbox [formControl]="entityForm.get('unique')" fxFlex="50">Unique</mat-checkbox>
          <esomus-ng-select *ngIf="typeOptions" [options]="typeOptions"
                            [control]="entityForm.get('type')" label="Type doc." flex="50"
                            fxFlex="100"></esomus-ng-select>
          <esomus-ng-select *ngIf="usageTypeOptions" [options]="usageTypeOptions"
                            [control]="entityForm.get('usageType')" label="Type d'utilisation"
                            flex="50" fxFlex="100"></esomus-ng-select>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <div class="btn-group-left" fxFlex="50">
                <esomus-delete-button *ngIf="documentType.id && documentType.active"
                                      [url]="getDocumentTypeDeleteURL()"
                                      [successUrl]="getDocumentTypeSuccessURL()"></esomus-delete-button>
              </div>
              <div class="btn-group-right" fxFlex="50">
                <esomus-back-button></esomus-back-button>
                <esomus-submit (submit)="submit()"></esomus-submit>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>
