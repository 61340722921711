import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Programming } from '../../../core/models/programming.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ProgrammingService } from '../../../core/api/programming.service';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ToastrService } from '../../../core/services/toastr.service';
import { TaskHelper } from '../../../core/services/task-helper.service';

@Component({
  selector: 'esomus-programming-view',
  templateUrl: './programming-view.component.html',
  styleUrls: ['./programming-view.component.sass']
})
export class ProgrammingViewComponent implements OnInit {
  programming: Programming;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private programmingService: ProgrammingService,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    const programmingID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    this._getProgramming(programmingID);
  }

  private _getProgramming(programmingID: number) {
    this.programmingService.find(programmingID).subscribe((programming: Programming) => {
      this.programming = programming;

      this.cd.detectChanges();
    });
  }

  editProgramming() {
    this.routeNameService.goTo('programming_id', {id: this.programming.id});
  }

  getFrequence() {
    if (this.programming.unit !== null && this.programming.frequence !== null) {
      return `${this.programming.frequence} ${TaskHelper.getProgrammingFrequenceLabel(this.programming.unit)}`;
    }
    return null;
  }
}
