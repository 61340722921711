import { HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';

/**
 * From Date to YYYY-MM-DDTHH:mm:iiZ
 */
export function toJSONWithoutMs(date: Date): string {
  if (!date || !(date instanceof Date) || isNaN(date.getDate())) {
    return null;
  }
  return date.toJSON().replace(/\.[0-9]{3}Z$/, 'Z');
}

/**
 * From Date to YYYY-MM-DD
 */
export function toDateInput(date: Date): string {
  if (!date || !(date instanceof Date)) {
    return null;
  }
  return `${date.getFullYear()}-${toPadNumber(date.getMonth() + 1)}-${toPadNumber(date.getDate())}`;
}

/**
 * From number to string (Format pad number : two digits)
 */
export function toPadNumber(num: number): string {
  if (num >= 0 && num < 10) {
    return `0${num}`;
  }
  return `${num}`;
}

export function saveFile(response: HttpResponse<Blob>) {
  let filename = response.headers.get('content-disposition').split('filename=')[1].replace(/"/g, '');
  saveAs(response.body, filename);
}

/**
 * Properties string format : ex 'prop1.prop2.prop3'
 * return the value found
 */
export function propertiesToValue(properties: string, obj: object) {
  let propList = properties.split('.');
  let value = obj;
  let i = 0;

  while (i < propList.length && value !== null) {
    const property = propList[i];
    if (value.hasOwnProperty(property)) {
      value = value[property];
    } else {
      value = null;
    }
    i++;
  }
  return value;
}
