import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { FieldType } from '../models/field-type.model';

const crudPath: CRUDPath = {
  many: `/field_types/`,
  single: (id: number) => `/field_types/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class FieldTypeService extends CRUD<FieldType>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }
}
