<div fxLayout="row" fxLayoutAlign="center center" style="height: 100%;">
  <div class="login mat-elevation-z12">
    <div class="login-header" fxLayout="column" fxLayoutAlign="center center">
      <img class="logo" src="assets/img/esomus-logo.png">
    </div>
    <form #form="ngForm" [formGroup]="loginForm" (ngSubmit)="login(form)">
      <div class="login-content" fxLayout="column" fxLayoutAlign="start stretch">
        <esomus-input i18n-label="@@email" label="E-mail" [control]="loginForm.get('email')" [flex]="100"></esomus-input>
        <esomus-input i18n-label="@@password" label="Mot de passe" [control]="loginForm.get('password')" [flex]="100"
          type="password"></esomus-input>
        <esomus-submit i18n-label="@@login" label="Se connecter" [invalid]="loginForm.invalid"
          [submitted]="loginFormSubmitted" [btnBlock]="true"></esomus-submit>
        <div class="extra-options" fxLayout="row" fxLayoutAlign="space-around center">
          <a class="forgot-password" [routerLink]="['']" i18n="@@forgotPassword">
            Mot de passe oublié ?</a>
        </div>
      </div>
    </form>
  </div>
</div>
