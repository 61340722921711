import { ChangeDetectorRef, Component, EventEmitter, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Task } from '../../../core/models/task.model';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { ItemSituation } from '../../../core/models/item-situation.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouteNameService } from '../../../core/services/route-name.service';
import { CategoryService } from '../../../core/api/category.service';
import { FamilyService } from '../../../core/api/family.service';
import { ToastrService } from '../../../core/services/toastr.service';
import { PersonService } from '../../../core/api/person.service';
import { of } from 'rxjs';
import { TaskTypeService } from '../../../core/api/task-type.service';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { FormService } from '../../../core/api/form.service';
import { CompanyService } from '../../../core/api/company.service';
import { SiteService } from '../../../core/api/site.service';
import { BuildingService } from '../../../core/api/building.service';
import { ItemSituationService } from '../../../core/api/item-situation.service';
import { TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { Entity } from '../../../core/models/entity.model';
import { Item } from '../../../core/models/item.model';
import { Family } from '../../../core/models/family.model';
import { Category } from '../../../core/models/category.model';
import { FormHelper } from '../../../core/services/form-helper.service';
import { Action } from '../../../core/models/action.model';
import { TaskService } from '../../../core/api/task.service';
import { TaskHelper } from '../../../core/services/task-helper.service';
import { TaskScope } from '../../../core/models/task-scope.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../../core/api/auth.service';

@Component({
  selector: 'esomus-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.sass']
})
export class TaskComponent implements OnInit {
  private latestTmpId = -1;
  isEditable: boolean;

  scopeForm: FormGroup;
  entityForm: FormGroup;
  fieldType = FieldType;

  task: Task;

  emergencyOptions: EntityOption;
  personOptions: EntityOption;
  taskTypeOptions: EntityOption;
  formOptions: EntityOption;
  companyOptions: EntityOption;
  siteOptions: EntityOption;
  buildingOptions: EntityOption;
  localOptions: EntityOption;
  familyOptions: EntityOption;
  categoryOptions: EntityOption;
  situationOptions: EntityOption;
  statusOptions: EntityOption;

  companyData: Array<Item>;
  siteData: Array<Item>;
  buildingData: Array<Item>;
  localData: Array<Item>;
  familyData: Array<Family>;
  categoryData: Array<Category>;
  situtationData: Array<ItemSituation>;

  tableData: Array<TableItemData>;
  tableOptions: TableOptions;
  tableEmitter: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('formModal', {static: true}) formModal: TemplateRef<any>;
  formModalRef: MatDialogRef<TaskComponent>;

  constructor(
    private i18n: I18n,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private categoryService: CategoryService,
    private familyService: FamilyService,
    private personService: PersonService,
    private taskTypeService: TaskTypeService,
    private formService: FormService,
    private companyService: CompanyService,
    private siteService: SiteService,
    private buildingService: BuildingService,
    private situationService: ItemSituationService,
    private authService: AuthService,
    private taskService: TaskService
  ) {
  }

  ngOnInit() {
    const taskID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);
    if (isNaN(taskID)) {
      this.task = new Task();
      this.task.status = 0;

      this._initForm();
    } else {
      this._getTask(taskID);
    }
  }

  private _initForm() {
    this.entityForm = this.fb.group({});
    this.scopeForm = this.fb.group({
      company: [null, [Validators.required]],
      family: [null, [Validators.required]],
      situation: [null, [Validators.required]]
    });

    if (!this.task.id || !this.task.generated) {
      this.isEditable = true;

      this.entityForm.addControl('definition', this.fb.control(null));
      this.entityForm.addControl('description', this.fb.control(null));
      this.entityForm.addControl('deadlineDate', this.fb.control(null));
      this.entityForm.addControl('comment', this.fb.control(null));
      this.entityForm.addControl('emergency', this.fb.control(2));

      if (!this.task.id) {
        this.entityForm.addControl('taskType', this.fb.control(null, [Validators.required]));
        this.entityForm.addControl('form', this.fb.control(null));
        const personID = this.authService.getUser().personID;
        this.entityForm.addControl('person', this.fb.control(personID ? personID : null));

        this.taskTypeOptions = {get: () => this.taskTypeService.findAllStd(), propName: 'label'};
        this.formOptions = {get: () => this.formService.findAll(), propName: 'name'};
      } else if (!this.task.generated) {
        this.entityForm.addControl('person', this.fb.control(null));
      }

      if (this.entityForm.contains('person')) {
        this.personOptions = {get: () => this.personService.findAll(), propName: 'fullName'};
      }

      this.emergencyOptions = {get: () => of(TaskHelper.getOptions()), propName: 'label'};
    } else {
      this.isEditable = false;
    }

    if (this.task.id && (this.task.status === 0 || this.task.status === 1)) {
      this.entityForm.addControl('status', this.fb.control(this.task.status));
      this.statusOptions = {get: () => of(TaskHelper.getTaskStatusOptions()), propName: 'label', autoSelect: true};
    }

    this.companyService.findAll('', {light: true}).subscribe((result: Array<Item>) => {
      this.companyData = result;

      this.companyOptions = {get: () => of(this.companyData), propName: 'label'};
      this.cd.detectChanges();
    });

    this.familyService.findAll('', {light: true, 'no-location': true}).subscribe((result: Array<Family>) => {
      this.familyData = result;

      this.familyOptions = {get: () => of(this.familyData), propName: 'label'};
      this.cd.detectChanges();
    });

    this.situationService.findAll().subscribe((result: Array<ItemSituation>) => {
      this.situtationData = result;

      this.situationOptions = {get: () => of(this.situtationData), propName: 'label'};
      this.cd.detectChanges();

      this.scopeForm.get('situation').setValue(this.situtationData.filter((item: ItemSituation) => item.value === 1));
      this.cd.detectChanges();
    });

    if (this.task.id === null) {
      this.tableData = [];
    } else {
      this.tableData = [];
    }

    this.tableOptions = {
      columnDefs: [
        {
          name: this.i18n({value: 'Où', id: 'where'}),
          prop: 'whereLabel', width: '35%',
        },
        {
          name: this.i18n({value: 'Quoi', id: 'what'}),
          prop: 'whatLabel', width: '35%',
        },
        {
          name: this.i18n({value: 'Situation', id: 'situation'}),
          prop: 'situationLabel', width: '10%',
        },
        {
          name: this.i18n({value: 'Tot.', id: 'total'}),
          prop: 'count', width: '10%',
        },
      ],
      findDataCb: () => of(this.tableData),
      actions: {
        columnWidth: '10%',
        canDelete: (entity: TableItemData) => !entity.id || entity.id < 0,
        deleteCb: (entity: TableItemData) => {
          this.tableData = this.tableData.splice(this.tableData.indexOf(entity), 1);
          this.tableEmitter.emit();
          return of();
        }
      }
    };
  }

  private _getTask(taskID: number) {
    this.taskService.find(taskID).subscribe((task: Task) => {
      this.task = task;

      this._initForm();

      FormHelper.initValues(this.task, this.entityForm);

      if (this.task.taskScopes && this.task.taskScopes.length > 0) {
        for (let scope of this.task.taskScopes) {
          this.addExistingData(scope);
        }
        this.tableEmitter.emit();
      }

      this.cd.detectChanges();
    });
  }

  updateDefinition() {
    if (this.task.definition || this.entityForm.get('definition').value) {
      this.entityForm.removeControl('deadlineDate');
    } else {
      this.entityForm.addControl('deadlineDate', this.fb.control(null));
    }
    this.cd.detectChanges();
  }

  confirmTask() {
    this.closeTaskModal();
    this._submitTask();
  }

  closeTaskModal() {
    this.formModalRef.close();
    this.formModalRef = null;
  }

  submit() {
    const status = this.entityForm.get('status');

    if (this.task.id && status !== null && status.value > 1 && this.task.nbActions > 0) {
      this.formModalRef = this.dialog.open(this.formModal);
    } else {
      this._submitTask();
    }
  }

  _submitTask() {
    let types = {};

    types['deadlineDate'] = {type: FieldType.DATE};

    const entity = FormHelper.buildEntity(this.task, this.entityForm, types) as Task;

    entity['data'] = this.tableData;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      this.task.id ? this.taskService.put(entity) : this.taskService.post(entity),
      (result: Action) => {
        this.routeNameService.goTo('task_view', {id: result.id});
      }
    );
  }

  updateSite() {
    if (this.scopeForm.contains('site')) {
      this.scopeForm.removeControl('site');
    }
    const companyID = parseInt(this.scopeForm.get('company').value, 10);

    if (isNaN(companyID)) {
      this.updateBuilding();
      return;
    }

    this.companyService.getAllSites(companyID, {light: true}).subscribe((result: Array<Item>) => {
      this.siteData = result;

      this.scopeForm.addControl('site', this.fb.control(null));
      this.siteOptions = {get: () => of(this.siteData), propName: 'label'};

      this.cd.detectChanges();
    });
  }

  updateBuilding() {
    if (this.scopeForm.contains('building')) {
      this.scopeForm.removeControl('building');
    }

    const siteID = parseInt(this.scopeForm.get('site').value, 10);

    if (isNaN(siteID)) {
      this.updateLocal();
      return;
    }

    this.siteService.getAllBuildings(siteID, {light: true}).subscribe((result: Array<Item>) => {
      this.buildingData = result;

      this.scopeForm.addControl('building', this.fb.control(null));
      this.buildingOptions = {get: () => of(this.buildingData), propName: 'label'};

      this.cd.detectChanges();
    });
  }

  updateLocal() {
    if (this.scopeForm.contains('local')) {
      this.scopeForm.removeControl('local');
    }

    const buildingID = parseInt(this.scopeForm.get('building').value, 10);

    if (isNaN(buildingID)) {
      this.cd.detectChanges();
      return;
    }

    this.buildingService.getAllLocals(buildingID, {light: true}).subscribe((result: Array<Item>) => {
      this.localData = result;

      this.scopeForm.addControl('local', this.fb.control(null));
      this.localOptions = {get: () => of(this.localData), propName: 'label'};

      this.cd.detectChanges();
    });
  }

  updateCategory() {
    if (this.scopeForm.contains('category')) {
      this.scopeForm.removeControl('category');
    }

    const familyID = parseInt(this.scopeForm.get('family').value, 10);

    if (isNaN(familyID)) {
      this.cd.detectChanges();
      return;
    }

    this.categoryService.findAllByParent(familyID, {light: true}).subscribe((result: Array<Category>) => {
      this.categoryData = result;

      this.scopeForm.addControl('category', this.fb.control(null));
      this.categoryOptions = {get: () => of(this.categoryData), propName: 'label'};

      this.cd.detectChanges();
    });
  }

  addExistingData(item: TaskScope) {
    let data = new TableItemData();

    data.id = item.id;

    if (item.company) {
      data.company = item.company.id;
      data.companyLabel = item.company.label;
    }

    if (item.site) {
      data.site = item.site.id;
      data.siteLabel = item.site.label;
    }

    if (item.building) {
      data.building = item.building.id;
      data.buildingLabel = item.building.label;
    }

    if (item.local) {
      data.local = item.local.id;
      data.localLabel = item.local.label;
    }

    if (item.family) {
      data.family = item.family.id;
      data.familyLabel = item.family.label;
    }

    if (item.category) {
      data.category = item.category.id;
      data.categoryLabel = item.category.label;
    }

    data.situationLabel = item.status;
    data.status = item.status;

    data.whatLabel = item.fullWhat;
    data.whereLabel = item.fullWhere;

    data.count = item.count;

    this.tableData.push(data);
  }

  addData() {
    let data = new TableItemData();

    data.id = this.latestTmpId;
    this.latestTmpId--;

    let whereParts = [];
    let whatParts = [];

    if (this.scopeForm.get('company') && this.scopeForm.get('company').value) {
      data.company = this.scopeForm.get('company').value;
      data.companyLabel = this.companyData.filter((item: Item) => item.id === data.company)[0].label;
      whereParts.push(data.companyLabel);
    }
    if (this.scopeForm.get('site') && this.scopeForm.get('site').value) {
      data.site = this.scopeForm.get('site').value;
      data.siteLabel = this.siteData.filter((item: Item) => item.id === data.site)[0].label;
      whereParts.push(data.siteLabel);
    }
    if (this.scopeForm.get('building') && this.scopeForm.get('building').value) {
      data.building = this.scopeForm.get('building').value;
      data.buildingLabel = this.buildingData.filter((item: Item) => item.id === data.building)[0].label;
      whereParts.push(data.buildingLabel);
    }
    if (this.scopeForm.get('local') && this.scopeForm.get('local').value) {
      data.local = this.scopeForm.get('local').value;
      data.localLabel = this.localData.filter((item: Item) => item.id === data.local)[0].label;
      whereParts.push(data.localLabel);
    }

    if (this.scopeForm.get('family') && this.scopeForm.get('family').value) {
      data.family = this.scopeForm.get('family').value;
      data.familyLabel = this.familyData.filter((item: Family) => item.id === data.family)[0].label;
      whatParts.push(data.familyLabel);
    }
    if (this.scopeForm.get('category') && this.scopeForm.get('category').value) {
      data.category = this.scopeForm.get('category').value;
      data.categoryLabel = this.categoryData.filter((item: Category) => item.id === data.category)[0].label;
      whatParts.push(data.categoryLabel);
    }

    if (this.scopeForm.get('situation') && this.scopeForm.get('situation').value) {
      data.situations = this.scopeForm.get('situation').value;
      if (!data.situations.length) {
        return false;
      }
      data.situationLabel = data.situations.map((item: Entity) => item.id).join(',');
      data.status = data.situationLabel;
    } else {
      return false;
    }

    data.whereLabel = whereParts.join('/');
    data.whatLabel = whatParts.join('/');

    this.taskService.countScope(data).subscribe((count: number) => {
      data.count = count;

      this.tableData.push(data);

      this.cd.detectChanges();

      this.tableEmitter.emit();
    });
  }

  getEmergencyLabel() {
    return TaskHelper.getLabel(this.task.emergency);
  }

  getStatusLabel() {
    return TaskHelper.getTaskStatusLabel(this.task.status);
  }
}

class TableItemData extends Entity {
  id: number;

  company: number;
  companyLabel: string;
  site: number;
  siteLabel: string;
  building: number;
  buildingLabel: string;
  local: number;
  localLabel: string;

  family: number;
  familyLabel: string;
  category: number;
  categoryLabel: string;

  situations: Array<Entity>;
  count: number;
  status: string;

  whereLabel: string;
  whatLabel: string;
  situationLabel: string;
}
