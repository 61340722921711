<div class="table-header" [fxLayoutAlign]="flexAlignHeaderFooter">
  <div
    *ngIf="(options.actions && options.actions.create && options.actions.create.length)|| this.options.headTemplate"
    class="btn-create-wrapper">
    <div
      *ngIf="options.actions && options.actions.create && options.actions.create.length && (options.actions.canCreate === true || options.actions.canCreate === undefined)">
      <ng-container *ngFor="let button of options.actions.create">
        <button color="success" mat-flat-button (click)="button.createCb()">
          <mat-icon>add</mat-icon>
          {{button.btnName}}
        </button>
      </ng-container>
    </div>
    <div *ngIf="this.options.headTemplate" style="margin-left: 5px;" fxLayoutAlign="center center"
         fxLayoutGap="5px">
      <ng-container *ngTemplateOutlet="this.options.headTemplate"></ng-container>
    </div>
  </div>
  <div class="table-search">
    <mat-form-field *ngIf="options.columnToDisplayEnabled" appearance="outline" floatLabel="always"
                    class="mr-1">
      <mat-label i18n="@@columns">Colonnes</mat-label>
      <mat-select [formControl]="columnsControl" multiple>
        <mat-option *ngFor="let column of columnList"
                    [value]="column.value">{{column.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="options.searchEnabled" appearance="outline" floatLabel="always">
      <mat-label i18n="@@search">Rechercher</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>
</div>
<div class="table-responsive">
  <table mat-table class="full-width-table" [dataSource]="dataSource" matSort
         [matSortActive]="options.defaultSortActive"
         [matSortDirection]="options.defaultSortDirection"
         aria-label="Elements">

    <!-- === COLUMN ACTIONS === -->
    <ng-container matColumnDef="type:actions">
      <th mat-header-cell *matHeaderCellDef [ngStyle]="{'width': options.actions.columnWidth}">
        <div class="header-name"></div>
      </th>
      <td mat-cell *matCellDef="let row">
        <div class="table-cell-actions" *ngIf="isDeletingDataId !== row.id">
          <!-- CUSTOM ACTIONS -->
          <ng-container *ngFor="let custom of options.actions.custom">
            <ng-container *ngIf="!custom.visibleCb || custom.visibleCb(row)">
              <button *ngIf="!custom.label" mat-icon-button (click)="custom.customCb(row)"
                      color="primary">
                <mat-icon>{{custom.icon}}</mat-icon>
              </button>
              <button *ngIf="custom.label" mat-flat-button (click)="custom.customCb(row)"
                      color="primary">
                <mat-icon *ngIf="custom.icon">{{custom.icon}}</mat-icon>
                <ng-container *ngIf="custom.label">{{custom.label}}</ng-container>
              </button>
            </ng-container>
          </ng-container>
          <!-- BASIC ACTIONS -->
          <button mat-icon-button *ngIf="options.actions.readCb"
                  (click)="options.actions.readCb(row)" color="primary">
            <mat-icon>visibility</mat-icon>
          </button>
          <button mat-icon-button *ngIf="options.actions.updateCb"
                  (click)="options.actions.updateCb(row)"
                  color="primary">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button
                  *ngIf="options.actions.deleteCb && (options.actions.canDelete === null || options.actions.canDelete(row))"
                  (click)="deleteEntity(row)" color="primary">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <div *ngIf="isDeletingDataId === row.id" class="table-cell-actions">
          <p i18n="@@confirmDeleting" class="center">Supprimer ?</p>
          <div class="table-cell-confirm-delete">
            <button mat-icon-button (click)="deleteEntity(row, true)" class="btn-delete">
              <mat-icon>check</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteEntity(row, false)" class="btn-cancel">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- === COLUMNS === -->
    <ng-container *ngFor="let columnDef of options.columnDefs" [matColumnDef]="columnDef.prop">
      <th mat-header-cell *matHeaderCellDef mat-sort-header
          [ngStyle]="{'width': (columnDef.width) ? columnDef.width : 'auto' }">
        <div class="header-name" [matTooltip]="columnDef.name" matTooltipPosition="above"
             matTooltipShowDelay="600"
             matTooltipClass="header-tooltip">
          {{columnDef.name}}</div>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container
          *ngTemplateOutlet="columnDefTemplate; context: {$implicit: columnDef, data: row}"></ng-container>
      </td>
      <!-- DISPLAY FOOTER -->
      <td mat-footer-cell *matFooterCellDef>
        <ng-container *ngIf="columnDef.footerDef">
          <ng-container
            *ngTemplateOutlet="columnDefTemplate; context: {$implicit: columnDef.footerDef, data: dataSource.data}">
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        [class.update-entity]="options.actions && options.actions.updateCb"
        [class.delete-selected-data]="isDeletingDataId === row.id"
        (dblclick)="editEntity(row)"></tr>
    <ng-container *ngIf="options.footerVisible">
      <tr [class.hidden]="dataSource.data.length === 0" mat-footer-row
          *matFooterRowDef="displayedColumns"></tr>
    </ng-container>
  </table>
  <div *ngIf="dataSource.data.length === 0 && !isLoadingData" class="empty-data">
    <p i18n="@@emptyDataTable">Aucune donnée disponible</p>
  </div>
  <div *ngIf="isLoadingData">
    <div class="spinner-wrapper">
      <mat-spinner color="primary" mode="indeterminate" diameter="36"></mat-spinner>
    </div>
  </div>
</div>
<div class="table-footer" [fxLayoutAlign]="flexAlignHeaderFooter">
  <div
    *ngIf="options.searchFormID && options.actions && options.actions.create && options.actions.create.length"
    class="btn-create-wrapper">
    <!--    <ng-container *ngFor="let button of options.actions.create">-->
    <!--      <button mat-flat-button (click)="button.createCb()">-->
    <!--        <mat-icon>add</mat-icon> {{button.btnName}}-->
    <!--      </button>-->
    <!--    </ng-container>-->
  </div>
  <div [class.hidden]="dataSource.data.length <= 20" class="table-paginator">
    <mat-paginator #paginator [length]="dataSource.data.length" [pageIndex]="0" [pageSize]="20"
                   [pageSizeOptions]="[20, 50, 100]" [showFirstLastButtons]="true">
    </mat-paginator>
  </div>
</div>

<ng-template #columnDefTemplate let-columnDef let-data="data">
  <ng-container [ngSwitch]="columnDef.type">
    <!-- CHECKBOX -->
    <ng-container *ngSwitchCase="ColumnType.CHECKBOX">
      <mat-checkbox [checked]="getValue(data, columnDef)" [disabled]="true"></mat-checkbox>
    </ng-container>
    <!-- DATE -->
    <ng-container *ngSwitchCase="ColumnType.DATE">
      {{getValue(data, columnDef)| date}}
    </ng-container>
    <!-- TEMPLATE -->
    <ng-container *ngSwitchCase="ColumnType.TEMPLATE">
      <ng-container
        *ngTemplateOutlet="columnDef['template'];context: {$implicit: data}"></ng-container>
    </ng-container>
    <!-- VALUE -->
    <ng-container *ngSwitchCase="ColumnType.VALUE">
      {{columnDef.value}}
    </ng-container>
    <!-- PICTURE -->
    <ng-container *ngSwitchCase="ColumnType.PICTURE">
      <esomus-img-cell [picture]="getValue(data, columnDef)"></esomus-img-cell>
    </ng-container>
    <ng-container *ngSwitchCase="ColumnType.ICON">
      <esomus-img-cell [isIcon]="true" [picture]="getValue(data, columnDef)"></esomus-img-cell>
    </ng-container>
    <!-- PROPERTY -->
    <ng-container *ngSwitchDefault>
      {{getValue(data, columnDef)}}
    </ng-container>
  </ng-container>
</ng-template>
