<ng-template #modal>
  <h3 mat-dialog-title>
    <span>Adresse : {{ address.id ? 'Edition' : 'Création' }}</span>
  </h3>
  <form *ngIf="entityForm">
    <div mat-dialog-content style="min-width: 640px;">
      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px grid">
        <esomus-ng-select *ngIf="entityForm.get('type')" [options]="typeOptions"
                          [control]="entityForm.get('type')" i18n-label="@@type"
                          label="Type" [fxFlex]="100" flex="100"></esomus-ng-select>
        <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('description')"
                      i18n-label="@@description" label="Description" flex="100"
                      fxFlex="100"></esomus-input>
        <esomus-input [autofocus]="true" [type]="fieldType.TEXT" [control]="entityForm.get('line1')"
                      i18n-label="@@line1" label="Adresse" flex="100"
                      fxFlex="100"></esomus-input>
        <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('locality')"
                      i18n-label="@@locality" label="Ville" flex="100"
                      fxFlex="50"></esomus-input>
        <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('zipCode')"
                      i18n-label="@@zipCode" label="Code postal" flex="100"
                      fxFlex="50"></esomus-input>
        <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('country')"
                      i18n-label="@@country" label="Pays" flex="100"
                      fxFlex="50"></esomus-input>
        <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('coordinates')"
                      i18n-label="@@coordinates" label="Coordonées" flex="100"
                      fxFlex="50"></esomus-input>
        <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('phone')"
                      i18n-label="@@phone" label="Téléphone" flex="100"
                      fxFlex="50"></esomus-input>
        <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('mail')"
                      i18n-label="@@mail" label="Mail" flex="100"
                      fxFlex="50"></esomus-input>
      </div>
    </div>
    <div mat-dialog-actions>
      <div class="btn-group-left">
        <esomus-delete-button
          *ngIf="address && address.id && address.active"
          [url]="getAddressDeleteURL()"></esomus-delete-button>
        <esomus-enable-button
          *ngIf="address && address.id && !address.active"
          [url]="getAddressEnableURL()"></esomus-enable-button>
      </div>
      <button type="button" mat-stroked-button color="success"
              i18n="@@saveAddress" (click)="submit()">Sauver l'adresse
      </button>
      <button type="button" mat-stroked-button i18n="@@cancel" (click)="closeModal()">Annuler
      </button>
    </div>
  </form>
</ng-template>
