<mat-expansion-panel expanded="true" *ngIf="categories">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Catégories: {{categories.length}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="container">
    <div fxLayout="row wrap" fxLayoutAlign="start center">
      <div *ngIf="showBtns" fxFlex="100" class="mb-10 ml-10">
        <button mat-raised-button (click)="createCategory()" color="success">
          <mat-icon>add</mat-icon>
          Nouvelle catégorie
        </button>
      </div>
      <esomus-image-box fxFlex="25%" *ngFor="let category of categories"
                        [url]="routeName.path('category_view', {familyID: family.id, id: category.id})"
                        [imageSource]="category.picture" [text]="category.label"
                        [comment]="category.nbItemDescriptions + ' fiche(s)'"></esomus-image-box>
    </div>
  </div>
</mat-expansion-panel>
