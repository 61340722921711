import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ItemTypeService } from '../../../core/api/item-type.service';
import { CategoryService } from '../../../core/api/category.service';
import { FamilyService } from '../../../core/api/family.service';
import { ToastrService } from '../../../core/services/toastr.service';
import { EquipmentService } from '../../../core/api/equipment.service';
import { AnomalyService } from '../../../core/api/anomaly.service';
import { AnomalyTypeService } from '../../../core/api/anomaly-type.service';
import { AnomalyDataService } from '../../../core/api/anomaly-data.service';
import { PersonService } from '../../../core/api/person.service';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { ItemType } from '../../../core/models/item-type.model';
import { Anomaly } from '../../../core/models/anomaly.model';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { AnomalyType } from '../../../core/models/anomaly-type.model';
import { FormsMap, FormsMappingMap } from '../../../forms/components/form-component/form-component.component';
import { of } from 'rxjs';
import { Action } from '../../../core/models/action.model';
import { FormService } from '../../../core/api/form.service';
import { FormHelper } from '../../../core/services/form-helper.service';
import { ActionService } from '../../../core/api/action.service';
import { TaskTypeService } from '../../../core/api/task-type.service';
import { Item } from '../../../core/models/item.model';
import { TaskHelper } from '../../../core/services/task-helper.service';
import { AccidentService } from '../../../core/api/accident.service';
import { Accident } from '../../../core/models/accident.model';
import { AuthService } from '../../../core/api/auth.service';

@Component({
  selector: 'esomus-action-add',
  templateUrl: './action-add.component.html',
  styleUrls: ['./action-add.component.sass']
})
export class ActionAddComponent implements OnInit {
  entityForm: FormGroup;
  fieldType = FieldType;
  itemType: ItemType;

  action: Action;
  anomaly: Anomaly;
  equipment: Item;
  accident: Accident;
  anomalyTypeOptions: EntityOption;
  anomalyTypes: Array<AnomalyType>;

  forms: FormsMap;
  formsMapping: FormsMappingMap;
  formsValidation: boolean[];
  nbForms: number;

  emergencyOptions: EntityOption;
  personOptions: EntityOption;

  formOptions: EntityOption;
  taskTypeOptions: EntityOption;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private itemTypeService: ItemTypeService,
    private categoryService: CategoryService,
    private familyService: FamilyService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private equipmentService: EquipmentService,
    private anomalyService: AnomalyService,
    private anomalyTypeService: AnomalyTypeService,
    private anomalyDataService: AnomalyDataService,
    private personService: PersonService,
    private formService: FormService,
    private actionService: ActionService,
    private accidentService: AccidentService,
    private taskTypeService: TaskTypeService,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      form: [null],
      description: [null],
      comment: [null],
      emergency: [2],
      person: [this.authService.getPersonID()],
      deadlineDate: [null],
      plannedDate: [null],
      taskType: [null],
    });

    this.emergencyOptions = {
      get: () => of(TaskHelper.getOptions()), propName: 'label'
    };
    this.personOptions = { get: () => this.personService.findAll('', { origin: 'actions' }), propName: 'fullName' };
    this.formOptions = { get: () => this.formService.findAll(), propName: 'name' };

    const anomalyID = parseInt(this.activatedRoute.snapshot.paramMap.get('anomalyID'), 10);
    const equipmentID = parseInt(this.activatedRoute.snapshot.paramMap.get('equipmentID'), 10);
    const accidentID = parseInt(this.activatedRoute.snapshot.paramMap.get('accidentID'), 10);

    if (!isNaN(anomalyID)) {
      this._getAnomaly(anomalyID);
    } else if (!isNaN(equipmentID)) {
      this._getEquipment(equipmentID);
    } else if (!isNaN(accidentID)) {
      this._getAccident(accidentID);
    }
  }

  private _getAnomaly(anomalyID: number) {
    this.anomalyService.find(anomalyID).subscribe((result: Anomaly) => {
      this.anomaly = result;

      this.taskTypeOptions = { get: () => this.taskTypeService.findAllAnomaly(), propName: 'label' };

      this.action = new Action();
      this.action.linkType = 1;
      this.action.anomaly = this.anomaly;
      this.action.deadlineDate = this.anomaly.deadlineDate;

      this.entityForm.addControl('anomaly', this.fb.control(this.anomaly.id));
      this.cd.detectChanges();
    });
  }

  private _getEquipment(equipmentID: number) {
    this.equipmentService.find(equipmentID).subscribe((result: Item) => {
      this.equipment = result;

      this.taskTypeOptions = { get: () => this.taskTypeService.findAllStd(), propName: 'label' };

      this.action = new Action();
      this.action.linkType = 0;
      this.action.item = this.equipment;

      this.entityForm.addControl('item', this.fb.control(this.equipment.id));
      this.cd.detectChanges();
    });
  }

  private _getAccident(accidentID: number) {
    this.accidentService.find(accidentID).subscribe((result: Accident) => {
      this.accident = result;

      this.taskTypeOptions = { get: () => this.taskTypeService.findAllAnomaly(), propName: 'label' };

      this.action = new Action();
      this.action.linkType = 2;
      this.action.accident = this.accident;

      this.entityForm.addControl('accident', this.fb.control(this.accident.id));
      this.cd.detectChanges();
    });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    const entity = FormHelper.buildEntity(this.action, this.entityForm, {
      deadlineDate: { type: FieldType.DATE }
    }) as Action;
    let submitUrl = null;
    if (this.anomaly) {
      submitUrl = this.actionService.postFromAnomaly(entity);
    } else if (this.equipment) {
      submitUrl = this.actionService.postFromEquipment(entity);
    } else if (this.accident) {
      submitUrl = this.actionService.postFromAccident(entity);
    }

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      submitUrl,
      (result: Action) => {
        this.routeNameService.goTo('action_view', { id: result.id });
        // if (this.anomaly) {
        //   this.routeNameService.goTo('anomaly_view', {id: this.anomaly.id});
        // } else if (this.equipment) {
        //   this.routeNameService.goTo('equipment_view', {id: this.equipment.id});
        // }
      }
    );
  }

  getOrigin() {
    return TaskHelper.getOrigin(this.action);
  }
}
