export enum RightEnum {
  // UserRights From token
  FAMILIES = 'families',
  PERSONS = 'persons',
  TASKS = 'tasks',
  PROGRAMMING = 'programming',
  ACCIDENTS = 'accidents',
  ANOMALIES = 'anomalies',
  LISTS = 'lists',
  FORMS = 'forms',
  DOCUMENT_TYPES = 'documenttypes',
  DOCUMENT_MANAGEMENT = 'documentmanagement',
  USERS = 'users',
  ANOMALY_TYPES = 'anomalytypes',
  TASK_TYPES = 'tasktypes',
  OBLIGATION_TYPES = 'obligationtypes',
  OBLIGATIONS = 'obligations',
  ROLES = 'roles',
  ACCIDENT_TYPES = 'accidenttypes',
  IMPACTS = 'impacts',
  ZONES = 'zones',
  EFFECTS = 'effects',
  PARAMETERS = 'parameters',

  FAMILY_ADMINISTER = 'familyadminister',
  FAMILY_CONFIGURE = 'familyconfigure',
  EQUIPMENT_ADMINISTER = 'equipmentadminister',
  TASK_ADMINISTER = 'taskadminister',

  // Both Right from URL & TOKEN
  COMPANY_ADMINISTER = 'companyadminister',

  // UserRights from URL
  FAMILY_MANAGER = 'familymanage',
  LOCATION_MANAGE = 'locationmanage',
  LOCATION_CONFIGURE = 'locationconfigure',
  LOCATION_IMPLEMENT = 'locationimplement',
  SITE_MANAGE_LIST = 'sitemanagelist',
  VEHICULE_MANAGE = 'vehiclemanage',
  ACTION_PERFORM = 'actionperform',

  EQUIPMENT_ACCESS = 'equipmentaccess',
  EQUIPMENT_MANAGE = 'equipmentmanage',
  EQUIPMENT_IMPLEMENT = 'equipmentimplement',
  EQUIPMENT_CONFIGURE = 'equipmentconfigure',
  EQUIPMENT_IMPLEMENT_FAMILY = 'equipmentimplementfamily',
}

export const TOKEN_RIGHTS: RightEnum[] = [
  RightEnum.ANOMALIES,
  RightEnum.FAMILIES,
  RightEnum.PERSONS,
  RightEnum.TASKS,
  RightEnum.PROGRAMMING,
  RightEnum.ACCIDENTS,
  RightEnum.LISTS,
  RightEnum.FORMS,
  RightEnum.DOCUMENT_TYPES,
  RightEnum.DOCUMENT_MANAGEMENT,
  RightEnum.USERS,
  RightEnum.ANOMALY_TYPES,
  RightEnum.TASK_TYPES,
  RightEnum.OBLIGATION_TYPES,
  RightEnum.OBLIGATIONS,
  RightEnum.ROLES,
  RightEnum.ACCIDENT_TYPES,
  RightEnum.IMPACTS,
  RightEnum.ZONES,
  RightEnum.EFFECTS,
  RightEnum.PARAMETERS,
  RightEnum.FAMILY_ADMINISTER,
  RightEnum.FAMILY_CONFIGURE,
  RightEnum.EQUIPMENT_ADMINISTER,
  RightEnum.TASK_ADMINISTER,
  RightEnum.COMPANY_ADMINISTER,
];

export const FULL_PARAMETERS_RIGHTS: RightEnum[] = [
  RightEnum.LISTS,
  RightEnum.FORMS,
  RightEnum.DOCUMENT_TYPES,
  RightEnum.DOCUMENT_MANAGEMENT,
  RightEnum.USERS,
  RightEnum.ANOMALY_TYPES,
  RightEnum.TASK_TYPES,
  RightEnum.OBLIGATION_TYPES,
  RightEnum.OBLIGATIONS,
  RightEnum.ROLES,
  RightEnum.ACCIDENT_TYPES,
  RightEnum.IMPACTS,
  RightEnum.ZONES,
  RightEnum.EFFECTS,
  RightEnum.PARAMETERS,
];
