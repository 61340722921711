<mat-expansion-panel *ngIf="contactsTable">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Contact : {{nbContacts}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <esomus-table *ngIf="contactsTable" (getLength)="updateNbContacts($event)"
                [options]="contactsTable" [searchReceiver]="contactTableEmitter"></esomus-table>
  <esomus-contact-link *ngIf="showContactModal" [contactTableEmitter]="contactTableEmitter"
                       [parent]="parent" [parentType]="parentType"></esomus-contact-link>
</mat-expansion-panel>
