import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Item } from '../../../core/models/item.model';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ItemDescriptionService } from '../../../core/api/item-description.service';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { FormHelper } from '../../../core/services/form-helper.service';
import { EquipmentService } from '../../../core/api/equipment.service';

@Component({
  selector: 'esomus-equipment-replace-modal',
  templateUrl: './equipment-replace-modal.component.html',
  styleUrls: ['./equipment-replace-modal.component.sass']
})
export class EquipmentReplaceModalComponent implements OnInit {
  @ViewChild('formModal', {static: true}) formModal: TemplateRef<any>;
  formModalRef: MatDialogRef<EquipmentReplaceModalComponent>;

  itemDescriptionOptions: EntityOption;
  fieldType = FieldType;
  entityForm: FormGroup;

  @Input() currentItem: Item;

  constructor(
    private i18n: I18n,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private cd: ChangeDetectorRef,
    private itemDescriptionService: ItemDescriptionService,
    private equipmentService: EquipmentService
  ) {
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      description: [],
      itemDescription: [null, [Validators.required]],
    });

    this.itemDescriptionOptions = {
      get: () => this.itemDescriptionService.findByCategory(this.currentItem.category.id),
      propName: 'label',
      autoSelect: true
    };

    this.formModalRef = this.dialog.open(this.formModal);
  }

  confirm() {
    if (this.entityForm.invalid) {
      return;
    }

    const entity = FormHelper.buildEntity(new Item(), this.entityForm);

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      this.equipmentService.replace(this.currentItem.id, entity),
      (result: Item) => {
        this.formModalRef.close();
        this.formModal = null;

        this.routeNameService.goTo('equipment_view', {id: result.id});
      }
    );
  }

  closeModal() {
    this.formModalRef.close();
    this.formModal = null;
  }
}
