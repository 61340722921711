import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObligationComponent } from './pages/obligation/obligation.component';
import { ObligationViewComponent } from './pages/obligation-view/obligation-view.component';
import { ObligationListComponent } from './pages/obligation-list/obligation-list.component';
import { SharedModule } from '../shared/shared.module';
import { ObligationItemDescriptionPanelComponent } from './components/obligation-item-description-panel/obligation-item-description-panel.component';
import { DashboardModule } from '../dashboard/dashboard.module';

@NgModule({
  declarations: [ObligationComponent, ObligationViewComponent, ObligationListComponent, ObligationItemDescriptionPanelComponent],
  exports: [
    ObligationItemDescriptionPanelComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DashboardModule
  ]
})
export class ObligationsModule { }
