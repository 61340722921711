import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/api/auth.service';
import { UserLogged } from 'src/app/core/models/user-logged.model';

@Component({
  selector: 'esomus-toolbar-user-button',
  templateUrl: './toolbar-user-button.component.html',
  styleUrls: ['./toolbar-user-button.component.scss']
})
export class ToolbarUserButtonComponent implements OnInit, AfterViewInit {

  isOpen: boolean;

  user: UserLogged;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.user = this.authService.getUser();
  }

  ngAfterViewInit() {
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  logout() {
    this.authService.logout(true);
  }
}
