import { Entity } from '../models/entity.model';
import { FormGroup } from '@angular/forms';
import { FileInput } from 'ngx-material-file-input';
import { FieldType } from '../../shared/form-builder/form-builder.component';
import { Observable } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';

export class FormHelper {

  constructor() {
  }

  static initValues(entity: Entity, form: FormGroup) {
    let controls = form.controls;
    for (let field in controls) {
      let data: Entity | number = entity;
      const properties = field.toString().split('.');
      let i = 0;

      while (i < properties.length && data !== null) {
        const property = properties[i];
        if (data[property] !== undefined) {
          data = data[property];
        }
        i++;
      }

      if (data !== null && typeof data === 'object' && data.hasOwnProperty('id')) {
        data = data.id;
      }

      controls[field].setValue(data);
    }
  }

  static submitForm(cd: ChangeDetectorRef, form: FormGroup, url: Observable<any>, successCb: (entity?: Entity) => void) {
    form.markAsTouched();
    url.subscribe(
      (result: Entity) => successCb(result),
      (errors: any) => FormHelper.setErrors(cd, errors, form)
    );
  }

  static setErrors(cd, event, form: FormGroup) {
    if (event.errors && event.errors.children) {
      const errors = event.errors.children;
      for (const property in errors) {
        if (errors.hasOwnProperty(property)) {
          const error = errors[property];
          if (error.hasOwnProperty('errors') && Array.isArray(error.errors)) {
            if (form.contains(property)) {
              form.controls[property].markAsTouched();
              form.controls[property].setErrors({
                custom: error.errors[0]
              });
            }
          }
        }
      }
      cd.detectChanges();
    }
  }

  static buildEntity(baseEntity: Entity, form: FormGroup, types?: any): Entity {
    if (!types) {
      types = {};
    }
    let controls = form.controls;
    let entity = new Entity();

    if (baseEntity.id !== undefined && baseEntity.id !== null) {
      entity.id = baseEntity.id;
    }

    for (let field in controls) {
      const value = controls[field].value;
      let data = entity;

      if (value !== null && value !== '' && value !== -1) {
        const properties = field.split('.');

        for (let i = 0; i < properties.length; i++) {
          const property = properties[i];

          if (types[field] !== undefined) {
            switch (types[field].type) {
              case FieldType.GROUP:
                data[field] = this.buildEntity(baseEntity[field], form.get(field) as FormGroup, types[field]);
                continue;
            }
          }

          if (data[property] === undefined && i + 1 < properties.length) {
            data[property] = {};
            data = data[property];
          } else if (typeof data[property] === 'object') {
            data = data[property];
          }

          if (i + 1 === properties.length) {
            if (types[field] !== undefined) {
              switch (types[field].type) {
                case FieldType.DATE:
                  data[property] = new Date(value);
                  break;
                case FieldType.FILE:
                  const fileInput: FileInput = value;
                  if (typeof fileInput === 'object') {
                    if (types[field].multiple) {
                      data[property] = fileInput.files;
                    } else if (fileInput.files.length) {
                      data[property] = fileInput.files[0];
                    }
                  }
                  break;
                case FieldType.SELECT:
                  if (types[field].multiple) {
                    data[property] = value.map((item: Entity) => item.id);
                  }
                  break;
                default:
                  data[property] = value;
              }
            } else {
              data[property] = value;
            }
          }
        }
      }
    }

    return entity;
  }

  static isInvalid(form: FormGroup): boolean {
    let isInvalid = false;

    for (let control in form.controls) {
      let item = form.get(control);
      if (item instanceof FormGroup) {
        if (FormHelper.isInvalid(item)) {
          isInvalid = true;
        }
      } else {
        if (item.invalid) {
          isInvalid = true;
        }
      }
    }

    return isInvalid;
  }
}
