import { Params } from '@angular/router';

export abstract class AbstractHelper {

  protected static _getLabel(type: any, options: Array<Params>) {
    let value = options.find((item: Params) => item.id === type);

    if (value) {
      return value.label;
    }

    return null;
  }

  protected static _getColor(type: any, options: Array<Params>) {
    let value = options.find((item: Params) => item.id === type);

    if (value) {
      return value.color;
    }

    return null;
  }
}
