import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Entity } from '../models/entity.model';
import { Params } from '@angular/router';
import { Contact } from '../models/contact.model';

const crudPath: CRUDPath = {
  many: `/contacts/`,
  single: (id: number) => `/contacts/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class ContactService extends CRUD<Contact> {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  unlink(dID: number, subUrl: string = ''): Observable<Contact> {
    return this.http.delete<Contact>(`${environment.api.url}${subUrl}${crudPath.single(dID)}/unlink`);
  }

  postParent(contact: Contact, parent: Entity, parentType: string): Observable<Contact> {
    switch (parentType) {
      case 'item':
        return this.postItem(contact, parent.id);
      case 'itemDescription':
        return this.postItemDescription(contact, parent.id);
    }
  }

  getParent(parentID: number, parentType: string, searchData: Params = null): Observable<Array<Contact>> {
    switch (parentType) {
      case 'item':
        return this.getItem(parentID, searchData);
      case 'itemDescription':
        return this.getItemDescription(parentID, searchData);
    }
  }

  unlinkParent(parentID: number, id: number, parentType: string) {
    switch (parentType) {
      case 'item':
        return this.unlinkItem(parentID, id);
      case 'itemDescription':
        return this.unlinkItemDescription(parentID, id);
    }
  }

  // region Item

  postItem(contact: Contact, itemID: number): Observable<Contact> {
    return this.post(contact, `/items/${itemID}`);
  }

  getItem(itemID: number, searchData: Params): Observable<Array<Contact>> {
    return this.findAll(`/items/${itemID}`, searchData);
  }

  unlinkItem(itemID: number, contactID: number) {
    return this.unlink(contactID, `/items/${itemID}`);
  }

  // endregion

  // region ItemDescription

  postItemDescription(contact: Contact, itemDescriptionID: number): Observable<Contact> {
    return this.post(contact, `/item_descriptions/${itemDescriptionID}`);
  }

  getItemDescription(itemDescriptionID: number, searchData: Params): Observable<Array<Contact>> {
    return this.findAll(`/item_descriptions/${itemDescriptionID}`, searchData);
  }

  unlinkItemDescription(itemDescriptionID: number, contactID: number) {
    return this.unlink(contactID, `/item_descriptions/${itemDescriptionID}`);
  }

  // endregion
}
