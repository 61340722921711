import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Item } from '../../../core/models/item.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { EquipmentService } from '../../../core/api/equipment.service';
import { ItemStatusService } from '../../../core/api/item-status.service';
import { ToastrService } from '../../../core/services/toastr.service';
import { FormHelper } from '../../../core/services/form-helper.service';

@Component({
  selector: 'esomus-equipment-conformity-modal',
  templateUrl: './equipment-conformity-modal.component.html',
  styleUrls: ['./equipment-conformity-modal.component.sass']
})
export class EquipmentConformityModalComponent implements OnInit {
  equipment: Item;
  entityForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: { equipment: Item},
    private dialogRef: MatDialogRef<EquipmentConformityModalComponent>,
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private equipmentService: EquipmentService,
    private itemStatusService: ItemStatusService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.equipment = this.data.equipment;

    this.entityForm = this.fb.group({
      conformity: [this.equipment.conformity]
    });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      this.equipmentService.putConformity(this.equipment.id, this.entityForm.value),
      (result: Item) => {
        this.dialogRef.close(result);
      }
    );
  }
}
