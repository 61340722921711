import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FieldType as FormFieldType } from '../../../shared/form-builder/form-builder.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrMessageType, ToastrService } from '../../../core/services/toastr.service';
import { PersonService } from '../../../core/api/person.service';
import { FormHelper } from '../../../core/services/form-helper.service';
import { User } from '../../../core/models/user.model';
import { UserService } from '../../../core/api/user.service';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { of } from 'rxjs';
import { UserHelper } from '../../../core/services/user-helper.service';

@Component({
  selector: 'esomus-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.sass']
})
export class UserComponent implements OnInit {
  entityForm: FormGroup;
  fieldType = FormFieldType;
  user: User;
  personOptions: EntityOption;
  languageOptions: EntityOption;

  constructor(
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private personService: PersonService
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((routeParams) => {
      this.entityForm = this.fb.group({
        username: [null, [Validators.required]],
        email: [null, [Validators.required, Validators.email]],
        active: [null],
        plainPassword: [null],
        light: [null],
        person: [null, [Validators.required]],
        language: [null, [Validators.required]],
      });

      this.personOptions = {get: () => this.personService.findAll(), propName: 'fullName'};
      this.languageOptions = {
        get: () => of(UserHelper.getLanguageOptions()), propName: 'label'
      };

      const userID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);
      if (!isNaN(userID)) {
        this._getUser(userID);
      } else {
        this.user = new User();
        this.user.active = true;
        this.entityForm.get('active').setValue(this.user.active);
        this.cd.detectChanges();
      }
    });
  }

  private _getUser(userID: number) {
    this.userService.find(userID)
      .subscribe((user: User) => {
        this.user = user;
        this.user.plainPassword = null;

        FormHelper.initValues(this.user, this.entityForm);

        this.cd.detectChanges();
      });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    let entity = FormHelper.buildEntity(this.user, this.entityForm, {}) as User;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      ((entity.id) ? this.userService.put(entity) : this.userService.post(entity)),
      (result: User) => {
        this.entityForm.reset();
        this.toastrService.open((entity.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        if (this.user.id) {
          this.routeNameService.goTo('users');
        } else {
          this.routeNameService.goTo('user_view', {id: result.id});
        }
      }
    );
  }
}
