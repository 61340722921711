import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectListComponent } from './components/effect-list/effect-list.component';
import { EffectComponent } from './components/effect/effect.component';
import { SharedModule } from '../shared/shared.module';
import { DashboardModule } from '../dashboard/dashboard.module';


@NgModule({
  declarations: [EffectListComponent, EffectComponent],
  imports: [
    CommonModule,
    SharedModule,
    DashboardModule
  ]
})
export class EffectsModule { }
