import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Zone } from '../../../core/models/zone.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ToastrMessageType, ToastrService } from '../../../core/services/toastr.service';
import { ZoneService } from '../../../core/api/zone.service';
import { FormHelper } from '../../../core/services/form-helper.service';

@Component({
  selector: 'esomus-zone',
  templateUrl: './zone.component.html',
  styleUrls: ['./zone.component.sass']
})
export class ZoneComponent implements OnInit {
  zone: Zone;

  entityForm: FormGroup;
  fieldType = FieldType;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private zoneService: ZoneService,
  ) {
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      description: [null, [Validators.required, Validators.maxLength(30)]],
      order: [null, [Validators.required]]
    });

    const id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    if (!isNaN(id)) {
      this._getZone(id);
    } else {
      this.zone = new Zone();
    }
  }

  private _getZone(id: number) {
    this.zoneService.find(id).subscribe((result: Zone) => {
      this.zone = result;

      FormHelper.initValues(this.zone, this.entityForm);
      this.cd.detectChanges();
    });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    const entity = FormHelper.buildEntity(this.zone, this.entityForm, {}) as Zone;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      (this.zone.id) ? this.zoneService.put(entity) : this.zoneService.post(entity),
      (result: Zone) => {
        this.toastrService.open((this.zone.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        this.routeNameService.goTo('zones');
      }
    );
  }

  getZoneDeleteURL() {
    return this.zoneService.delete(this.zone.id);
  }

  getZoneSuccessURL() {
    return this.routeNameService.path('zones');
  }
}
