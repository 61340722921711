import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleComponent } from './pages/role/role.component';
import { RoleListComponent } from './pages/role-list/role-list.component';
import { SharedModule } from '../shared/shared.module';
import { DashboardModule } from '../dashboard/dashboard.module';


@NgModule({
  declarations: [RoleComponent, RoleListComponent],
  imports: [
    CommonModule,
    SharedModule,
    DashboardModule
  ]
})
export class RolesModule { }
