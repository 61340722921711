import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Item } from '../../../core/models/item.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute, Params } from '@angular/router';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ItemDescriptionService } from '../../../core/api/item-description.service';
import { EquipmentService } from '../../../core/api/equipment.service';
import { BuildingService } from '../../../core/api/building.service';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { SiteService } from '../../../core/api/site.service';
import { LightItem } from '../../../core/models/light-item.model';
import { of } from 'rxjs';
import { ItemSituationService } from '../../../core/api/item-situation.service';
import { ItemSituation } from '../../../core/models/item-situation.model';
import { FormHelper } from '../../../core/services/form-helper.service';

@Component({
  selector: 'esomus-equipment-move-modal',
  templateUrl: './equipment-move-modal.component.html',
  styleUrls: ['./equipment-move-modal.component.sass']
})
export class EquipmentMoveModalComponent implements OnInit {
  @ViewChild('formModal', {static: true}) formModal: TemplateRef<any>;
  formModalRef: MatDialogRef<EquipmentMoveModalComponent>;

  fieldType = FieldType;
  entityForm: FormGroup;

  @Input() currentItem: Item;

  buildingOptions: EntityOption;
  localOptions: EntityOption;
  situationOptions: EntityOption;
  containerOptions: EntityOption;

  itemSituations: ItemSituation[];

  private containers: LightItem[];

  constructor(
    private i18n: I18n,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private cd: ChangeDetectorRef,
    private itemDescriptionService: ItemDescriptionService,
    private equipmentService: EquipmentService,
    private siteService: SiteService,
    private buildingService: BuildingService,
    private itemSituationService: ItemSituationService,
  ) {
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      building: [],
    });

    this.itemSituationService.findAll().subscribe((results: ItemSituation[]) => {
      this.itemSituations = results;

      this.updateSituation();
    });

    this.buildingOptions = {
      get: () => this.siteService.getAllBuildings(this.currentItem.site.id, {origin: 'move', light: true}),
      propName: 'label'
    };

    this._updateContainers();

    this.formModalRef = this.dialog.open(this.formModal);
  }

  confirm() {
    if (this.entityForm.invalid) {
      return;
    }

    const entity = FormHelper.buildEntity(new Item(), this.entityForm);

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      this.equipmentService.move(this.currentItem.id, entity),
      (result: Item) => {
        this.formModalRef.close();
        this.formModal = null;

        this.routeNameService.goTo('equipment_view', {id: this.currentItem.id});
      }
    );
  }


  closeModal() {
    this.formModalRef.close();
    this.formModal = null;
  }

  changeBuilding(value: any) {
    if (this.entityForm.contains('local')) {
      this.entityForm.removeControl('local');
    }

    this._updateContainers();

    if (!value) {
      this.localOptions = null;
      return;
    }

    this.localOptions = {get: () => this.buildingService.getAllLocals(value, {origin: 'move', light: true}), propName: 'label'};
    this.entityForm.addControl('local', this.fb.control(null));
  }

  changeLocal(value: any) {
    this._updateContainers();
  }

  updateSituation() {
    console.log('EVENT');
    if (this.entityForm.contains('situation')) {
      this.entityForm.removeControl('situation');
      this.situationOptions = null;
      this.cd.detectChanges();
    }

    if (this.entityForm.contains('container') && this.entityForm.get('container').value) {
      const containerItem = this.containers.find(item => item.id === parseInt(this.entityForm.get('container').value, 10));
      if (!containerItem) {
        return;
      }

      let itemSituations = null;
      if (containerItem.itemType.value === 6) {
        itemSituations = this.itemSituations.filter(item => [2, 4].indexOf(item.value) !== -1);
      } else {
        itemSituations = this.itemSituations.filter(item => [3].indexOf(item.value) !== -1);
      }
      this.situationOptions = {get: () => of(itemSituations), propName: 'label', autoSelect: true};
    } else {
      this.situationOptions = {get: () => of(this.itemSituations.filter(item => item.value === 1)), propName: 'label', autoSelect: true};
    }

    setTimeout(() => {
      this.entityForm.addControl('situation', this.fb.control(null, [Validators.required]));
      this.cd.detectChanges();
    }, 0);
  }

  private _updateContainers() {
    const params: Params = {
      companyID: this.currentItem.company.id,
      siteID: this.currentItem.site.id,
      light: true,
      origin: 'move'
    };
    if (this.entityForm.get('building').value) {
      params.buildingID = this.entityForm.get('building').value;
    }
    if (this.entityForm.contains('local') && this.entityForm.get('local').value) {
      params.localID = this.entityForm.get('local').value;
    }
    if (this.entityForm.contains('container')) {
      this.entityForm.removeControl('container');
    }
    this.containerOptions = null;
    this.cd.detectChanges();

    this.equipmentService.findContainers(params).subscribe((results: LightItem[]) => {
      this.containers = results;
      this.entityForm.addControl('container', this.fb.control(null));
      this.containerOptions = {get: () => of(results), propName: 'label'};
      this.cd.detectChanges();
    });
  }
}
