import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ToastrMessageType, ToastrService } from '../../../core/services/toastr.service';
import { FormHelper } from '../../../core/services/form-helper.service';
import { EffectService } from '../../../core/api/effect.service';
import { Effect } from '../../../core/models/effect.model';

@Component({
  selector: 'esomus-effect',
  templateUrl: './effect.component.html',
  styleUrls: ['./effect.component.sass']
})
export class EffectComponent implements OnInit {
  effect: Effect;

  entityForm: FormGroup;
  fieldType = FieldType;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private effectService: EffectService,
  ) {
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      description: [null, [Validators.required, Validators.maxLength(20)]],
    });

    const id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    if (!isNaN(id)) {
      this._getEffect(id);
    } else {
      this.effect = new Effect();
    }
  }

  private _getEffect(id: number) {
    this.effectService.find(id).subscribe((result: Effect) => {
      this.effect = result;

      FormHelper.initValues(this.effect, this.entityForm);
      this.cd.detectChanges();
    });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    const entity = FormHelper.buildEntity(this.effect, this.entityForm, {}) as Effect;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      (this.effect.id) ? this.effectService.put(entity) : this.effectService.post(entity),
      (result: Effect) => {
        this.toastrService.open((this.effect.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        this.routeNameService.goTo('effects');
      }
    );
  }

  getEffectDeleteURL() {
    return this.effectService.delete(this.effect.id);
  }

  getEffectSuccessURL() {
    return this.routeNameService.path('effects');
  }
}
