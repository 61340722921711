import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImpactComponent } from './pages/impact/impact.component';
import { ImpactListComponent } from './pages/impact-list/impact-list.component';
import { SharedModule } from '../shared/shared.module';
import { DashboardModule } from '../dashboard/dashboard.module';


@NgModule({
  declarations: [ImpactComponent, ImpactListComponent],
  imports: [
    CommonModule,
    SharedModule,
    DashboardModule
  ]
})
export class ImpactsModule { }
