import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { ListValue } from '../models/list-value.model';

const crudPath: CRUDPath = {
  many: `/list_values/`,
  single: (id: number) => `/list_values/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class ListValueService extends CRUD<ListValue>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }
}
