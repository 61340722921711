import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccidentPersonPanelComponent } from './components/accident-person-panel/accident-person-panel.component';
import { AccidentPersonComponent } from './pages/accident-person/accident-person.component';
import { SharedModule } from '../shared/shared.module';
import { AccidentPersonViewComponent } from './pages/accident-person-view/accident-person-view.component';


@NgModule({
  declarations: [AccidentPersonPanelComponent, AccidentPersonComponent, AccidentPersonViewComponent],
  exports: [
    AccidentPersonPanelComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class AccidentPersonsModule { }
