<mat-accordion multi="true" class="mat-edit">
  <mat-expansion-panel expanded="true" *ngIf="user">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Utilisateur: {{user.id ? 'Edition' : 'Création'}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <form>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px">
          <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('username')"
                        i18n-label="@@username" label="Username" flex="100"
                        fxFlex="50" [autofocus]="true"></esomus-input>
          <esomus-input [type]="fieldType.EMAIL" [control]="entityForm.get('email')"
                        i18n-label="@@email" label="Email" flex="100"
                        fxFlex="50"></esomus-input>
          <esomus-input [type]="fieldType.PASSWORD" autoComplete="new-password" [control]="entityForm.get('plainPassword')"
                        i18n-label="@@plainPassword" label="Mot de passe" flex="100"
                        fxFlex="50"></esomus-input>
          <esomus-ng-select *ngIf="entityForm.get('person')" [options]="personOptions"
                            [control]="entityForm.get('person')"
                            i18n-label="@@person" label="Personne" fxFlex="100"
                            flex="50"></esomus-ng-select>
          <esomus-ng-select *ngIf="entityForm.get('language')" [options]="languageOptions"
                            [control]="entityForm.get('language')"
                            i18n-label="@@language" label="Langue" fxFlex="100"
                            flex="50"></esomus-ng-select>
          <mat-checkbox [formControl]="entityForm.get('light')" i18n-label="@@light"
                        label="Light" fxFlex="50">Light
          </mat-checkbox>
          <mat-checkbox [formControl]="entityForm.get('active')" i18n-label="@@active"
                        label="Actif" fxFlex="50">Actif
          </mat-checkbox>
        </div>
        <div class="btn-group-right">
          <esomus-back-button></esomus-back-button>
          <esomus-submit (submit)="submit()"></esomus-submit>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>
