import { RightEnum } from '../../../../../../core/enum/right.enum';

export class SidenavItem {
  id: string;
  name: string;
  icon?: string;
  route?: string;
  routeName?: string;
  parent?: SidenavItem;
  subItems?: SidenavItem[];
  position: number;
  badge?: string;
  badgeColor?: string;
  customClass?: string;
  routerLinkActiveOptions?: any;
  isOpen: boolean;

  constructor(model: Partial<SidenavItemConfig>) {
    Object.assign(this, model);

    this.routerLinkActiveOptions = { exact: false };
    this.isOpen = false;
  }

  // generateLetterIcon() {
  //   const words = this.name.split(' ');

  //   if (words.length > 1) {
  //     return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toLowerCase();
  //   } else {
  //     return this.name.charAt(0).toUpperCase() + this.name.charAt(1).toLowerCase();
  //   }
  // }
}

/**
 * Use to create the menu manually
 */
export interface SidenavItemConfig {
  name: string;
  icon?: string;
  routeName?: string;
  subItems?: SidenavItemConfig[];
  position: number;
  customClass?: string;
  rights?: RightEnum[];
}
