import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipmentComponent } from './pages/equipment/equipment.component';
import { EquipmentTableComponent } from './components/equipment-table/equipment-table.component';
import { MaterialModule } from '../shared/material.module';
import { SharedModule } from '../shared/shared.module';
import { ItemModule } from '../item/item.module';
import { EquipmentAddComponent } from './pages/equipment-add/equipment-add.component';
import { EquipmentViewComponent } from './pages/equipment-view/equipment-view.component';
import { FormsModule } from '../forms/forms.module';
import { DocumentsModule } from '../documents/documents.module';
import { ActionsAnomaliesModule } from '../actions-anomalies/actions-anomalies.module';
import { EquipmentPanelComponent } from './components/equipment-panel/equipment-panel.component';
import { ContactModule } from '../contact/contact.module';
import { EquipmentCreateModalComponent } from './components/equipment-create-modal/equipment-create-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ObligationsModule } from '../obligations/obligations.module';
import { EquipmentLinkComponent } from './components/equipment-link/equipment-link.component';
import { DashboardModule } from '../dashboard/dashboard.module';
import { EquipmentConformityModalComponent } from './components/equipment-conformity-modal/equipment-conformity-modal.component';
import { EquipmentStatusModalComponent } from './components/equipment-status-modal/equipment-status-modal.component';
import { EquipmentReplaceModalComponent } from './components/equipment-replace-modal/equipment-replace-modal.component';
import { EquipmentMoveModalComponent } from './components/equipment-move-modal/equipment-move-modal.component';

@NgModule({
  declarations: [
    EquipmentComponent, EquipmentTableComponent, EquipmentAddComponent, EquipmentViewComponent,
    EquipmentPanelComponent, EquipmentCreateModalComponent, EquipmentLinkComponent,
    EquipmentConformityModalComponent, EquipmentStatusModalComponent, EquipmentReplaceModalComponent, EquipmentMoveModalComponent
  ],
  exports: [
    EquipmentTableComponent,
    EquipmentPanelComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    ItemModule,
    FormsModule,
    DocumentsModule,
    ActionsAnomaliesModule,
    ContactModule,
    MatDialogModule,
    ObligationsModule,
    DashboardModule
  ]
})
export class EquipmentsModule {
}
