import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Document } from '../models/document.model';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { CRUD, CRUDPath } from './crud';
import { HttpResponse } from '@angular/common/http';
import { Entity } from '../models/entity.model';
import { Params } from '@angular/router';

const crudPath: CRUDPath = {
  single: (id: number) => `/documents/${id}`,
  many: `/documents/`
};

@Injectable({
  providedIn: 'root'
})
export class DocumentService extends CRUD<Document> {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  management(documentTypeID: number, params?: Params): Observable<Array<Document>> {
    return this.http.get<Array<Document>>(`${environment.api.url}${crudPath.many}management/${documentTypeID}`, params);
  }

  search(documentTypeID: number): Observable<Array<Document>> {
    return this.http.get<Array<Document>>(`${environment.api.url}${crudPath.many}search/${documentTypeID}`);
    // return this.getParent(parentID, parentType, searchData);
    // return this.http.get<Array<Document>>(`${environment.api.url}${this.crudPath.many}filter`, searchData);
  }

  postVersion(previousDocumentID: number, entity: Document) {
    const body = this._createFormData(entity);
    return this.http.post<Document>(`${environment.api.url}${this.crudPath.single(previousDocumentID)}/version`, body);
  }

  preview(documentID: number) {
    return this.http.getBlob(`${environment.api.url}${crudPath.single(documentID)}/preview`).subscribe(
      (res: HttpResponse<Blob>) => {
        const fileURL = URL.createObjectURL(res.body);
        window.open(fileURL, '_blank');
      });
    // return this.http.get<Array<Document>>(`${environment.api.url}${crudPath.many}preview/${documentID}`);
  }

  /**
   * GET download document
   */
  download(dID: number, subUrl: string = ''): Observable<HttpResponse<Blob>> {
    return this.http.getBlob(`${environment.api.url}${subUrl}${crudPath.single(dID)}/download`);
  }

  link(dID: number, subUrl: string = '', data: any = null): Observable<Document> {
    return this.http.post<Document>(`${environment.api.url}${subUrl}${crudPath.single(dID)}/link`, null, data);
  }

  unlink(dID: number, subUrl: string = ''): Observable<Document> {
    return this.http.delete<Document>(`${environment.api.url}${subUrl}${crudPath.single(dID)}/unlink`);
  }

  open(dID: number, subUrl: string = '') {
    return this.download(dID, subUrl).subscribe(
      (res: HttpResponse<Blob>) => {
        const fileURL = URL.createObjectURL(res.body);
        window.open(fileURL, '_blank');
        // return new Blob([res.blob()], { type: 'application/pdf' });
      });
  }

  forceDownload(observable: Observable<HttpResponse<Blob>>) {
    observable.subscribe((res: HttpResponse<Blob>) => {
      let contentDispositionHeader = res.headers.get('Content-Disposition');
      let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];

      let a = window.document.createElement('a');
      a.href = URL.createObjectURL(res.body);
      a.download = result.replace(/"/g, '');
      a.click();
    });
  }

  postParent(document: Document, parent: Entity, parentType: string): Observable<Document> {
    switch (parentType) {
      case 'category':
        return this.postCategory(document, parent.id);
      case 'item':
        return this.postItem(document, parent.id);
      case 'itemDescription':
        return this.postItemDescription(document, parent.id);
      case 'anomaly':
        return this.postAnomaly(document, parent.id);
      case 'action':
        return this.postAction(document, parent.id);
      case 'task':
        return this.postTask(document, parent.id);
      case 'accident':
        return this.postAccident(document, parent.id);
      case 'closedAccident':
        return this.postClosedAccident(document, parent.id);
    }
  }

  getParent(parentID: number, parentType: string, searchData: Params = null): Observable<Array<Document>> {
    switch (parentType) {
      case 'category':
        return this.getCategory(parentID, searchData);
      case 'item':
        return this.getItem(parentID, searchData);
      case 'itemActivity':
        return this.getItemActivity(parentID, searchData);
      case 'itemDescription':
        return this.getItemDescription(parentID, searchData);
      case 'anomaly':
        return this.getAnomaly(parentID, searchData);
      case 'action':
        return this.getAction(parentID, searchData);
      case 'task':
        return this.getTask(parentID, searchData);
      case 'accident':
        return this.getAccident(parentID, searchData);
      case 'closedAccident':
        return this.getClosedAccident(parentID, searchData);
    }
  }

  downloadParent(parentID: number, id: number, parentType: string) {
    switch (parentType) {
      case 'category':
        return this.downloadCategory(parentID, id);
      case 'item':
        return this.downloadItem(parentID, id);
      case 'itemActivity':
        return this.downloadItemActivity(parentID, id);
      case 'itemDescription':
        return this.downloadItemDescription(parentID, id);
      case 'anomaly':
        return this.downloadAnomaly(parentID, id);
      case 'action':
        return this.downloadAction(parentID, id);
      case 'task':
        return this.downloadTask(parentID, id);
      case 'accident':
        return this.downloadAccident(parentID, id);
      case 'closedAccident':
        return this.downloadClosedAccident(parentID, id);
    }
  }

  openParent(parentID: number, id: number, parentType: string) {
    switch (parentType) {
      case 'category':
        return this.openCategory(parentID, id);
      case 'item':
        return this.openItem(parentID, id);
      case 'itemActivity':
        return this.openItemActivity(parentID, id);
      case 'itemDescription':
        return this.openItemDescription(parentID, id);
      case 'anomaly':
        return this.openAnomaly(parentID, id);
      case 'action':
        return this.openAction(parentID, id);
      case 'task':
        return this.openTask(parentID, id);
      case 'accident':
        return this.openAccident(parentID, id);
      case 'closedAccident':
        return this.openClosedAccident(parentID, id);
    }
  }

  linkParent(parentID: number, id: number, parentType: string, data: any = {}) {
    switch (parentType) {
      case 'category':
        return this.linkCategory(parentID, id, data);
      case 'item':
        return this.linkItem(parentID, id);
      case 'itemDescription':
        return this.linkItemDescription(parentID, id, data);
      case 'anomaly':
        return this.linkAnomaly(parentID, id, data);
      case 'action':
        return this.linkAction(parentID, id, data);
      case 'task':
        return this.linkTask(parentID, id, data);
      case 'accident':
        return this.linkAccident(parentID, id, data);
      case 'closedAccident':
        return this.linkClosedAccident(parentID, id, data);
    }
  }

  unlinkParent(parentID: number, id: number, parentType: string) {
    switch (parentType) {
      case 'category':
        return this.unlinkCategory(parentID, id);
      case 'item':
        return this.unlinkItem(parentID, id);
      case 'itemDescription':
        return this.unlinkItemDescription(parentID, id);
      case 'anomaly':
        return this.unlinkAnomaly(parentID, id);
      case 'action':
        return this.unlinkAction(parentID, id);
      case 'task':
        return this.unlinkTask(parentID, id);
      case 'accident':
        return this.unlinkAccident(parentID, id);
      case 'closedAccident':
        return this.unlinkClosedAccident(parentID, id);
    }
  }

  // region Item

  postItem(document: Document, itemID: number): Observable<Document> {
    return this.post(document, `/items/${itemID}`);
  }

  getItem(itemID: number, searchData: Params): Observable<Array<Document>> {
    return this.findAll(`/items/${itemID}`, searchData);
  }

  getItemActivity(itemID: number, searchData: Params): Observable<Array<Document>> {
    return this.findAll(`/items/${itemID}/activities`, searchData);
  }

  downloadItem(itemID: number, documentID: number) {
    this.forceDownload(this.download(documentID, `/items/${itemID}`));
  }

  openItem(itemID: number, documentID: number) {
    return this.open(documentID, `/items/${itemID}`);
  }

  linkItem(itemID: number, documentID: number) {
    return this.link(documentID, `/items/${itemID}`);
  }

  unlinkItem(itemID: number, documentID: number) {
    return this.unlink(documentID, `/items/${itemID}`);
  }

  // endregion

  // region ItemDescription

  postItemDescription(document: Document, itemDescriptionID: number): Observable<Document> {
    return this.post(document, `/item_descriptions/${itemDescriptionID}`);
  }

  getItemDescription(itemDescriptionID: number, searchData: Params): Observable<Array<Document>> {
    return this.findAll(`/item_descriptions/${itemDescriptionID}`, searchData);
  }

  downloadItemDescription(itemDescriptionID: number, documentID: number) {
    this.forceDownload(this.download(documentID, `/item_descriptions/${itemDescriptionID}`));
  }

  openItemDescription(itemDescriptionID: number, documentID: number) {
    return this.open(documentID, `/item_descriptions/${itemDescriptionID}`);
  }

  linkItemDescription(itemDescriptionID: number, documentID: number, data: any) {
    return this.link(documentID, `/item_descriptions/${itemDescriptionID}`, data);
  }

  unlinkItemDescription(itemDescriptionID: number, documentID: number) {
    return this.unlink(documentID, `/item_descriptions/${itemDescriptionID}`);
  }

  // endregion

  // region Category

  postCategory(document: Document, categoryID: number): Observable<Document> {
    return this.post(document, `/categories/${categoryID}`);
  }

  getCategory(categoryID: number, searchData: Params): Observable<Array<Document>> {
    return this.findAll(`/categories/${categoryID}`, searchData);
  }

  downloadCategory(categoryID: number, documentID: number) {
    this.forceDownload(this.download(documentID, `/categories/${categoryID}`));
  }

  openCategory(categoryID: number, documentID: number) {
    return this.open(documentID, `/categories/${categoryID}`);
  }

  linkCategory(categoryID: number, documentID: number, data: any) {
    return this.link(documentID, `/categories/${categoryID}`, data);
  }

  unlinkCategory(categoryID: number, documentID: number) {
    return this.unlink(documentID, `/categories/${categoryID}`);
  }

  // endregion

  // region Anomaly

  postAnomaly(document: Document, anomalyID: number): Observable<Document> {
    return this.post(document, `/anomalies/${anomalyID}`);
  }

  getAnomaly(anomalyID: number, searchData: Params): Observable<Array<Document>> {
    return this.findAll(`/anomalies/${anomalyID}`, searchData);
  }

  downloadAnomaly(anomalyID: number, documentID: number) {
    this.forceDownload(this.download(documentID, `/anomalies/${anomalyID}`));
  }

  openAnomaly(anomalyID: number, documentID: number) {
    return this.open(documentID, `/anomalies/${anomalyID}`);
  }

  linkAnomaly(anomalyID: number, documentID: number, data: any) {
    return this.link(documentID, `/anomalies/${anomalyID}`, data);
  }

  unlinkAnomaly(anomalyID: number, documentID: number) {
    return this.unlink(documentID, `/anomalies/${anomalyID}`);
  }

  // endregion

  // region Action

  postAction(document: Document, actionID: number): Observable<Document> {
    return this.post(document, `/actions/${actionID}`);
  }

  getAction(actionID: number, searchData: Params): Observable<Array<Document>> {
    return this.findAll(`/actions/${actionID}`, searchData);
  }

  downloadAction(actionID: number, documentID: number) {
    this.forceDownload(this.download(documentID, `/actions/${actionID}`));
  }

  openAction(actionID: number, documentID: number) {
    return this.open(documentID, `/actions/${actionID}`);
  }

  linkAction(actionID: number, documentID: number, data: any) {
    return this.link(documentID, `/actions/${actionID}`, data);
  }

  unlinkAction(actionID: number, documentID: number) {
    return this.unlink(documentID, `/actions/${actionID}`);
  }

  // endregion

  // region Task

  postTask(document: Document, taskID: number): Observable<Document> {
    return this.post(document, `/tasks/${taskID}`);
  }

  getTask(taskID: number, searchData: Params): Observable<Array<Document>> {
    return this.findAll(`/tasks/${taskID}`, searchData);
  }

  downloadTask(taskID: number, documentID: number) {
    this.forceDownload(this.download(documentID, `/tasks/${taskID}`));
  }

  openTask(taskID: number, documentID: number) {
    return this.open(documentID, `/tasks/${taskID}`);
  }

  linkTask(taskID: number, documentID: number, data: any) {
    return this.link(documentID, `/tasks/${taskID}`, data);
  }

  unlinkTask(taskID: number, documentID: number) {
    return this.unlink(documentID, `/tasks/${taskID}`);
  }

  // endregion

  // region Accident

  postAccident(document: Document, accidentID: number): Observable<Document> {
    return this.post(document, `/accidents/${accidentID}`);
  }

  getAccident(accidentID: number, searchData: Params): Observable<Array<Document>> {
    return this.findAll(`/accidents/${accidentID}`, searchData);
  }

  downloadAccident(accidentID: number, documentID: number) {
    this.forceDownload(this.download(documentID, `/accidents/${accidentID}`));
  }

  openAccident(accidentID: number, documentID: number) {
    return this.open(documentID, `/accidents/${accidentID}`);
  }

  linkAccident(accidentID: number, documentID: number, data: any) {
    return this.link(documentID, `/accidents/${accidentID}`, data);
  }

  unlinkAccident(accidentID: number, documentID: number) {
    return this.unlink(documentID, `/accidents/${accidentID}`);
  }

  // endregion

  // region Accident

  postClosedAccident(document: Document, accidentID: number): Observable<Document> {
    return this.post(document, `/closed_accidents/${accidentID}`);
  }

  getClosedAccident(accidentID: number, searchData: Params): Observable<Array<Document>> {
    return this.findAll(`/closed_accidents/${accidentID}`, searchData);
  }

  downloadClosedAccident(accidentID: number, documentID: number) {
    this.forceDownload(this.download(documentID, `/closed_accidents/${accidentID}`));
  }

  openClosedAccident(accidentID: number, documentID: number) {
    return this.open(documentID, `/closed_accidents/${accidentID}`);
  }

  linkClosedAccident(accidentID: number, documentID: number, data: any) {
    return this.link(documentID, `/closed_accidents/${accidentID}`, data);
  }

  unlinkClosedAccident(accidentID: number, documentID: number) {
    return this.unlink(documentID, `/closed_accidents/${accidentID}`);
  }

  // endregion

  // region ItemActivity

  downloadItemActivity(itemID: number, documentID: number) {
    this.forceDownload(this.download(documentID, `/items/${itemID}/activities`));
  }

  openItemActivity(itemID: number, documentID: number) {
    return this.open(documentID, `/items/${itemID}/activities`);
  }

  // endregion
}
