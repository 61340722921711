import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactTableComponent } from './components/contact-table/contact-table.component';
import { ContactLinkComponent } from './components/contact-link/contact-link.component';
import { SharedModule } from '../shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [ContactTableComponent, ContactLinkComponent],
  exports: [
    ContactTableComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatDialogModule,
  ]
})
export class ContactModule { }
