<mat-accordion multi="true" *ngIf="category">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Catégorie : {{category.label}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
        <div *ngIf="showBtns" fxLayoutAlign="center center">
          <button mat-flat-button (click)="editCategory()" color="success">
            <mat-icon>edit</mat-icon>
            Editer
          </button>
        </div>
        <esomus-dashboard-print-selector screenID="9"
                                         [itemID]="category.id"></esomus-dashboard-print-selector>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex="30" fxFlex.xs="100">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <esomus-img fxFlex="75" [picture]="category.picture"></esomus-img>
            <esomus-line-data fxFlex="100" boldFlex="50" textFlex="50"
                              boldValue="Code esomus"
                              [textValue]="category.code"></esomus-line-data>
          </div>
        </div>
        <div fxLayout="column" fxFlex>
          <div fxLayout="row wrap" fxLayoutAlign="start">
            <esomus-line-data flex="50" fxFlex="100" boldValue="Libellé"
                              [textValue]="category.label"></esomus-line-data>
            <esomus-line-data flex="50" fxFlex="100" boldValue="Famille"
                              [textValue]="family.label"
                              [textURL]="getFamilyURL()"></esomus-line-data>
            <esomus-line-data flex="50" fxFlex="100" boldValue="Commentaire"
                              [textValue]="category.comment"></esomus-line-data>
            <esomus-line-data flex="50" fxFlex="100" boldValue="Formulaire"
                              [textValue]="category.form ? category.form.name : null"></esomus-line-data>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel expanded="true" *ngIf="itemDescriptionsTable">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Fiche : {{nbItems}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <esomus-table *ngIf="itemDescriptionsTable" (getLength)="updateNbItems($event)"
                  [searchReceiver]="search" [options]="itemDescriptionsTable"></esomus-table>
  </mat-expansion-panel>
  <mat-expansion-panel *ngIf="formID">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Information complémentaire
      </mat-panel-title>
    </mat-expansion-panel-header>
    <esomus-form-component *ngIf="formID" [disabled]="true" [formID]="formID"
                           [forms]="forms"></esomus-form-component>
  </mat-expansion-panel>
  <esomus-document-table [showBtns]="showEquipmentBtns" [parent]="category"
                         parentType="category"></esomus-document-table>
</mat-accordion>

<ng-template #headerTable>
  <mat-checkbox [checked]="true" (change)="itemDescriptionChange($event)">Afficher les actifs
  </mat-checkbox>
</ng-template>
