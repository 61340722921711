<!-- === STANDARD === -->
<mat-expansion-panel *ngIf="visible && !modal" [expanded]="true" [class.mat-expansion-panel-border]="formTitle">
  <mat-expansion-panel-header *ngIf="formTitle" collapsedHeight="20px" expandedHeight="20px">
    <mat-panel-title *ngIf="formTitle">
      <ng-container *ngTemplateOutlet="formHeader"></ng-container>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <form *ngIf="form" #ngForm="ngForm" [formGroup]="form" (ngSubmit)="submitForm(ngForm)">
    <ng-container *ngTemplateOutlet="formBody"></ng-container>
    <div class="btn-group-right">
      <ng-container *ngTemplateOutlet="formFooter"></ng-container>
    </div>
  </form>
</mat-expansion-panel>

<!-- === MODAL === -->
<ng-template #formModal>
  <h1 *ngIf="formTitle" mat-dialog-title>
    <ng-container *ngTemplateOutlet="formHeader"></ng-container>
  </h1>
  <form *ngIf="form" #ngForm="ngForm" [formGroup]="form" (ngSubmit)="submitForm(ngForm)">
    <div mat-dialog-content>
      <ng-container *ngTemplateOutlet="formBody"></ng-container>
    </div>
    <div mat-dialog-actions>
      <ng-container *ngTemplateOutlet="formFooter"></ng-container>
    </div>
  </form>
</ng-template>

<!-- === TEMPLATES === -->

<ng-template #formHeader>
  <span fxLayoutAlign="start center">
    <mat-icon *ngIf="formTitleIcon">{{formTitleIcon}}</mat-icon>
    <ng-container *ngIf="formTitle">{{formTitle}}</ng-container>
  </span>
</ng-template>

<ng-template #formBody>
  <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap.gt-xs="10px">

    <ng-container *ngFor="let field of options.fields">
      <ng-container [ngSwitch]="field.type">
        <!-- SELECT -->
        <ng-container *ngSwitchCase="'select'">
          <esomus-select [label]="field.label" [control]="form.get(field.name)" [options]="field['options']"
            [required]="isRequired[field.name]" flex="225px" [visible]="isVisible[field.name]" [disabled]="field.disabled">
          </esomus-select>
        </ng-container>
        <!-- ENTITY -->
        <ng-container *ngSwitchCase="'entity'">
          <esomus-ng-select [label]="field.label" [control]="form.get(field.name)" [multiple]="field['multiple']"
            [options]="field['options']" flex="225px" [visible]="isVisible[field.name]" [disabled]="field.disabled">
          </esomus-ng-select>
        </ng-container>
        <!-- INPUT FILE -->
        <ng-container *ngSwitchCase="'file'">
          <esomus-input-file [label]="field.label" [control]="form.get(field.name)" [multiple]="field['multiple']"
            flex="225px" [visible]="isVisible[field.name]" [disabled]="field.disabled"></esomus-input-file>
        </ng-container>
        <!-- TEXTAREA -->
        <ng-container *ngSwitchCase="'textarea'">
          <esomus-textarea [label]="field.label" [control]="form.get(field.name)" [rows]="field['rows']"
            [cols]="field['cols']" flex="500px" [visible]="isVisible[field.name]" [disabled]="field.disabled"></esomus-textarea>
        </ng-container>
        <!-- CHECKBOX -->
        <ng-container *ngSwitchCase="'checkbox'">
          <div fxFlex="225px" [class.hidden]="!isVisible[field.name]">
            <mat-checkbox [formControl]="form.get(field.name)" [disabled]="field.disabled">{{field.label}}</mat-checkbox>
          </div>
        </ng-container>
        <!-- INPUT HIDDEN -->
        <ng-container *ngSwitchCase="'hidden'">
          <!-- nothing -->
        </ng-container>
        <!-- Default: INPUT -->
        <ng-container *ngSwitchDefault>
          <esomus-input [type]="field.type" [label]="field.label" [control]="form.get(field.name)"
            [required]="isRequired[field.name]" flex="225px" [visible]="isVisible[field.name]" [disabled]="field.disabled"></esomus-input>
        </ng-container>
      </ng-container>
    </ng-container>

  </div>
</ng-template>

<ng-template #formFooter>
  <esomus-submit *ngIf="options.submitIsVisible" [label]="options.submitLabel" [invalid]="form.invalid" [submitted]="formSubmitted"></esomus-submit>
  <button *ngIf="options.mode === 'search_mode'" type="button" mat-stroked-button i18n="@@clearForm"
    (click)="resetForm(true)">
    Vider
  </button>
  <button *ngIf="modal" type="button" mat-stroked-button i18n="@@cancel" (click)="resetForm()">Annuler</button>
</ng-template>
