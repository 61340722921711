import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { JwtModule } from '@auth0/angular-jwt';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { environment } from 'src/environments/environment';
import { SharedModule } from '../shared/shared.module';
import { CoreRoutingModule } from './core-routing.module';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { LoginComponent } from './pages/login/login.component';
import { PaginatorIntl } from './services/paginator-intl.service';
import { RouteNameService } from './services/route-name.service';

export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    LoginComponent,
  ],
  imports: [
    CoreRoutingModule,
    HttpClientModule,
    SharedModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        whitelistedDomains: [environment.api.domain],
        blacklistedRoutes: [`${environment.api.url}/login`]
      }
    })
  ],
  exports: [
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {
      provide: MatPaginatorIntl,
      useFactory: (i18n) => {
        return new PaginatorIntl(i18n);
      },
      deps: [I18n]
    },
    RouteNameService
  ]
})
export class CoreModule { }
