import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Anomaly } from '../../../core/models/anomaly.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ToastrService } from '../../../core/services/toastr.service';
import { AnomalyDataService } from '../../../core/api/anomaly-data.service';
import { AnomalyService } from '../../../core/api/anomaly.service';
import { AuthService } from '../../../core/api/auth.service';
import { RightEnum } from '../../../core/enum/right.enum';

@Component({
  selector: 'esomus-anomaly-view',
  templateUrl: './anomaly-view.component.html',
  styleUrls: ['./anomaly-view.component.sass']
})
export class AnomalyViewComponent implements OnInit {

  anomaly: Anomaly;

  showEquipmentAccessBtns: boolean;
  showEquipmentManageBtns: boolean;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private anomalyService: AnomalyService,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private anomalyDataService: AnomalyDataService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    const anomalyID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    this._getAnomaly(anomalyID);
  }

  private _getAnomaly(anomalyID: number) {
    this.anomalyService.find(anomalyID).subscribe((anomaly: Anomaly) => {
      this.authService.fetchPageRights({
        companyId: anomaly.item.company.id,
        siteId: anomaly.item.site.id,
        familyId: anomaly.item.family.id,
        categoryId: anomaly.item.category.id,
        anomalyId: anomaly.id,
        ownerId: anomaly.person.id
      }).subscribe(() => {
        this.showEquipmentAccessBtns = this.authService.hasPageRight(RightEnum.EQUIPMENT_ACCESS);
        this.showEquipmentManageBtns = this.authService.hasPageRight(RightEnum.EQUIPMENT_MANAGE);

        this.anomaly = anomaly;

        this.cd.detectChanges();
      });
    });
  }

  getFetchDataURL(formID: number) {
    return this.anomalyDataService.getData(this.anomaly.id, formID);
  }
}
