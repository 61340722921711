import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { ObligationType } from '../models/obligation-type.model';

const crudPath: CRUDPath = {
  many: `/obligation_types/`,
  single: (id: number) => `/obligation_types/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class ObligationTypeService extends CRUD<ObligationType>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }
}
