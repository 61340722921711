import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[esomusAutoFocus]'
})
export class AutoFocusDirective implements AfterContentInit {
  @Input() public esomusAutoFocus: boolean;

  public constructor(private el: ElementRef) {
  }

  public ngAfterContentInit() {
    if (this.esomusAutoFocus === true) {
      setTimeout(() => {
        this.el.nativeElement.focus();
      }, 500);
    }
  }
}
