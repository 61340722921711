import { Component, Input, OnInit } from '@angular/core';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { AbstractControl, FormControl } from '@angular/forms';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Component({
  selector: 'esomus-person-input',
  templateUrl: './person-input.component.html',
  styleUrls: ['./person-input.component.sass']
})
export class PersonInputComponent implements OnInit {

  @Input() options: EntityOption;
  @Input() control: FormControl|AbstractControl;
  @Input() label: string;
  @Input() fxFlex: string;
  @Input() flex: string;

  constructor(
    private i18n: I18n,
  ) { }

  ngOnInit() {
    if (!this.label) {
      this.label = this.i18n({value: 'Attribué à', id: 'assignedTo'});
    }
    if (!this.fxFlex) {
      this.fxFlex = '50';
    }
    if (!this.flex) {
      this.flex = '100';
    }
  }

}
