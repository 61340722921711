import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { Item } from '../models/item.model';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import { LightItem } from '../models/light-item.model';

const crudPath: CRUDPath = {
  many: `/equipments/`,
  single: (id: number) => `/equipments/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class EquipmentService extends CRUD<Item> {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  findContainers(data: any): Observable<Array<LightItem>> {
    return this.http.get<Array<LightItem>>(`${environment.api.url}${this.crudPath.many}containers`, data);
  }

  findByCompany(companyID: number): Observable<Array<Item>> {
    return this.http.get<Array<Item>>(`${environment.api.url}${this.crudPath.many}companies/${companyID}`);
  }

  getParent(parentID: number): Observable<Item> {
    return this.http.get<Item>(`${environment.api.url}${this.crudPath.many}parent/${parentID}`);
  }

  postFromParent(entity: Item, parentID: number, params?: Params): Observable<Item> {
    const body = this._createFormData(entity);
    return this.http.post<Item>(`${environment.api.url}${this.crudPath.single(parentID)}`, body, params);
  }

  getAllFromParent(parentID: number, parent: any, params?: Params) {
    if (!params) {
      params = {};
    }
    if (parent) {
      params['type'] = parent;
    }
    return this.http.get<Array<Item>>(`${environment.api.url}${this.crudPath.many}parent/${parentID}/data`, params);
  }

  findLink(equipmentID: number, params?: Params) {
    return this.http.get<Array<Item>>(`${environment.api.url}${this.crudPath.single(equipmentID)}/link`, params);
  }

  findByScope(equipmentID: number, params?: Params) {
    return this.http.get<Array<Item>>(`${environment.api.url}${this.crudPath.single(equipmentID)}/link-scope`, params);
  }

  linkChild(equipmentID: number, childID: number, params?: Params) {
    return this.http.post<Item>(`${environment.api.url}${this.crudPath.single(equipmentID)}/link/${childID}`, null, params);
  }

  putStatus(equipmentID: number, data: any) {
    const body = this._createFormData(data);
    body.set('_method', 'PUT');

    return this.http.put(`${environment.api.url}${this.crudPath.single(equipmentID)}/status`, body);
  }

  putConformity(equipmentID: number, data: any) {
    const body = this._createFormData(data);
    body.set('_method', 'PUT');

    return this.http.put(`${environment.api.url}${this.crudPath.single(equipmentID)}/conformity`, body);
  }

  replace(currentEquipmentID: number, data: any) {
    const body = this._createFormData(data);

    return this.http.post(`${environment.api.url}${this.crudPath.single(currentEquipmentID)}/replace`, body);
  }

  move(currentEquipmentID: number, data: any) {
    const body = this._createFormData(data);
    body.set('_method', 'PUT');

    return this.http.put(`${environment.api.url}${this.crudPath.single(currentEquipmentID)}/move`, body);
  }
}
