<ng-container [ngSwitch]="field.fieldTypeName">
  <ng-container *ngSwitchCase="'text'">
    <esomus-input [type]="formFieldType.TEXT" [control]="fieldsForm.get(field.fullName)"
                  [label]="field.name" flex="100" [fxFlex]="flex"></esomus-input>
  </ng-container>
  <ng-container *ngSwitchCase="'number'">
    <esomus-input [type]="formFieldType.NUMBER" [control]="fieldsForm.get(field.fullName)"
                  [label]="field.name" flex="100" [fxFlex]="flex"></esomus-input>
  </ng-container>
  <ng-container *ngSwitchCase="'calendar'">
    <esomus-input [type]="formFieldType.DATE" [control]="fieldsForm.get(field.fullName)"
                  [label]="field.name" flex="100" [fxFlex]="flex"></esomus-input>
  </ng-container>
  <ng-container *ngSwitchCase="'textarea'">
    <esomus-textarea [control]="fieldsForm.get(field.fullName)" [label]="field.name" flex="100"
                     [fxFlex]="flex"></esomus-textarea>
  </ng-container>
  <ng-container *ngSwitchCase="'checkbox'">
    <mat-checkbox [formControl]="fieldsForm.get(field.fullName)"
                  [fxFlex]="flex">{{field.name}}</mat-checkbox>
  </ng-container>
  <ng-container *ngSwitchCase="'label'">
    <p class="p-form-dynamic" [class.text-bold]="field.options['bold']" [class.big-font]="field.options['bigFont']" [fxFlex]="flex">{{field.name}}</p>
  </ng-container>
  <ng-container *ngSwitchCase="'radio'">
    <mat-radio-group [name]="field.name" [fxFlex]="flex">
      <div fxLayout="column" [fxFlex]="flex" fxFlex.xs="100">
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <span [fxFlex]="flex">{{field.name}}</span>
          <mat-radio-button
            *ngFor="let value of fieldsOptions[field.fullName].get()|async"
            [fxFlex]="flex" [value]="value.id">
            {{value.value}}
          </mat-radio-button>
        </div>
      </div>
    </mat-radio-group>
  </ng-container>
  <ng-container *ngSwitchCase="'selectlist'">
    <esomus-ng-select [options]="fieldsOptions[field.fullName]"
                      [control]="fieldsForm.get(field.fullName)" [label]="field.name"
                      flex="100" [fxFlex]="flex"></esomus-ng-select>
  </ng-container>
</ng-container>
