export const environment = {
  production: true,
  appName: 'Esomus [DOCEO]',
  languages: ['fr', 'nl'],
  api: {
    domain: 'api.doceo.esomus.com',
    url: 'https://api.doceo.esomus.com/api'
  },
  version: '28/10/2019 14:50'
};
