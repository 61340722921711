import { Component, OnInit } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RouteNameService } from '../../../core/services/route-name.service';
import { CompanyService } from '../../../core/api/company.service';
import { Item } from '../../../core/models/item.model';
import { Observable } from 'rxjs';
import { AuthService } from '../../../core/api/auth.service';
import { RightEnum } from '../../../core/enum/right.enum';

@Component({
  selector: 'esomus-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.sass']
})
export class CompanyListComponent implements OnInit {

  companies$: Observable<Item[]>;

  showAddBtn: boolean;

  constructor(
    private i18n: I18n,
    private routeName: RouteNameService,
    private companyService: CompanyService,
    public authService: AuthService,
  ) {
    this.showAddBtn = authService.hasRight(RightEnum.COMPANY_ADMINISTER);
  }


  ngOnInit() {
    this._getCompanies();
  }

  private _getCompanies() {
    this.companies$ = this.companyService.findAll();
  }

  createCompany() {
    this.routeName.goTo('company');
  }
}
