import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '../shared/elastic-ui/layout/layout.component';
import { LoginComponent } from './pages/login/login.component';
import { ItemDescriptionListComponent } from '../item-descriptions/pages/item-description-list/item-description-list.component';
import { FamilyComponent } from '../families/pages/family/family.component';
import { FamilyListComponent } from '../families/pages/family-list/family-list.component';
import { ItemDescriptionComponent } from '../item-descriptions/pages/item-description/item-description.component';
import { CompanyListComponent } from '../companies/pages/company-list/company-list.component';
import { CompanyComponent } from '../companies/pages/company/company.component';
import { SiteComponent } from '../sites/pages/site/site.component';
import { EquipmentComponent } from '../equipments/pages/equipment/equipment.component';
import { BuildingComponent } from '../buildings/pages/building/building.component';
import { LocalComponent } from '../locals/pages/local/local.component';
import { CategoryComponent } from '../categories/pages/category/category.component';
import { FormListComponent } from '../forms/pages/form-list/form-list.component';
import { FormComponent } from '../forms/pages/form/form.component';
import { ListComponentListComponent } from '../list-components/pages/list-component-list/list-component-list.component';
import { ListComponentComponent } from '../list-components/pages/list-component/list-component.component';
import { ListValueComponent } from '../list-values/pages/list-value/list-value.component';
import { FamilyViewComponent } from '../families/pages/family-view/family-view.component';
import { CategoryViewComponent } from '../categories/pages/category-view/category-view.component';
import { ItemDescriptionViewComponent } from '../item-descriptions/pages/item-description-view/item-description-view.component';
import { CompanyAddComponent } from '../companies/pages/company-add/company-add.component';
import { CompanyViewComponent } from '../companies/pages/company-view/company-view.component';
import { SiteAddComponent } from '../sites/pages/site-add/site-add.component';
import { SiteViewComponent } from '../sites/pages/site-view/site-view.component';
import { EquipmentAddComponent } from '../equipments/pages/equipment-add/equipment-add.component';
import { EquipmentViewComponent } from '../equipments/pages/equipment-view/equipment-view.component';
import { BuildingViewComponent } from '../buildings/pages/building-view/building-view.component';
import { BuildingAddComponent } from '../buildings/pages/building-add/building-add.component';
import { LocalViewComponent } from '../locals/pages/local-view/local-view.component';
import { LocalAddComponent } from '../locals/pages/local-add/local-add.component';
import { AnomalyTypeListComponent } from '../anomaly-types/pages/anomaly-type-list/anomaly-type-list.component';
import { AnomalyTypeAddComponent } from '../anomaly-types/pages/anomaly-type-add/anomaly-type-add.component';
import { AnomalyTypeComponent } from '../anomaly-types/pages/anomaly-type/anomaly-type.component';
import { DocumentTypeListComponent } from '../document-types/pages/document-type-list/document-type-list.component';
import { DocumentTypeComponent } from '../document-types/pages/document-type/document-type.component';
import { DocumentTypeViewComponent } from '../document-types/pages/document-type-view/document-type-view.component';
import { PersonListComponent } from '../persons/pages/person-list/person-list.component';
import { PersonComponent } from '../persons/pages/person/person.component';
import { PersonViewComponent } from '../persons/pages/person-view/person-view.component';
import { AnomalyComponent } from '../anomalies/pages/anomaly/anomaly.component';
import { AnomalyViewComponent } from '../anomalies/pages/anomaly-view/anomaly-view.component';
import { ActionAddComponent } from '../actions/pages/action-add/action-add.component';
import { ActionComponent } from '../actions/pages/action/action.component';
import { ActionViewComponent } from '../actions/pages/action-view/action-view.component';
import { TaskComponent } from '../tasks/pages/task/task.component';
import { TaskViewComponent } from '../tasks/pages/task-view/task-view.component';
import { TaskPlanificationComponent } from '../tasks/pages/task-planification/task-planification.component';
import { ProgrammingComponent } from '../programmings/pages/programming/programming.component';
import { ProgrammingViewComponent } from '../programmings/pages/programming-view/programming-view.component';
import { ProgrammingListComponent } from '../programmings/pages/programming-list/programming-list.component';
import { TaskListComponent } from '../tasks/pages/task-list/task-list.component';
import { ActionRealisationComponent } from '../actions/pages/action-realisation/action-realisation.component';
import { DashboardViewComponent } from '../dashboard/pages/dashboard-view/dashboard-view.component';
import { TaskTypeListComponent } from '../task-types/pages/task-type-list/task-type-list.component';
import { TaskTypeComponent } from '../task-types/pages/task-type/task-type.component';
import { TaskTypeViewComponent } from '../task-types/pages/task-type-view/task-type-view.component';
import { UserListComponent } from '../users/pages/user-list/user-list.component';
import { UserComponent } from '../users/pages/user/user.component';
import { UserViewComponent } from '../users/pages/user-view/user-view.component';
import { DocumentManagementComponent } from '../documents/pages/document-management/document-management.component';
import { DashboardRedirectionComponent } from '../dashboard/pages/dashboard-redirection/dashboard-redirection.component';
import { ObligationTypeListComponent } from '../obligation-types/pages/obligation-type-list/obligation-type-list.component';
import { ObligationTypeComponent } from '../obligation-types/pages/obligation-type/obligation-type.component';
import { ObligationViewComponent } from '../obligations/pages/obligation-view/obligation-view.component';
import { ObligationListComponent } from '../obligations/pages/obligation-list/obligation-list.component';
import { ObligationComponent } from '../obligations/pages/obligation/obligation.component';
import { DashboardInformationComponent } from '../dashboard/pages/dashboard-information/dashboard-information.component';
import { RoleListComponent } from '../roles/pages/role-list/role-list.component';
import { RoleComponent } from '../roles/pages/role/role.component';
import { SearchViewComponent } from '../search/pages/search-view/search-view.component';
import { EquipmentLinkComponent } from '../equipments/components/equipment-link/equipment-link.component';
import { AccidentTypeListComponent } from '../accident-types/pages/accident-type-list/accident-type-list.component';
import { AccidentTypeComponent } from '../accident-types/pages/accident-type/accident-type.component';
import { ImpactListComponent } from '../impacts/pages/impact-list/impact-list.component';
import { ImpactComponent } from '../impacts/pages/impact/impact.component';
import { ZoneListComponent } from '../zones/pages/zone-list/zone-list.component';
import { ZoneComponent } from '../zones/pages/zone/zone.component';
import { AccidentListComponent } from '../accidents/pages/accident-list/accident-list.component';
import { AccidentComponent } from '../accidents/pages/accident/accident.component';
import { AccidentViewComponent } from '../accidents/pages/accident-view/accident-view.component';
import { EffectListComponent } from '../effects/components/effect-list/effect-list.component';
import { EffectComponent } from '../effects/components/effect/effect.component';
import { AccidentPersonComponent } from '../accident-persons/pages/accident-person/accident-person.component';
import { AccidentPersonViewComponent } from '../accident-persons/pages/accident-person-view/accident-person-view.component';
import { AccidentCloseComponent } from '../accidents/pages/accident-close/accident-close.component';
import { ParameterListComponent } from '../parameters/pages/parameter-list/parameter-list.component';
import { ParameterComponent } from '../parameters/pages/parameter/parameter.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {routeName: 'login'}
  },
  {
    // Default Home Url
    path: 'home',
    redirectTo: '/companies',
    data: {routeName: 'home'}
  },
  {
    path: '',
    component: LayoutComponent,
    children: [

      //
      // == ItemDescription
      //
      {
        path: 'item-descriptions',
        children: [
          {
            path: '',
            component: ItemDescriptionListComponent,
            data: {routeName: 'item_descriptions'}
          },
          {
            path: 'add',
            component: ItemDescriptionComponent,
            data: {routeName: 'item_description_add'}
          },
          {
            path: ':id/edit',
            component: ItemDescriptionComponent,
            data: {routeName: 'item_description_id'}
          },
          {
            path: ':id',
            component: ItemDescriptionViewComponent,
            data: {routeName: 'item_description_view'}
          }
        ]
      },
      //
      // == Persons
      //
      {
        path: 'persons',
        children: [
          {
            path: '',
            component: PersonListComponent,
            data: {routeName: 'persons'}
          },
          {
            path: 'add',
            component: PersonComponent,
            data: {routeName: 'person_add'}
          },
          {
            path: ':id/edit',
            component: PersonComponent,
            data: {routeName: 'person_id'}
          },
          {
            path: ':id',
            component: PersonViewComponent,
            data: {routeName: 'person_view'}
          }
        ]
      },
      {
        path: 'document-types',
        children: [
          {
            path: '',
            component: DocumentTypeListComponent,
            data: {routeName: 'document_types'}
          },
          {
            path: 'add',
            component: DocumentTypeComponent,
            data: {routeName: 'document_type_add'}
          },
          {
            path: ':id/edit',
            component: DocumentTypeComponent,
            data: {routeName: 'document_type_id'}
          },
          {
            path: ':id',
            component: DocumentTypeViewComponent,
            data: {routeName: 'document_type_view'}
          }
        ]
      },
      {
        path: 'task-types',
        children: [
          {
            path: '',
            component: TaskTypeListComponent,
            data: {routeName: 'task_types'}
          },
          {
            path: 'add',
            component: TaskTypeComponent,
            data: {routeName: 'task_type_add'}
          },
          {
            path: ':id/edit',
            component: TaskTypeComponent,
            data: {routeName: 'task_type_id'}
          },
          {
            path: ':id',
            component: TaskTypeViewComponent,
            data: {routeName: 'task_type_view'}
          }
        ]
      },
      {
        path: 'anomaly-types',
        children: [
          {
            path: '',
            component: AnomalyTypeListComponent,
            data: {routeName: 'anomaly_types'}
          },
          {
            path: 'add',
            component: AnomalyTypeAddComponent,
            data: {routeName: 'anomaly_type_add'}
          },
          {
            path: ':id/edit',
            component: AnomalyTypeComponent,
            data: {routeName: 'anomaly_type_id'}
          },
          {
            path: ':id',
            component: AnomalyTypeListComponent,
            data: {routeName: 'anomaly_type_view'}
          }
        ]
      },
      {
        path: 'obligation-types',
        children: [
          {
            path: '',
            component: ObligationTypeListComponent,
            data: {routeName: 'obligation_types'}
          },
          {
            path: 'add',
            component: ObligationTypeComponent,
            data: {routeName: 'obligation_type_add'}
          },
          {
            path: ':id/edit',
            component: ObligationTypeComponent,
            data: {routeName: 'obligation_type_id'}
          },
        ]
      },
      {
        path: 'accident-types',
        children: [
          {
            path: '',
            component: AccidentTypeListComponent,
            data: {routeName: 'accident_types'}
          },
          {
            path: 'add',
            component: AccidentTypeComponent,
            data: {routeName: 'accident_type_add'}
          },
          {
            path: ':id/edit',
            component: AccidentTypeComponent,
            data: {routeName: 'accident_type_id'}
          },
        ]
      },
      {
        path: 'impacts',
        children: [
          {
            path: '',
            component: ImpactListComponent,
            data: {routeName: 'impacts'}
          },
          {
            path: 'add',
            component: ImpactComponent,
            data: {routeName: 'impact_add'}
          },
          {
            path: ':id/edit',
            component: ImpactComponent,
            data: {routeName: 'impact_id'}
          },
        ]
      },
      {
        path: 'effects',
        children: [
          {
            path: '',
            component: EffectListComponent,
            data: {routeName: 'effects'}
          },
          {
            path: 'add',
            component: EffectComponent,
            data: {routeName: 'effect_add'}
          },
          {
            path: ':id/edit',
            component: EffectComponent,
            data: {routeName: 'effect_id'}
          },
        ]
      },
      {
        path: 'zones',
        children: [
          {
            path: '',
            component: ZoneListComponent,
            data: {routeName: 'zones'}
          },
          {
            path: 'add',
            component: ZoneComponent,
            data: {routeName: 'zone_add'}
          },
          {
            path: ':id/edit',
            component: ZoneComponent,
            data: {routeName: 'zone_id'}
          },
        ]
      },
      {
        path: 'parameters',
        children: [
          {
            path: '',
            component: ParameterListComponent,
            data: {routeName: 'parameters'}
          },
          {
            path: 'add',
            component: ParameterComponent,
            data: {routeName: 'parameter_add'}
          },
          {
            path: ':id/edit',
            component: ParameterComponent,
            data: {routeName: 'parameter_id'}
          },
        ]
      },
      {
        path: 'obligations',
        children: [
          {
            path: '',
            component: ObligationListComponent,
            data: {routeName: 'obligations'}
          },
          {
            path: 'add',
            component: ObligationComponent,
            data: {routeName: 'obligation_add'}
          },
          {
            path: ':id/edit',
            component: ObligationComponent,
            data: {routeName: 'obligation_id'}
          },
          {
            path: ':id',
            component: ObligationViewComponent,
            data: {routeName: 'obligation_view'}
          },
        ]
      },
      {
        path: 'roles',
        children: [
          {
            path: '',
            component: RoleListComponent,
            data: {routeName: 'roles'}
          },
          {
            path: 'add',
            component: RoleComponent,
            data: {routeName: 'role_add'}
          },
          {
            path: ':id/edit',
            component: RoleComponent,
            data: {routeName: 'role_id'}
          },
        ]
      },
      //
      // == FAMILY
      //
      {
        path: 'families',
        children: [
          {
            path: '',
            component: FamilyListComponent,
            data: {routeName: 'families'},
          },
          {
            path: 'add',
            component: FamilyComponent,
            data: {routeName: 'family_add'}
          },
          {
            path: ':id/edit',
            component: FamilyComponent,
            data: {routeName: 'family_id'}
          },
          {
            path: ':id',
            component: FamilyViewComponent,
            data: {routeName: 'family_view'}
          },
          {
            path: ':familyID/categories',
            children: [
              {
                path: 'add',
                component: CategoryComponent,
                data: {routeName: 'category_add'}
              },
              {
                path: ':id/edit',
                component: CategoryComponent,
                data: {routeName: 'category_id'}
              },
              {
                path: ':id',
                component: CategoryViewComponent,
                data: {routeName: 'category_view'}
              }
            ]
          }
        ]
      },
      //
      // == FORM
      //
      {
        path: 'forms',
        children: [
          {
            path: '',
            component: FormListComponent,
            data: {routeName: 'forms'},
          },
          {
            path: 'add',
            component: FormComponent,
            data: {routeName: 'form'}
          },
          {
            path: ':id',
            component: FormComponent,
            data: {routeName: 'form_id'}
          },
        ]
      },
      //
      // == LIST
      //
      {
        path: 'lists',
        children: [
          {
            path: '',
            component: ListComponentListComponent,
            data: {routeName: 'lists'},
          },
          {
            path: 'add',
            component: ListComponentComponent,
            data: {routeName: 'list'}
          },
          {
            path: ':id',
            component: ListComponentComponent,
            data: {routeName: 'list_id'}
          },
          {
            path: ':listComponentID/values',
            children: [
              {
                path: 'add',
                component: ListValueComponent,
                data: {routeName: 'value'}
              },
              {
                path: ':id',
                component: ListValueComponent,
                data: {routeName: 'value_id'}
              },
            ]
          }
        ]
      },
      //
      // == COMPANY
      //
      {
        path: 'companies',
        children: [
          {
            path: '',
            component: CompanyListComponent,
            data: {routeName: 'companies'},
          },
          {
            path: 'add',
            component: CompanyAddComponent,
            data: {routeName: 'company'}
          },
          {
            path: ':id/edit',
            component: CompanyComponent,
            data: {routeName: 'company_id'}
          },
          {
            path: ':id',
            component: CompanyViewComponent,
            data: {routeName: 'company_view'}
          },
          {
            path: ':companyID/sites/add',
            component: SiteAddComponent,
            data: {routeName: 'site_add'}
          }
        ]
      },
      //
      // == SITE
      //
      {
        path: 'sites',
        children: [
          {
            path: ':id/edit',
            component: SiteComponent,
            data: {routeName: 'site_id'}
          },
          {
            path: ':id',
            component: SiteViewComponent,
            data: {routeName: 'site_view'}
          },
          {
            path: ':siteID/buildings/add',
            component: BuildingAddComponent,
            data: {routeName: 'building_add'}
          }
        ]
      },
      //
      // == BUILDING
      //
      {
        path: 'buildings',
        children: [
          {
            path: ':id/edit',
            component: BuildingComponent,
            data: {routeName: 'building_id'}
          },
          {
            path: ':id',
            component: BuildingViewComponent,
            data: {routeName: 'building_view'}
          },
          {
            path: ':buildingID/locals/add',
            component: LocalAddComponent,
            data: {routeName: 'local_add'}
          }
        ]
      },
      //
      // == LOCAL
      //
      {
        path: 'locals',
        children: [
          {
            path: ':id/edit',
            component: LocalComponent,
            data: {routeName: 'local_id'}
          },
          {
            path: ':id',
            component: LocalViewComponent,
            data: {routeName: 'local_view'}
          },
        ]
      },
      //
      // == Equipment
      //
      {
        path: 'equipments',
        children: [
          {
            path: 'parent/:parentID/add',
            component: EquipmentAddComponent,
            data: {routeName: 'equipment_add'}
          },
          {
            path: ':id/edit',
            component: EquipmentComponent,
            data: {routeName: 'equipment_id'}
          },
          {
            path: ':id',
            component: EquipmentViewComponent,
            data: {routeName: 'equipment_view'}
          },
          {
            path: ':id/link',
            component: EquipmentLinkComponent,
            data: {routeName: 'equipment_link'}
          },
          {
            path: ':equipmentID/anomalies/add',
            component: AnomalyComponent,
            data: {routeName: 'anomaly_add'}
          },
          {
            path: ':equipmentID/actions/add',
            component: ActionAddComponent,
            data: {routeName: 'equipment_action_add'}
          }
        ]
      },
      //
      // == Anomaly
      //
      {
        path: 'anomalies',
        children: [
          {
            path: ':id/edit',
            component: AnomalyComponent,
            data: {routeName: 'anomaly_id'}
          },
          {
            path: ':id',
            component: AnomalyViewComponent,
            data: {routeName: 'anomaly_view'}
          },
          {
            path: ':anomalyID/actions/add',
            component: ActionAddComponent,
            data: {routeName: 'anomaly_action_add'}
          }
        ]
      },
      //
      // == Search
      //
      {
        path: 'search/:searchText',
        component: SearchViewComponent,
        data: {routeName: 'search'}
      },
      //
      // == Dashboard
      //
      {
        path: 'dashboard',
        children: [
          {
            path: 'list',
            component: DashboardViewComponent,
            data: {routeName: 'dashboard_view'}
          },
          {
            path: 'information',
            component: DashboardInformationComponent,
            data: {routeName: 'dashboard_information'}
          },
          {
            path: 'force/:id',
            component: DashboardRedirectionComponent,
            data: {routeName: 'dashboard_force'}
          },
        ]
      },
      //
      // == Action
      //
      {
        path: 'actions',
        children: [
          {
            path: ':id/edit',
            component: ActionComponent,
            data: {routeName: 'action_id'}
          },
          {
            path: ':id',
            component: ActionViewComponent,
            data: {routeName: 'action_view'}
          },
          {
            path: ':id/realisation',
            component: ActionRealisationComponent,
            data: {routeName: 'action_realisation'}
          },
        ]
      },
      //
      // == Task
      //
      {
        path: 'tasks',
        children: [
          {
            path: 'add',
            component: TaskComponent,
            data: {routeName: 'task_add'}
          },
          {
            path: ':id/edit',
            component: TaskComponent,
            data: {routeName: 'task_id'}
          },
          {
            path: ':id',
            component: TaskViewComponent,
            data: {routeName: 'task_view'}
          },
          {
            path: '',
            component: TaskListComponent,
            data: {routeName: 'tasks'}
          },
          {
            path: ':id/planification',
            component: TaskPlanificationComponent,
            data: {routeName: 'task_planification'}
          },
        ]
      },
      //
      // == Programming
      //
      {
        path: 'programmings',
        children: [
          {
            path: 'add',
            component: ProgrammingComponent,
            data: {routeName: 'programming_add'}
          },
          {
            path: ':id/edit',
            component: ProgrammingComponent,
            data: {routeName: 'programming_id'}
          },
          {
            path: ':id',
            component: ProgrammingViewComponent,
            data: {routeName: 'programming_view'}
          },
          {
            path: '',
            component: ProgrammingListComponent,
            data: {routeName: 'programmings'}
          },
        ]
      },
      //
      // == Document
      //
      {
        path: 'documents',
        component: DocumentManagementComponent,
        data: {routeName: 'documents'}
      },
      //
      // == USERS
      //
      {
        path: 'users',
        children: [
          {
            path: '',
            component: UserListComponent,
            data: {routeName: 'users'}
          },
          {
            path: 'add',
            component: UserComponent,
            data: {routeName: 'user_add'}
          },
          {
            path: ':id/edit',
            component: UserComponent,
            data: {routeName: 'user_id'}
          },
          {
            path: ':id',
            component: UserViewComponent,
            data: {routeName: 'user_view'}
          }
        ]
      },
      //
      // == Accidents
      //
      {
        path: 'accidents',
        children: [
          {
            path: '',
            component: AccidentListComponent,
            data: {routeName: 'accidents'}
          },
          {
            path: 'add',
            component: AccidentComponent,
            data: {routeName: 'accident_add'}
          },
          {
            path: ':id/edit',
            component: AccidentComponent,
            data: {routeName: 'accident_id'}
          },
          {
            path: ':id/close',
            component: AccidentCloseComponent,
            data: {routeName: 'accident_close'}
          },
          {
            path: ':id',
            component: AccidentViewComponent,
            data: {routeName: 'accident_view'}
          },
          {
            path: ':accidentID/actions/add',
            component: ActionAddComponent,
            data: {routeName: 'accident_action_add'}
          },
          {
            path: ':accidentID/persons',
            children: [
              {
                path: 'add',
                component: AccidentPersonComponent,
                data: {routeName: 'accident_person_add'}
              },
              {
                path: ':id/edit',
                component: AccidentPersonComponent,
                data: {routeName: 'accident_person_id'}
              },
              {
                path: ':id',
                component: AccidentPersonViewComponent,
                data: {routeName: 'accident_person_view'}
              },
            ]
          }
        ]
      },
    ]
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [
    RouterModule
  ]
})
export class CoreRoutingModule {
}
