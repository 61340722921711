import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ToastrMessageType, ToastrService } from '../../../core/services/toastr.service';
import { FormHelper } from '../../../core/services/form-helper.service';
import { TaskTypeService } from '../../../core/api/task-type.service';
import { RoleService } from '../../../core/api/role.service';
import { Role } from '../../../core/models/role.model';

@Component({
  selector: 'esomus-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.sass']
})
export class RoleComponent implements OnInit {
  role: Role;

  entityForm: FormGroup;
  fieldType = FieldType;
  taskTypeOptions: EntityOption;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private roleService: RoleService,
    private taskTypeService: TaskTypeService,
  ) {
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      description: [null, [Validators.required]],
      type: [null, [Validators.required]],
      taskType: [null]
    });
    this.taskTypeOptions = {get: () => this.taskTypeService.findAll(), propName: 'label'};

    const id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    if (!isNaN(id)) {
      this._getRole(id);
    } else {
      this.role = new Role();
    }
  }

  private _getRole(id: number) {
    this.roleService.find(id).subscribe((result: Role) => {
      this.role = result;

      FormHelper.initValues(this.role, this.entityForm);
      this.cd.detectChanges();
    });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    const entity = FormHelper.buildEntity(this.role, this.entityForm, {}) as Role;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      (this.role.id) ? this.roleService.put(entity) : this.roleService.post(entity),
      (result: Role) => {
        this.toastrService.open((this.role.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        this.routeNameService.goTo('roles');
      }
    );
  }

  getRoleDeleteURL() {
    return this.roleService.delete(this.role.id);
  }

  getRoleSuccessURL() {
    return this.routeNameService.path('roles');
  }
}
