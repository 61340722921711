import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { FormEvent, FormEventType, FormService } from 'src/app/shared/form-builder/services/form.service';
import { Subscription } from 'rxjs';
import { Entity } from 'src/app/core/models/entity.model';

@Component({
  selector: 'esomus-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.scss']
})
export class SubmitComponent implements OnInit, OnDestroy {

  @Input() label: string;
  @Input() invalid: boolean;
  @Input() submitted: boolean;
  @Input() btnBlock: boolean;

  @Input() submitformIDs: string[];
  @Output() submit: EventEmitter<Entity>;

  private subscriptions: Subscription;
  private formData: Entity[];

  constructor(
    private i18n: I18n,
    private formService: FormService
  ) {
    this.submitted = false;
    this.invalid = false;
    this.submitformIDs = [];
    this.submit = new EventEmitter<Entity>();
  }

  ngOnInit() {
    if (!this.label) {
      this.label = this.i18n({ value: 'Sauver', id: 'save' });
    }

    if (this.submitformIDs.length) {
      this.subscriptions = new Subscription();
      this.formData = [];
    }
  }

  ngOnDestroy(): void {
    if (this.submitformIDs.length) {
      this.subscriptions.unsubscribe();
    }
  }

  submitForms() {
    if (!this.submitformIDs.length) {
      this.submit.emit();
      return;
    }
    this.formData = [];

    for (const formID of this.submitformIDs) {
      this.subscriptions.add(
        this.formService.catchForm(formID, FormEventType.RESPONSE_DATA)
          .subscribe((event: FormEvent) => {
            if (event.data) {
              this.formData.push(event.data);
            }

            // merge data & emit event 'submit'
            if (this.formData.length === this.submitformIDs.length) {
              let entity = new Entity();
              for (const data of this.formData) {
                Object.assign(entity, data);
              }
              this.submit.emit(entity);
            }
          })
      );
      // trigger REQUEST_DATA
      this.formService.getFormData(formID);
    }
  }
}
