import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteComponent } from './pages/site/site.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../shared/material.module';
import { ItemModule } from '../item/item.module';
import { EquipmentsModule } from '../equipments/equipments.module';
import { SiteAddComponent } from './pages/site-add/site-add.component';
import { SiteViewComponent } from './pages/site-view/site-view.component';
import { DocumentsModule } from '../documents/documents.module';
import { FormsModule } from '../forms/forms.module';
import { ContactModule } from '../contact/contact.module';
import { DashboardModule } from '../dashboard/dashboard.module';

@NgModule({
  declarations: [SiteComponent, SiteAddComponent, SiteViewComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    ItemModule,
    EquipmentsModule,
    DocumentsModule,
    FormsModule,
    ContactModule,
    DashboardModule
  ]
})
export class SitesModule { }
