import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CasesRoutingModule } from './cases-routing.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CasesRoutingModule
  ]
})
export class CasesModule { }
