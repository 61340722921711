import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { Item } from '../../../core/models/item.model';
import { ColumnSortDirection, ColumnType, TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { SiteService } from '../../../core/api/site.service';
import { ItemDescriptionService } from '../../../core/api/item-description.service';
import { CategoryService } from '../../../core/api/category.service';
import { ItemStatusService } from '../../../core/api/item-status.service';
import { UserService } from '../../../core/api/user.service';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ToastrService } from '../../../core/services/toastr.service';
import { Entity } from '../../../core/models/entity.model';
import { EquipmentService } from '../../../core/api/equipment.service';
import { ItemDataService } from '../../../core/api/item-data.service';
import { AuthService } from '../../../core/api/auth.service';
import { RightEnum } from '../../../core/enum/right.enum';

@Component({
  selector: 'esomus-site-view',
  templateUrl: './site-view.component.html',
  styleUrls: ['./site-view.component.sass']
})
export class SiteViewComponent implements OnInit {

  entityForm: FormGroup;
  fieldType = FieldType;
  site: Item;

  buildingsTable: TableOptions;

  nbBuildings: number;

  breadcrumItemID: number;
  breadcrumbCurrent: string;

  addParam: object;

  showLocationBtns: boolean;
  showLocationConfigureBtns: boolean;
  showLocationManageBtns: boolean;
  showVehicleManageBtns: boolean;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private siteService: SiteService,
    private itemDescriptionService: ItemDescriptionService,
    private categoryService: CategoryService,
    private itemStatusService: ItemStatusService,
    private userService: UserService,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private equipmentService: EquipmentService,
    private itemDataService: ItemDataService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    const siteID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    this._getSite(siteID);
  }

  private _getSite(id: number) {
    this.siteService.find(id).subscribe((site: Item) => {
      this.authService.fetchPageRights({companyId: site.company.id, siteId: site.id}).subscribe(() => {
        this.showLocationBtns = this.authService.hasPageRight(RightEnum.LOCATION_IMPLEMENT);
        this.showLocationConfigureBtns = this.authService.hasPageRight(RightEnum.LOCATION_CONFIGURE);
        this.showLocationManageBtns = this.authService.hasPageRight(RightEnum.LOCATION_MANAGE);
        this.showVehicleManageBtns = this.authService.hasPageRight(RightEnum.VEHICULE_MANAGE);

        this.site = site;

        this.addParam = {siteID: this.site.id};

        this.breadcrumItemID = this.site.id;
        this.breadcrumbCurrent = null;

        this.buildingsTable = {
          columnDefs: [
            {
              name: this.i18n({value: 'Photo', id: 'picture'}),
              type: ColumnType.PICTURE, prop: 'picture', width: '10%'
            },
            {
              name: this.i18n({value: 'Nom', id: 'name'}),
              prop: 'label', width: '25%'
            },
            {
              name: this.i18n({value: 'Adresse', id: 'address'}),
              prop: 'address.line1', width: '25%'
            },
            {
              name: this.i18n({value: 'Commentaire', id: 'comment'}),
              prop: 'comment', width: '35%'
            },
          ],
          findDataCb: () => this.siteService.getAllBuildings(this.site.id),
          actions: {
            canCreate: this.showLocationConfigureBtns,
            create: [
              {
                btnName: this.i18n({value: 'Ajouter un bâtiment', id: 'btnAddBuilding'}),
                createCb: () => this.routeNameService.goTo('building_add', {siteID: this.site.id})
              },
            ],
            readCb: (entity: Entity) => this.routeNameService.goTo('building_view', {id: entity.id}),
            columnWidth: '5%'
          },
          defaultSortActive: 'label',
          defaultSortDirection: ColumnSortDirection.ASC
        };

        this.cd.detectChanges();
      });
    });
  }

  editSite() {
    this.routeNameService.goTo('site_id', {id: this.site.id});
  }

  updateNbBuildings(nbItems: number) {
    this.nbBuildings = nbItems;
  }

  getCategoryURL() {
    return this.routeNameService.path('category_view', {familyID: this.site.family.id, id: this.site.category.id});
  }

  getItemDescriptionURL() {
    return this.routeNameService.path('item_description_view', {id: this.site.itemDescription.id});
  }

  getFetchDataURL(formID: number) {
    return this.itemDataService.getData(this.site.id, formID);
  }
}
