import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryListComponent } from './components/category-list/category-list.component';
import { MaterialModule } from '../shared/material.module';
import { EsomusUiModule } from '../shared/esomus-ui/esomus-ui.module';
import { CategoryComponent } from './pages/category/category.component';
import { SharedModule } from '../shared/shared.module';
import { CategoryViewComponent } from './pages/category-view/category-view.component';
import { FormsModule } from '../forms/forms.module';
import { DocumentsModule } from '../documents/documents.module';
import { DashboardModule } from '../dashboard/dashboard.module';

@NgModule({
  declarations: [CategoryListComponent, CategoryComponent, CategoryViewComponent],
  exports: [
    CategoryListComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    EsomusUiModule,
    SharedModule,
    FormsModule,
    DocumentsModule,
    DashboardModule
  ]
})
export class CategoriesModule { }
