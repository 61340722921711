<mat-accordion multi="true">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <a class="link-header" [routerLink]="'families'| path">Famille</a>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container" *ngIf="family">
      <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayoutAlign="center center">
          <button *ngIf="showBtns" mat-flat-button (click)="editFamily()" color="success">
            <mat-icon>edit</mat-icon>
            Editer
          </button>
        </div>
        <esomus-dashboard-print-selector screenID="8"
                                         [itemID]="family.id"></esomus-dashboard-print-selector>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex="30" fxFlex.xs="100">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <esomus-img fxFlex="100" [picture]="family.picture"></esomus-img>
            <esomus-line-data fxFlex="100" boldFlex="50" textFlex="50"
                              boldValue="Code esomus"
                              [textValue]="family.code"></esomus-line-data>
          </div>
        </div>
        <div fxLayout="column" fxFlex>
          <div fxLayout="row wrap" fxLayoutAlign="start">
            <esomus-line-data fxFlex="100" flex="50" boldValue="Libellé"
                              [textValue]="family.label"></esomus-line-data>
            <esomus-line-data fxFlex="100" flex="50" boldValue="Thématique"
                              [textValue]="family.itemType ? family.itemType.label : null"></esomus-line-data>
            <esomus-line-data fxFlex="100" flex="50" boldValue="Commentaire"
                              [textValue]="family.comment"></esomus-line-data>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <esomus-category-list *ngIf="family" [family]="family"
                        [showBtns]="showBtns"></esomus-category-list>
</mat-accordion>
