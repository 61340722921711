import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ToastrService } from '../../../core/services/toastr.service';
import { AccidentService } from '../../../core/api/accident.service';
import { Accident } from '../../../core/models/accident.model';
import { FormHelper } from '../../../core/services/form-helper.service';
import { FieldType } from '../../../shared/form-builder/form-builder.component';

@Component({
  selector: 'esomus-accident-close',
  templateUrl: './accident-close.component.html',
  styleUrls: ['./accident-close.component.sass']
})
export class AccidentCloseComponent implements OnInit {
  accident: Accident;

  entityForm: FormGroup;
  fieldType = FieldType;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private accidentService: AccidentService,
  ) {
  }

  ngOnInit() {
    const id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    if (!isNaN(id)) {
      this._getAccident(id);
    }
  }

  private _getAccident(id: number) {
    this.accidentService.find(id).subscribe((result: Accident) => {
      this.accident = result;

      this.entityForm = this.fb.group({
        closedDate: [null, [Validators.required]],
        closedComment: [null]
      });

      FormHelper.initValues(this.accident, this.entityForm);
      this.cd.detectChanges();
    });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    const types = {
        closedDate: {type: FieldType.DATE}
    };
    const entity = FormHelper.buildEntity(this.accident, this.entityForm, types) as Accident;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      this.accidentService.closeAccident(this.accident.id, entity),
      (result: Accident) => {
        this.routeNameService.goTo('accident_view', {id: this.accident.id});
      }
    );
  }
}
