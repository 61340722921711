import { Entity } from './entity.model';
import { Picture } from './picture.model';
import { ItemLocation } from './item-location.model';
import { Obligation } from './obligation.model';

export class Person extends Entity {
  internalNumber1: string;
  internalNumber2: string;
  firstName: string;
  lastName: string;
  fullName: string;

  title: string;
  initial: string;
  phone: string;
  fax: string;
  gsm: string;
  mail: string;
  intern: boolean;
  comment: string;

  active: boolean;
  picture: Picture;
  type: number;

  companies: Array<Person>;
  personLocation: ItemLocation;
  obligations: Array<Obligation>;
}
