import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { SidenavMenu } from './menu.provider';
import { SidenavItem } from './sidenav-item/sidenav-item.model';
import { SidenavService } from './sidenav.service';

@Component({
  selector: 'esomus-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy, OnChanges {

  @Input() collapsed: boolean;
  @Output() toggledSidenavCollapse = new EventEmitter();

  sidenavItems: SidenavMenu;
  currentlyOpen$: Observable<SidenavItem[]>;

  constructor(
    private sidenavService: SidenavService
  ) {
  }

  ngOnInit() {
    this.sidenavService.generateSidenavMenu();
    this.sidenavItems = this.sidenavService.getSidenavMenu();
  }

  toggleSidenavCollapse() {
    this.toggledSidenavCollapse.emit();
  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.collapsed && !changes.collapsed.firstChange) {
      localStorage.setItem('sidenavCollapsed', (this.collapsed) ? '1' : '0');
    }
  }
}

