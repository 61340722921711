import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemBaseFormComponent } from './components/item-base-form/item-base-form.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../shared/material.module';

@NgModule({
  declarations: [ItemBaseFormComponent],
  exports: [
    ItemBaseFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule
  ]
})
export class ItemModule { }
