import { ChangeDetectorRef, Component, EventEmitter, OnInit } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TaskService } from '../../../core/api/task.service';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ToastrService } from '../../../core/services/toastr.service';
import { Task } from '../../../core/models/task.model';
import { ColumnType, TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { of } from 'rxjs';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { PersonService } from '../../../core/api/person.service';
import { TaskAssignment } from '../../../core/models/task-assignment.model';
import { TaskAssignmentService } from '../../../core/api/task-assignment.service';
import { FormHelper } from '../../../core/services/form-helper.service';
import { Person } from '../../../core/models/person.model';
import { Entity } from '../../../core/models/entity.model';

@Component({
  selector: 'esomus-task-planification',
  templateUrl: './task-planification.component.html',
  styleUrls: ['./task-planification.component.sass']
})
export class TaskPlanificationComponent implements OnInit {

  task: Task;
  scopeTable: TableOptions;
  entityForm: FormGroup;
  fieldType = FieldType;
  personOptions: EntityOption;
  planifications: Array<TaskAssignment>;
  planificationTable: TableOptions;
  tableEmitter: EventEmitter<any> = new EventEmitter<any>();

  private persons: Array<Person>;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private taskService: TaskService,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private personService: PersonService,
    private taskAssignmentService: TaskAssignmentService
  ) {
  }

  ngOnInit() {
    const taskID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    if (!isNaN(taskID)) {
      this._getTask(taskID);
    }
  }

  private _getTask(taskID: number) {
    this.taskService.find(taskID).subscribe((result: Task) => {
      this.task = result;

      this.entityForm = this.fb.group({
        startDate: [null, Validators.required],
        endDate: [null, Validators.required],
        comment: [null],
        person: [null, Validators.required]
      });

      this.personService.findAll('', {origin: 'planification'}).subscribe((persons: Array<Person>) => {
        this.persons = persons;

        this.personOptions = {get: () => of(this.persons), propName: 'fullName'};

        this.cd.detectChanges();
      });

      this.scopeTable = {
        columnDefs: [
          {
            prop: 'fullWhat',
            name: this.i18n({value: 'Où', id: 'where'})
          },
          {
            prop: 'fullWhere',
            name: this.i18n({value: 'Quoi', id: 'what'})
          },
          {
            prop: 'status',
            name: this.i18n({value: 'Situation', id: 'situation'})
          },
          {
            prop: 'count',
            name: this.i18n({value: 'Tot.', id: 'tot.'})
          },
        ],
        findDataCb: (searchData: Params) => of(this.task.taskScopes),
        actions: null
      };

      this.taskAssignmentService.findAllByTask(taskID).subscribe((assigments: Array<TaskAssignment>) => {
        this.planifications = assigments;

        this.planificationTable = {
          columnDefs: [
            {
              name: this.i18n({value: 'Nom', id: 'name'}),
              prop: 'person.fullName'
            },
            {
              name: this.i18n({value: 'Rôle', id: 'role'}),
              prop: 'person.role.label'
            },
            {
              name: this.i18n({value: 'Commentaire', id: 'comment'}),
              prop: 'comment',
            },
            {
              name: this.i18n({value: 'De', id: 'from'}),
              prop: 'startDate', type: ColumnType.DATE
            },
            {
              name: this.i18n({value: 'à', id: 'to'}),
              prop: 'endDate', type: ColumnType.DATE
            },
            {
              name: this.i18n({value: 'RDV', id: 'rdv'}),
              prop: 'empty1'
            },
            {
              name: null,
              prop: 'empty2'
            },
            {
              name: this.i18n({value: 'IE', id: 'ie'}),
              prop: 'empty3'
            },
          ],
          findDataCb: () => of(this.planifications),
        };

        this.cd.detectChanges();
      });

      this.cd.detectChanges();
    });
  }

  addPlanification() {
    if (this.entityForm.invalid) {
      return false;
    }

    let assignment = new TaskAssignment();
    assignment = FormHelper.buildEntity(assignment, this.entityForm, {
      startDate: {type: FieldType.DATE},
      endDate: {type: FieldType.DATE},
    }) as TaskAssignment;

    const personID = assignment.person as any;
    assignment.person = this.persons.find((person: Person) => {
      return person.id === personID;
    });

    this.planifications.push(assignment);

    this.cd.detectChanges();

    this.tableEmitter.emit();
  }

  submit() {
    let assignmentsSended = 0;
    let assignmentsCompleted = 0;
    for (let assignment of this.planifications) {
      if (assignment.id) {
        continue;
      }

      assignmentsSended++;

      assignment['person'] = (assignment.person.id) as any;

      this.taskAssignmentService.postFromParent(this.task, assignment).subscribe((result: Entity) => {
        assignmentsCompleted++;

        if (assignmentsCompleted === assignmentsSended) {
          this.taskService.generateAction(this.task).subscribe(() => {
            this.routeNameService.goTo('task_view', {id: this.task.id}, {scroll: 'actions'});
          });
        }
      });
    }
  }
}
