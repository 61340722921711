<mat-accordion multi="true" *ngIf="site">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Site : {{site.label}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
        <esomus-line-membership fxFlex="100" [item]="site"
                                [showAll]="false"></esomus-line-membership>
        <div fxLayoutAlign="center center">
          <button *ngIf="showLocationBtns" mat-flat-button (click)="editSite()" color="success">
            <mat-icon>edit</mat-icon>
            Editer
          </button>
        </div>
        <esomus-dashboard-print-selector screenID="2"
                                         [itemID]="site.id"></esomus-dashboard-print-selector>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex="30" fxFlex.xs="100">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <esomus-img fxFlex="100" [picture]="site.picture"></esomus-img>
            <esomus-line-data fxFlex="100" boldFlex="50" textFlex="50"
                              boldValue="Code esomus"
                              [textValue]="site.code"></esomus-line-data>
          </div>
        </div>
        <div fxLayout="column" fxFlex>
          <div fxLayout="row wrap" fxLayoutAlign="start">
            <esomus-line-data flex="50" fxFlex="100" boldValue="Libellé"
                              [textValue]="site.description"></esomus-line-data>
            <esomus-line-data flex="50" fxFlex="100" boldValue="Pays"
                              [textValue]="site.address.country"></esomus-line-data>
            <esomus-line-data flex="100" fxFlex="50" boldValue="Localité"
                              [textValue]="site.address.locality"></esomus-line-data>
            <esomus-line-data flex="100" fxFlex="50" boldValue="CP"
                              [textValue]="site.address.zipCode"></esomus-line-data>
            <esomus-line-data flex="50" fxFlex="100" boldValue="Addresse"
                              [textValue]="site.address.line1"></esomus-line-data>
            <esomus-line-data flex="50" fxFlex="100" boldValue="Coordonées"
                              [textValue]="site.address.coordinates"></esomus-line-data>
            <esomus-line-data flex="50" fxFlex="100" boldValue="Commentaire"
                              [textValue]="site.comment"></esomus-line-data>
            <esomus-line-data flex="50" fxFlex="100" boldValue="Catégorie"
                              [textValue]="site.category.label"
                              [textURL]="getCategoryURL()"></esomus-line-data>
            <esomus-line-data flex="50" fxFlex="100" boldValue="Fiche"
                              [textValue]="site.itemDescription.label"
                              [textURL]="getItemDescriptionURL()"></esomus-line-data>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Bâtiment : {{nbBuildings}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <esomus-table *ngIf="buildingsTable" (getLength)="updateNbBuildings($event)"
                  [options]="buildingsTable"></esomus-table>
  </mat-expansion-panel>
  <mat-expansion-panel *ngIf="site.category.form || site.itemDescription.form">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Information complémentaire
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <esomus-form-display *ngIf="site.category.form"
                               [fetchDataURL]="getFetchDataURL(site.category.form.id)"></esomus-form-display>
        </div>
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <esomus-form-display *ngIf="site.itemDescription.form"
                               [fetchDataURL]="getFetchDataURL(site.itemDescription.form.id)"></esomus-form-display>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <esomus-document-table [showBtns]="showLocationManageBtns" [parent]="site"
                         parentType="item"></esomus-document-table>
  <esomus-contact-table [showBtns]="showLocationManageBtns" [showActions]="showLocationManageBtns"
                        [parent]="site" [parentType]="'item'"></esomus-contact-table>
  <esomus-equipment-table [showBtns]="showVehicleManageBtns" parent="site" [parentID]="site.id"
                          [addParam]="addParam" [expanded]="false" addLabel="Ajouter un véhicule"
                          label="Véhicules"></esomus-equipment-table>
</mat-accordion>
