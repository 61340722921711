import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { ItemDescription } from '../models/item-description.model';
import { environment } from '../../../environments/environment';
import { Params } from '@angular/router';

const crudPath: CRUDPath = {
  many: `/items_description/`,
  single: (id: number) => `/items_description/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class ItemDescriptionService extends CRUD<ItemDescription>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  findAllCompanies() {
    return this.http.get<Array<ItemDescription>>(`${environment.api.url}${this.crudPath.many}companies`);
  }

  findAllSites() {
    return this.http.get<Array<ItemDescription>>(`${environment.api.url}${this.crudPath.many}sites`);
  }

  findAllBuildings() {
    return this.http.get<Array<ItemDescription>>(`${environment.api.url}${this.crudPath.many}buildings`);
  }

  findAllLocals() {
    return this.http.get<Array<ItemDescription>>(`${environment.api.url}${this.crudPath.many}locals`);
  }

  findAllEquipments() {
    return this.http.get<Array<ItemDescription>>(`${environment.api.url}${this.crudPath.many}equipments`);
  }

  findByCategory(categoryID: number, params: Params = {}) {
    return this.http.get<Array<ItemDescription>>(`${environment.api.url}${this.crudPath.many}categories/${categoryID}`, params);
  }
}
