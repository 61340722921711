import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchViewComponent } from './pages/search-view/search-view.component';
import { DashboardModule } from '../dashboard/dashboard.module';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [SearchViewComponent],
  imports: [
    CommonModule,
    DashboardModule,
    SharedModule
  ]
})
export class SearchModule { }
