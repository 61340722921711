import { Entity } from './entity.model';
import { Person } from './person.model';
import { Task } from './task.model';

export class Programming extends Entity {
  description: string;
  pivotDate: string;
  unit: number;
  frequence: number;
  forceDay: boolean;
  anticipation: number;
  task: Task;
  person: Person;
}
