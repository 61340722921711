import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[esomusAutoScroll]'
})
export class AutoScrollDirective implements AfterContentInit {
  @Input() public esomusAutoScroll: boolean;

  public constructor(private el: ElementRef) {
  }

  public ngAfterContentInit() {
    if (this.esomusAutoScroll === true) {
      setTimeout(() => {
        this.el.nativeElement.scrollIntoView({
          behavior: 'smooth'
        });
      }, 500);
    }
  }
}
