import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FieldType } from '../../form-builder.component';
import { FormField, FormFieldOptions } from '../form-field';
import { toDateInput } from 'src/app/core/utils.function';

@Component({
  selector: 'esomus-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent extends FormField implements OnInit {

  public fieldType = FieldType;

  @Input() type: FieldType.TEXT | FieldType.NUMBER | FieldType.PASSWORD | FieldType.EMAIL | FieldType.DATE = FieldType.TEXT;
  @Input() autoComplete: string;

  @Input() maxDate: string;

  constructor(private el: ElementRef) {
    super();
  }

  ngOnInit(): void {
    if (!this.autoComplete) {
      this.autoComplete = 'off';
    }
    if (!this.value) {
      this.value = this.control.value;
    }
    if (this.type === FieldType.DATE) {
      if (this.value) {
        this.value = toDateInput(new Date(this.value));
      }
    }
  }
}

/**
 * Basic <input>
 */
export class InputField extends FormFieldOptions {
  type: FieldType.TEXT | FieldType.PASSWORD | FieldType.EMAIL | FieldType.NUMBER | FieldType.DATE | FieldType.TIME;
}
