import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FieldType as FormFieldType } from '../../../shared/form-builder/form-builder.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrMessageType, ToastrService } from '../../../core/services/toastr.service';
import { FormHelper } from '../../../core/services/form-helper.service';
import { DocumentTypeService } from '../../../core/api/document-type.service';
import { DocumentType } from '../../../core/models/document-type.model';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { of } from 'rxjs';
import { DocumentTypeHelper } from '../../../core/services/document-type-helper.service';

@Component({
  selector: 'esomus-document-type',
  templateUrl: './document-type.component.html',
  styleUrls: ['./document-type.component.sass']
})
export class DocumentTypeComponent implements OnInit {
  entityForm: FormGroup;
  formFieldType = FormFieldType;
  documentType: DocumentType;

  usageTypeOptions: EntityOption;
  typeOptions: EntityOption;

  constructor(
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private documentTypeService: DocumentTypeService,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((routeParams) => {
      this.entityForm = this.fb.group({
        label: [null],
        group: [null],
        duration: [null],
        unique: [null],
        legal: [null],
        type: [null],
        usageType: [null],
      });

      this.usageTypeOptions = {get: () => of(DocumentTypeHelper.getUsageTypes()), propName: 'label'};
      this.typeOptions = {get: () => of(DocumentTypeHelper.getTypes()), propName: 'label'};

      const documentTypeID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);
      if (!isNaN(documentTypeID)) {
        this._getDocumentType(documentTypeID);
      } else {
        this.documentType = new DocumentType();
      }
    });
  }

  private _getDocumentType(documentTypeID: number) {
    this.documentTypeService.find(documentTypeID)
      .subscribe((documentType: DocumentType) => {
        this.documentType = documentType;
        FormHelper.initValues(this.documentType, this.entityForm);

        this.cd.detectChanges();
      });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }
    let entity = FormHelper.buildEntity(this.documentType, this.entityForm, {}) as DocumentType;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      (entity.id) ? this.documentTypeService.put(entity) : this.documentTypeService.post(entity),
      (result: DocumentType) => {
        this.entityForm.reset();
        this.toastrService.open((entity.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        this.routeNameService.goTo('document_types');
      }
    );
  }

  getDocumentTypeDeleteURL() {
    return this.documentTypeService.delete(this.documentType.id);
  }

  getDocumentTypeSuccessURL() {
    return this.routeNameService.path('document_types');
  }
}
