<ng-template #formModal>
  <form>
    <h3 mat-dialog-title>
      <span>Création équipement personnel</span>
    </h3>
    <div mat-dialog-content style="width: 640px; max-width: 640px;">
      <div fxLayout="row wrap" fxLayoutAlign="start center">
        <esomus-ng-select *ngIf="itemTypeOptions" [options]="itemTypeOptions"
                          [control]="entityForm.get('itemType')"
                          i18n-label="@@itemType" label="Type" fxFlex="100"
                          (valueChange)="changeItemType()"
                          flex="100"></esomus-ng-select>
        <esomus-ng-select *ngIf="familyOptions" [options]="familyOptions"
                          [control]="entityForm.get('family')"
                          i18n-label="@@family" label="Famille" fxFlex="100"
                          (valueChange)="updateFamily()"
                          flex="100"></esomus-ng-select>
        <esomus-ng-select *ngIf="categoryOptions" [options]="categoryOptions"
                          [control]="entityForm.get('category')"
                          i18n-label="@@category" label="Catégorie" fxFlex="100"
                          (valueChange)="updateCategory()"
                          flex="100"></esomus-ng-select>
        <esomus-ng-select *ngIf="itemDescriptionOptions" [options]="itemDescriptionOptions"
                          [control]="entityForm.get('itemDescription')"
                          i18n-label="@@itemDescription" label="Fiche" fxFlex="100"
                          flex="100"></esomus-ng-select>
        <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('description')"
                      i18n-label="@@name" label="Nom" flex="100"
                      fxFlex="100"></esomus-input>
      </div>
    </div>
    <div mat-dialog-actions>
      <esomus-submit (submit)="confirm()"></esomus-submit>
      <button type="button" mat-stroked-button i18n="@@cancel" (click)="closeModal()">
        Annuler
      </button>
    </div>
  </form>
</ng-template>
