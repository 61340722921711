import { Params } from '@angular/router';

export class UserHelper {

  constructor() {
  }

  static getLanguageOptions() {
    return [
      {id: 0, label: 'fr'}, {id: 1, label: 'nl'}, {id: 2, label: 'en'}
    ];
  }

  static getLanguageLabel(language: number) {
    let value = this.getLanguageOptions().find((item: Params) => item.id === language);

    if (value) {
      return value.label;
    }

    return null;
  }
}
