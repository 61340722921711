<mat-accordion multi="true" *ngIf="task" class="mat-edit">
  <esomus-task-panel [showBtns]="false" [task]="task"></esomus-task-panel>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Portée
      </mat-panel-title>
    </mat-expansion-panel-header>
    <esomus-table *ngIf="scopeTable" [options]="scopeTable"></esomus-table>
  </mat-expansion-panel>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Planification
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px grid">
        <esomus-ng-select *ngIf="personOptions" [options]="personOptions"
                          [control]="entityForm.get('person')"
                          i18n-label="@@assignedTo" label="Attribué à" fxFlex="100"
                          flex="100"></esomus-ng-select>
        <esomus-input [type]="fieldType.DATE" [control]="entityForm.get('startDate')"
                      i18n-label="@@startDate" label="De" flex="100"
                      fxFlex="50"></esomus-input>
        <esomus-input [type]="fieldType.DATE" [control]="entityForm.get('endDate')"
                      i18n-label="@@endDate" label="à" flex="100"
                      fxFlex="50"></esomus-input>
        <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('comment')"
                      i18n-label="@@description" label="Description" flex="100"
                      fxFlex="75"></esomus-input>
        <div class="btn-group-right">
          <esomus-submit (submit)="addPlanification()" label="Attribué" fxFlex="25"></esomus-submit>
        </div>
      </div>
    </div>
    <esomus-table *ngIf="planificationTable" [options]="planificationTable" [searchReceiver]="tableEmitter"></esomus-table>
  </mat-expansion-panel>
  <div class="btn-group-right">
    <esomus-back-button></esomus-back-button>
    <esomus-submit (submit)="submit()"></esomus-submit>
  </div>
</mat-accordion>
