import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { CRUD, CRUDPath } from './crud';

const crudPath: CRUDPath = {
  many: `/users/`,
  single: (id: number) => `/users/${id}`,
  filter: `/users/filter`
};

@Injectable({
  providedIn: 'root'
})
export class UserService extends CRUD<User> {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }
}
