import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemLocationFormComponent } from './components/item-location-form/item-location-form.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [ItemLocationFormComponent],
  exports: [
    ItemLocationFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class ItemLocationsModule { }
