<mat-expansion-panel *ngIf="documentsTable">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{label ? label : 'Document'}} : {{nbDocuments}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <esomus-table *ngIf="documentsTable" (getLength)="updateNbDocuments($event)"
                [options]="documentsTable" [searchReceiver]="documentTableEmitter"></esomus-table>
  <esomus-document-link *ngIf="showDocumentModal" [documentTableEmitter]="documentTableEmitter" [parent]="parent" [parentType]="parentType"></esomus-document-link>
</mat-expansion-panel>

<esomus-document-version *ngIf="currentDocumentID" [previousID]="currentDocumentID"
                         [documentTableEmitter]="documentTableEmitter"></esomus-document-version>

<ng-template #originCell let-row>
  <ng-container [ngSwitch]="row.origin">
    <span *ngSwitchCase="0">Catégorie</span>
    <span *ngSwitchCase="1">Fiche</span>
    <span *ngSwitchCase="2">Equipement</span>
    <span *ngSwitchCase="4"><a [routerLink]="'anomaly_view'|path:{id: row.originID}">{{ row.description }}</a></span>
    <span *ngSwitchCase="5"><a [routerLink]="'task_view'|path:{id: row.originID}">{{ row.description }}</a></span>
    <span *ngSwitchCase="6"><a [routerLink]="'action_view'|path:{id: row.originID}">{{ row.description }}</a></span>
  </ng-container>
</ng-template>

<ng-template #companySiteCell let-row>
  <span *ngIf="row.company">
    {{row.company.name}}<span *ngIf="row.site"> / {{row.site.name}}</span>
  </span>
</ng-template>

<ng-template #removeModal>
  <form>
    <h3 mat-dialog-title>
      <span>Valider la suppression</span>
    </h3>
    <div mat-dialog-content>
    </div>
    <div mat-dialog-actions>
      <esomus-submit label="Confirmer" (submit)="confirmRemove()"></esomus-submit>
      <button type="button" mat-stroked-button i18n="@@cancel" (click)="closeRemoveModal()">
        Annuler
      </button>
    </div>
  </form>
</ng-template>
