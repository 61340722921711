import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../shared/material.module';
import { ItemModule } from '../item/item.module';
import { LocalComponent } from './pages/local/local.component';
import { SharedModule } from '../shared/shared.module';
import { EquipmentsModule } from '../equipments/equipments.module';
import { LocalViewComponent } from './pages/local-view/local-view.component';
import { LocalAddComponent } from './pages/local-add/local-add.component';
import { FormsModule } from '../forms/forms.module';
import { DocumentsModule } from '../documents/documents.module';
import { ContactModule } from '../contact/contact.module';
import { DashboardModule } from '../dashboard/dashboard.module';

@NgModule({
  declarations: [LocalComponent, LocalViewComponent, LocalAddComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ItemModule,
    SharedModule,
    EquipmentsModule,
    FormsModule,
    DocumentsModule,
    ContactModule,
    DashboardModule
  ]
})
export class LocalsModule { }
