<ng-container *ngIf="data">
  <ng-container *ngFor="let item of data">
    <ng-container *ngIf="item.value">
      <ng-container [ngSwitch]="item.type">
        <esomus-line-data *ngSwitchCase="'calendar'" [boldValue]="item.fieldName" [textValue]="item.realValue" textType="calendar"></esomus-line-data>
        <esomus-line-data *ngSwitchDefault [boldValue]="item.fieldName" [textValue]="item.realValue"></esomus-line-data>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
