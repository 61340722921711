import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { Item } from '../models/item.model';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import { LightItem } from '../models/light-item.model';

const crudPath: CRUDPath = {
  many: `/buildings/`,
  single: (id: number) => `/buildings/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class BuildingService extends CRUD<Item>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  postFromParent(entity: Item, parentID: number): Observable<Item> {
    const body = this._createFormData(entity);
    return this.http.post<Item>(`${environment.api.url}${this.crudPath.single(parentID)}`, body);
  }

  getAllLocals(buildingID: number, params?: Params) {
    return this.http.get<Array<Item>>(`${environment.api.url}${this.crudPath.single(buildingID)}/locals`, params);
  }

  findAllByTask(taskID: number, params?: Params): Observable<Array<LightItem>> {
    return this.http.get<Array<LightItem>>(`${environment.api.url}/tasks/${taskID}${this.crudPath.many}`, params);
  }
}
