import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ToastrMessageType, ToastrService } from '../../../core/services/toastr.service';
import { FormHelper } from '../../../core/services/form-helper.service';
import { ObligationService } from '../../../core/api/obligation.service';
import { Obligation } from '../../../core/models/obligation.model';
import { ObligationTypeService } from '../../../core/api/obligation-type.service';
import { ObligationType } from '../../../core/models/obligation-type.model';
import { of } from 'rxjs';
import { CategoryService } from '../../../core/api/category.service';

@Component({
  selector: 'esomus-obligation',
  templateUrl: './obligation.component.html',
  styleUrls: ['./obligation.component.sass']
})
export class ObligationComponent implements OnInit {
  obligation: Obligation;

  entityForm: FormGroup;
  fieldType = FieldType;
  formOptions: EntityOption;

  obligationTypes: Array<ObligationType>;
  obligationTypeOptions: EntityOption;
  categoryOptions: EntityOption;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private obligationService: ObligationService,
    private obligationTypeService: ObligationTypeService,
    private categoryService: CategoryService
  ) {
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      label: [null, [Validators.required]],
      required: [null],
      negation: [null],
    });

    const id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    if (!isNaN(id)) {
      this._getObligation(id);
    } else {
      this.obligation = new Obligation();
      this._updateForm();
    }
  }

  private _getObligation(id: number) {
    this.obligationService.find(id).subscribe((result: Obligation) => {
      this.obligation = result;

      this._updateForm();

      FormHelper.initValues(this.obligation, this.entityForm);
    });
  }

  private _updateForm() {
    this.obligationTypeService.findAll().subscribe((data: Array<ObligationType>) => {
      let typeID = this.obligation.obligationType ? this.obligation.obligationType.id : null;
      this.obligationTypes = data;
      this.obligationTypeOptions = {get: () => of(this.obligationTypes), propName: 'label'};
      this.entityForm.addControl('obligationType', this.fb.control(typeID, [Validators.required]));
      this.cd.detectChanges();
    });
  }

  updateCategory() {
    let value = parseInt(this.entityForm.get('obligationType').value, 10);
    let obligationType = this.obligationTypes.find((item: ObligationType) => item.id === value);

    if (obligationType === null || obligationType.code !== 3) {
      if (this.entityForm.get('category')) {
        this.entityForm.removeControl('category');
        this.categoryOptions = null;
      }
    } else {
      if (!this.entityForm.get('category')) {
        this.entityForm.addControl('category',
          this.fb.control(this.obligation.category ? this.obligation.category.id : null, [Validators.required])
        );
        this.categoryOptions = {get: () => this.categoryService.findAllEquipments(), propName: 'label'};
      }
    }

    this.cd.detectChanges();
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    const entity = FormHelper.buildEntity(this.obligation, this.entityForm, {}) as Obligation;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      (this.obligation.id) ? this.obligationService.put(entity) : this.obligationService.post(entity),
      (result: Obligation) => {
        this.toastrService.open((this.obligation.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        this.routeNameService.goTo('obligations');
      }
    );
  }

  getObligationDeleteURL() {
    return this.obligationService.delete(this.obligation.id);
  }

  getObligationSuccessURL() {
    return this.routeNameService.path('obligation_types');
  }
}
