<mat-expansion-panel [expanded]="expanded">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Anomalie
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="container">
    <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
      <div fxLayoutAlign="center center">
        <button mat-flat-button (click)="editAnomaly()" color="success">
          <mat-icon>edit</mat-icon> Editer
        </button>
      </div>
      <esomus-dashboard-print-selector [visible]="fromAnomaly" screenID="18" [itemID]="anomaly.id"></esomus-dashboard-print-selector>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start">
      <div fxLayout="column" fxFlex="30" fxFlex.xs="100">
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <esomus-img fxFlex="100" [picture]="anomaly.picture"></esomus-img>
        </div>
      </div>
      <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <esomus-line-data fxFlex="100" boldValue="Type d'anomalie"
                                [textValue]="anomaly.anomalyType.label"></esomus-line-data>
              <esomus-line-data fxFlex="100" boldValue="Description"
                                [textValue]="anomaly.description"></esomus-line-data>
              <esomus-line-data fxFlex="100" boldValue="Commentaire"
                                [textValue]="anomaly.comment"></esomus-line-data>
            </div>
          </div>
          <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <esomus-line-data fxFlex="100" boldValue="Gravité"
                                [textValue]="getSeverityLabel()"></esomus-line-data>
              <esomus-line-data fxFlex="100" boldValue="Urgence"
                                [textValue]="getEmergencyLabel()"></esomus-line-data>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
            <esomus-line-data fxFlex="100" boldValue="Signalé par"
                              [textValue]="anomaly.person ? anomaly.person.fullName : null"></esomus-line-data>
            <esomus-line-data fxFlex="100" boldValue="Résolu le" [textType]="anomaly.resolutionDate ? 'date' : null"
                              [textValue]="anomaly.resolutionDate ? anomaly.resolutionDate : '__/__/____'"></esomus-line-data>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex="20" fxFlex.xs="100">
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <esomus-info-box fxFlex="100" [line1]="getStatus()" [date1]="anomaly.deadlineDate" color="warning"></esomus-info-box>
        </div>
      </div>
    </div>
  </div>
</mat-expansion-panel>
