import { AbstractHelper } from './abstract-helper';

export class AccidentHelper extends AbstractHelper {
  static getSeverityOptions() {
    return [
      {id: 0, label: 'Pas d\'arrêt'}, {id: 1, label: 'Arrêt'}, {id: 2, label: 'Décès'},
    ];
  }

  static getSeverityLabel(type: number) {
    return this._getLabel(type, this.getSeverityOptions());
  }

  static getAccidentStatusOptions() {
    return [
      {id: 0, label: 'Prise en charge', color: 'primary'},
      {id: 1, label: 'En cours', color: 'warning'},
      {id: 2, label: 'Clôturé', color: 'success'},
    ];
  }

  static getAccidentStatusLabel(type: number) {
    return this._getLabel(type, this.getAccidentStatusOptions());
  }

  static getAccidentStatusColor(type: number) {
    return this._getColor(type, this.getAccidentStatusOptions());
  }

  static getAccidentStageOptions() {
    return [
      {id: 1, label: 'Incident'}, {id: 2, label: 'Presqu\'accident'}, {id: 3, label: 'Accident'}, {id: 4, label: 'Accident mortel'},
    ];
  }

  static getAccidentStageLabel(type: number) {
    return this._getLabel(type, this.getAccidentStageOptions());
  }

  static getAccidentLinkOptions() {
    return [
      {id: 0, label: 'Equipement'}, {id: 1, label: 'Anomalie'}, {id: 2, label: 'Action'}, {id: 3, label: 'Accident'},
    ];
  }

  static getAccidentLinkLabel(type: number) {
    return this._getLabel(type, this.getAccidentLinkOptions());
  }
}
