import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponentListComponent } from './pages/list-component-list/list-component-list.component';
import { ListComponentComponent } from './pages/list-component/list-component.component';
import { SharedModule } from '../shared/shared.module';
import { DashboardModule } from '../dashboard/dashboard.module';

@NgModule({
  declarations: [ListComponentListComponent, ListComponentComponent],
  imports: [
    CommonModule,
    SharedModule,
    DashboardModule
  ]
})
export class ListComponentsModule { }
