import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TaskType } from '../models/task-type.model';

const crudPath: CRUDPath = {
  many: `/task_types/`,
  single: (id: number) => `/task_types/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class TaskTypeService extends CRUD<TaskType>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  findAllStd(): Observable<Array<TaskType>> {
    return this.http.get<Array<TaskType>>(`${environment.api.url}${this.crudPath.many}std`);
  }

  findAllAnomaly(): Observable<Array<TaskType>> {
    return this.http.get<Array<TaskType>>(`${environment.api.url}${this.crudPath.many}anomaly`);
  }
}
