<mat-form-field appearance="outline" [class.hidden]="!visible" floatLabel="always" fxFlex.xs="100"
                [fxFlex]="flex">
  <mat-label [class.required]="required">{{label}}</mat-label>
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="fieldType.DATE">
      <input #input [esomusAutoFocus]="autofocus" matInput [autocomplete]="autoComplete"
             [type]="'text'"
             [formControl]="control" [required]="required" [value]="value" [max]="maxDate"
             [owlDateTime]="datepicker" [owlDateTimeTrigger]="datepicker"
      >
      <owl-date-time #datepicker [pickerType]="'calendar'"></owl-date-time>
    </ng-container>
    <ng-container *ngSwitchCase="fieldType.DATETIME">
      <input #input [esomusAutoFocus]="autofocus" matInput [autocomplete]="autoComplete"
             [type]="'text'"
             [formControl]="control" [required]="required" [value]="value" [max]="maxDate"
             [owlDateTime]="datetimepicker" [owlDateTimeTrigger]="datetimepicker"
      >
      <owl-date-time #datetimepicker [pickerType]="'both'"></owl-date-time>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <input #input [esomusAutoFocus]="autofocus" matInput [autocomplete]="autoComplete"
             [type]="type"
             [formControl]="control" [required]="required" [value]="value">
    </ng-container>
  </ng-container>
  <esomus-input-error [control]="control" [required]="required"></esomus-input-error>
</mat-form-field>
