<mat-accordion multi="true" *ngIf="programming">
  <mat-expansion-panel [expanded]="true" *ngIf="programming">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Programmation : {{programming.description}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayoutAlign="center center">
          <button mat-flat-button (click)="editProgramming()" color="success">
            <mat-icon>edit</mat-icon> Editer
          </button>
        </div>
        <esomus-dashboard-print-selector screenID="22" [itemID]="programming.id"></esomus-dashboard-print-selector>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex="40" fxFlex.lt-md="100">
          <div fxLayout="row wrap" fxLayoutAlign="start">
            <esomus-line-data fxFlex="100" flex="100" boldValue="Description"
                              [textValue]="programming.description"></esomus-line-data>
            <esomus-line-data fxFlex="100" flex="100" boldValue="Défini par"
                              [textValue]="programming.person ? programming.person.fullName : null"></esomus-line-data>
            <esomus-line-data fxFlex="100" flex="100" boldValue="Date pivot"
                              [textValue]="programming.pivotDate"
                              textType="date"></esomus-line-data>
          </div>
        </div>
        <div fxLayout="column" fxFlex="40" fxFlex.lt-md="100">
          <div fxLayout="row wrap" fxLayoutAlign="start">
            <esomus-line-data fxFlex="100" flex="100" boldValue="Fréquence"
                              [textValue]="getFrequence()"></esomus-line-data>
            <esomus-line-data fxFlex="100" flex="100" boldValue="Force le jour"
                              [boolValue]="programming.forceDay"></esomus-line-data>
            <esomus-line-data fxFlex="100" flex="100" boldValue="Période d'anticipation"
                              [textValue]="programming.anticipation.toString()"></esomus-line-data>
          </div>
        </div>
        <div fxLayout="column" fxFlex="20" fxFlex.lt-md="100">
          <div fxLayout="row wrap" fxLayoutAlign="start">

          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <esomus-task-panel [fromProgramming]="true" [task]="programming.task"></esomus-task-panel>
</mat-accordion>
