import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { Accident } from '../models/accident.model';
import { environment } from '../../../environments/environment';

const crudPath: CRUDPath = {
  many: `/accidents/`,
  single: (id: number) => `/accidents/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class AccidentService extends CRUD<Accident> {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  findByCompany(companyID: number) {
    return this.http.get<Array<Accident>>(`${environment.api.url}${this.crudPath.many}companies/${companyID}`);
  }

  closeAccident(accidentID: number, accident: Accident) {
    const body = this._createFormData(accident);
    body.set('_method', 'PUT');

    return this.http.put<Accident>(`${environment.api.url}${this.crudPath.single(accidentID)}/close`, body);
  }
}
