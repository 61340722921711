import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Accident } from '../../../core/models/accident.model';
import { ColumnSortDirection, TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { Params } from '@angular/router';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RouteNameService } from '../../../core/services/route-name.service';
import { FormBuilder } from '@angular/forms';
import { AccidentPersonService } from '../../../core/api/accident-person.service';
import { Person } from '../../../core/models/person.model';
import { AccidentPerson } from '../../../core/models/accident-person.model';

@Component({
  selector: 'esomus-accident-person-panel',
  templateUrl: './accident-person-panel.component.html',
  styleUrls: ['./accident-person-panel.component.sass']
})
export class AccidentPersonPanelComponent implements OnInit {
  @Input() accident: Accident;
  @Input() expanded = false;

  accidentPersonTable: TableOptions;
  nbAccidentPersons: number;

  constructor(
    private element: ElementRef,
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private fb: FormBuilder,
    private accidentPersonService: AccidentPersonService
  ) {
  }

  ngOnInit() {
    this.accidentPersonTable = {
      columnDefs: [
        {
          name: this.i18n({value: 'Personne', id: 'person'}),
          prop: 'person.fullName', width: '20%'
        },
        {
          name: this.i18n({value: 'Type', id: 'type'}),
          prop: 'witnessOrVictim', width: '10%', valueCb: (entity: AccidentPerson) => entity.witness ? 'Témoin' : 'Victime'
        },
        {
          name: this.i18n({value: 'Role', id: 'role'}),
          prop: 'role.description', width: '10%'
        },
        {
          name: this.i18n({value: 'Détail', id: 'detail'}),
          prop: 'comment', width: '35%'
        },
        {
          name: this.i18n({value: 'Impact', id: 'impact'}),
          prop: 'impact.description', width: '15%'
        },
        {
          name: this.i18n({value: 'Jours', id: 'days'}),
          prop: 'days', width: '5%'
        },
      ],
      findDataCb: (params: Params) => this.accidentPersonService.findAllAccidentPersons(this.accident.id),
      defaultSortActive: 'person',
      defaultSortDirection: ColumnSortDirection.ASC,
      actions: {
        create: [{
          btnName: 'Ajouter une personne',
          createCb: () => this.routeNameService.goTo('accident_person_add', {accidentID: this.accident.id})
        }],
        readCb: (entity: Person) => this.routeNameService.goTo('accident_person_view', {accidentID: this.accident.id, id: entity.id})
      },
    };
  }

  updateNbAccidentPersons(nbAccidentPersons: number) {
    this.nbAccidentPersons = nbAccidentPersons;
  }
}
