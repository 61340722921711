import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccidentComponent } from './pages/accident/accident.component';
import { AccidentViewComponent } from './pages/accident-view/accident-view.component';
import { AccidentListComponent } from './pages/accident-list/accident-list.component';
import { SharedModule } from '../shared/shared.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { ItemLocationsModule } from '../item-locations/item-locations.module';
import { FormsModule } from '../forms/forms.module';
import { DocumentsModule } from '../documents/documents.module';
import { ActionsAnomaliesModule } from '../actions-anomalies/actions-anomalies.module';
import { AccidentPanelComponent } from './components/accident-panel/accident-panel.component';
import { AccidentPersonsModule } from '../accident-persons/accident-persons.module';
import { AccidentCloseComponent } from './pages/accident-close/accident-close.component';


@NgModule({
  declarations: [AccidentComponent, AccidentViewComponent, AccidentListComponent, AccidentPanelComponent, AccidentCloseComponent],
  imports: [
    CommonModule,
    SharedModule,
    DashboardModule,
    ItemLocationsModule,
    FormsModule,
    DocumentsModule,
    ActionsAnomaliesModule,
    AccidentPersonsModule
  ]
})
export class AccidentsModule { }
