import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentLinkComponent } from './components/document-link/document-link.component';
import { SharedModule } from '../shared/shared.module';
import { DocumentTableComponent } from './components/document-table/document-table.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DocumentVersionComponent } from './components/document-version/document-version.component';
import { DocumentManagementComponent } from './pages/document-management/document-management.component';
import { DashboardModule } from '../dashboard/dashboard.module';

@NgModule({
  declarations: [DocumentLinkComponent, DocumentTableComponent, DocumentVersionComponent, DocumentManagementComponent],
  exports: [DocumentLinkComponent, DocumentTableComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatDialogModule,
    DashboardModule
  ]
})
export class DocumentsModule { }
