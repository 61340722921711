import { Component, Input, OnInit } from '@angular/core';
import { ItemDescription } from '../../../core/models/item-description.model';

@Component({
  selector: 'esomus-obligation-item-description-panel',
  templateUrl: './obligation-item-description-panel.component.html',
  styleUrls: ['./obligation-item-description-panel.component.sass']
})
export class ObligationItemDescriptionPanelComponent implements OnInit {

  @Input() itemDescription: ItemDescription;

  constructor() { }

  ngOnInit() {
  }

}
