<div fxLayout="row wrap" fxLayoutAlign="start center">
  <esomus-ng-select *ngIf="companyOptions" [options]="companyOptions"
                    [control]="form.get('company')"
                    i18n-label="@@company" label="Entreprise" fxFlex="100"
                    flex="100" (valueChange)="updateSite()"></esomus-ng-select>
  <esomus-ng-select *ngIf="form.get('site') && siteOptions" [options]="siteOptions"
                    [control]="form.get('site')"
                    i18n-label="@@site" label="Site" fxFlex="100"
                    flex="100" (valueChange)="updateBuilding()"></esomus-ng-select>
  <esomus-ng-select *ngIf="form.get('building') && buildingOptions" [options]="buildingOptions"
                    [control]="form.get('building')"
                    i18n-label="@@building" label="Bâtiment" fxFlex="100"
                    flex="100" (valueChange)="updateLocal()"></esomus-ng-select>
  <esomus-ng-select *ngIf="form.get('local') && localOptions" [options]="localOptions"
                    [control]="form.get('local')"
                    i18n-label="@@local" label="Local" fxFlex="100"
                    flex="100"></esomus-ng-select>
</div>
