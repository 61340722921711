import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { Observable } from 'rxjs';
import { Category } from '../models/category.model';
import { environment } from '../../../environments/environment';
import { Params } from '@angular/router';

const crudPath: CRUDPath = {
  many: `/categories/`,
  single: (id: number) => `/categories/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends CRUD<Category> {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  findOneCategory(familyID: number, categoryID: number) {
    return this.http.get(`${environment.api.url}/families/${familyID}/categories/${categoryID}`);
  }

  findAllCategories(): Observable<Category[]> {
    return this.http.get(`${environment.api.url}/families/0/categories/all`);
  }

  findAllByParent(familyID: number, params?: Params): Observable<Category[]> {
    return this.http.get(`${environment.api.url}/families/${familyID}/categories`, params);
  }

  findAllEquipments(): Observable<Category[]> {
    return this.http.get(`${environment.api.url}/families/0/categories/equipments`);
  }
}
