import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemDescriptionListComponent } from './pages/item-description-list/item-description-list.component';
import { SharedModule } from '../shared/shared.module';
import { ItemDescriptionComponent } from './pages/item-description/item-description.component';
import { ItemDescriptionViewComponent } from './pages/item-description-view/item-description-view.component';
import { FormsModule } from '../forms/forms.module';
import { DocumentsModule } from '../documents/documents.module';
import { ContactModule } from '../contact/contact.module';
import { ObligationsModule } from '../obligations/obligations.module';
import { DashboardModule } from '../dashboard/dashboard.module';

@NgModule({
  declarations: [ItemDescriptionListComponent, ItemDescriptionComponent, ItemDescriptionViewComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    DocumentsModule,
    ContactModule,
    ObligationsModule,
    DashboardModule
  ]
})
export class ItemDescriptionsModule { }
