import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'esomus-document-type-view',
  templateUrl: './document-type-view.component.html',
  styleUrls: ['./document-type-view.component.sass']
})
export class DocumentTypeViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
