import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Picture } from '../../../../core/models/picture.model';
import { PictureHelper } from '../../../../core/services/picture-helper.service';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'esomus-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.sass']
})
export class ImgComponent implements OnInit {

  @Input() picture: Picture;

  url: SafeUrl|string;

  constructor(
    private pictureHelper: PictureHelper,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.url = this.pictureHelper.generateImage(this.picture);

    this.cd.detectChanges();
  }

}
