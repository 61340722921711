<mat-accordion multi="true" class="mat-edit">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Type d'anomalie: Création
      </mat-panel-title>
    </mat-expansion-panel-header>
    <form>

      <div class="container">
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px">
          <div fxLayout="column" fxFlex="50">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-input [autofocus]="true" [type]="fieldType.TEXT"
                            [control]="entityForm.get('label')"
                            i18n-label="@@label" label="Libellé" flex="50"
                            fxFlex="100"></esomus-input>
              <esomus-ng-select *ngIf="severityOptions" [options]="severityOptions"
                                [control]="entityForm.get('severity')"
                                i18n-label="@@severity" label="Gravité"
                                [fxFlex]="100" flex="100"></esomus-ng-select>
              <esomus-ng-select *ngIf="emergencyOptions" [options]="emergencyOptions"
                                [control]="entityForm.get('emergency')"
                                i18n-label="@@emergency" label="Urgence"
                                [fxFlex]="100" flex="100"></esomus-ng-select>
              <esomus-ng-select *ngIf="entityForm.get('form')" [options]="formOptions"
                                [control]="entityForm.get('form')"
                                i18n-label="@@form" label="Formulaire" fxFlex="100"
                                flex="100"></esomus-ng-select>
              <div fxFlex="225px">
                <mat-checkbox [formControl]="entityForm.get('handled')">Gérer</mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <div fxLayout="row wrap" fxLayoutAlign="start">
            <div class="btn-group-right" fxFlex="100">
              <esomus-back-button></esomus-back-button>
              <esomus-submit (submit)="submit()"></esomus-submit>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-expansion-panel>
</mat-accordion>
