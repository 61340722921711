import { MatPaginatorIntl } from '@angular/material/paginator';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Injectable } from '@angular/core';

@Injectable()
export class PaginatorIntl extends MatPaginatorIntl {

  constructor(
    private i18n: I18n
  ) {
    super();
    this.itemsPerPageLabel = this.i18n({ value: 'Lignes par page', id: 'itemsPerPage' });
    this.nextPageLabel = this.i18n({ value: 'Page suivante', id: 'nextPage' });
    this.previousPageLabel = this.i18n({ value: 'Page précédente', id: 'previousPage' });
    this.firstPageLabel = this.i18n({ value: 'Première page', id: 'firstPage' });
    this.lastPageLabel = this.i18n({ value: 'Dernière page', id: 'lastPage' });
    this.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 ${this.i18n({ value: 'sur', id: 'of' })} ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} ${this.i18n({ value: 'sur', id: 'of' })} ${length}`;
    }
  }
}
