import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { Anomaly } from '../models/anomaly.model';
import { environment } from '../../../environments/environment';

const crudPath: CRUDPath = {
  many: `/anomalies/`,
  single: (id: number) => `/anomalies/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class AnomalyService extends CRUD<Anomaly>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  findByCompany(companyID: number) {
    return this.http.get<Array<Anomaly>>(`${environment.api.url}${this.crudPath.many}companies/${companyID}`);
  }
}
