import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyListComponent } from './pages/company-list/company-list.component';
import { CompanyComponent } from './pages/company/company.component';
import { SharedModule } from '../shared/shared.module';
import { ItemModule } from '../item/item.module';
import { EquipmentsModule } from '../equipments/equipments.module';
import { MaterialModule } from '../shared/material.module';
import { CompanyAddComponent } from './pages/company-add/company-add.component';
import { CompanyViewComponent } from './pages/company-view/company-view.component';
import { FormsModule } from '../forms/forms.module';
import { DocumentsModule } from '../documents/documents.module';
import { ContactModule } from '../contact/contact.module';
import { DashboardModule } from '../dashboard/dashboard.module';

@NgModule({
  declarations: [CompanyListComponent, CompanyComponent, CompanyAddComponent, CompanyViewComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    ItemModule,
    EquipmentsModule,
    FormsModule,
    DocumentsModule,
    ContactModule,
    DashboardModule
  ]
})
export class CompaniesModule { }
