import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ToastrMessageType, ToastrService } from '../../../core/services/toastr.service';
import { FormHelper } from '../../../core/services/form-helper.service';
import { AccidentService } from '../../../core/api/accident.service';
import { Accident } from '../../../core/models/accident.model';
import { AccidentHelper } from '../../../core/services/accident-helper.service';
import { of, Subject } from 'rxjs';
import { AccidentTypeService } from '../../../core/api/accident-type.service';
import { PersonService } from '../../../core/api/person.service';
import { ItemLocation } from '../../../core/models/item-location.model';
import { takeUntil } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormsMap, FormsMappingMap } from '../../../forms/components/form-component/form-component.component';
import { AccidentDataService } from '../../../core/api/accident-data.service';
import { EquipmentService } from '../../../core/api/equipment.service';
import { ActionService } from '../../../core/api/action.service';
import { AnomalyService } from '../../../core/api/anomaly.service';

@Component({
  selector: 'esomus-accident',
  templateUrl: './accident.component.html',
  styleUrls: ['./accident.component.sass']
})
export class AccidentComponent implements OnInit, OnDestroy {
  accident: Accident;
  accidentHelper = AccidentHelper;

  entityForm: FormGroup;
  fieldType = FieldType;
  formOptions: EntityOption;

  // statusOptions: EntityOption;
  stageOptions: EntityOption;
  typeOptions: EntityOption;
  managerOptions: EntityOption;
  insurerOptions: EntityOption;
  linkTypeOptions: EntityOption;
  linkedEquipmentOptions: EntityOption;
  linkedActionOptions: EntityOption;
  linkedAccidentOptions: EntityOption;
  linkedAnomalyOptions: EntityOption;

  onTheWayToWork: boolean;

  companyEmitter: EventEmitter<any>;
  companyID: number;
  private destroyed$: Subject<void>;

  forms: FormsMap;
  formsMapping: FormsMappingMap;
  formsValidation: boolean[];
  nbForms: number;

  maxDate: Date;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private accidentService: AccidentService,
    private accidentTypeService: AccidentTypeService,
    private personService: PersonService,
    private accidentDataService: AccidentDataService,
    private equipmentService: EquipmentService,
    private actionService: ActionService,
    private anomalyService: AnomalyService,
  ) {
    this.destroyed$ = new Subject<void>();
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      // status: [null, [Validators.required]],
      insurerReference: [null],
      stage: [null, [Validators.required]],
      description: [null, [Validators.required]],
      placeDescription: [null],
      comment: [null],
      insurer: [null],
    });

    // this.statusOptions = {get: () => of(AccidentHelper.getAccidentStatusOptions()), propName: 'label'};
    this.stageOptions = {get: () => of(AccidentHelper.getAccidentStageOptions()), propName: 'label'};
    this.insurerOptions = {get: () => this.personService.findCompanies({type: 'accident'}), propName: 'fullName'};

    const id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    if (!isNaN(id)) {
      this._getAccident(id);
    } else {
      this.accident = new Accident();
      this.accident.status = 0;
      this.accident.location = new ItemLocation();
      this.accident.location.company = null;
      this.accident.location.site = null;
      this.accident.location.building = null;
      this.accident.location.local = null;
      this.onTheWayToWork = false;
      this.maxDate = new Date();

      this.entityForm.addControl('location', this.fb.group({}));
      this.entityForm.addControl('onTheWayToWork', this.fb.control(null));
      this.entityForm.addControl('date', this.fb.control(null, [Validators.required]));
      this.entityForm.addControl('type', this.fb.control(null, [Validators.required]));
      this.typeOptions = {get: () => this.accidentTypeService.findAll('', {type: 'accident'}), propName: 'description'};

      this.companyEmitter = new EventEmitter<any>();
      this.companyEmitter.pipe(takeUntil(this.destroyed$)).subscribe((value: any) => {
        if (this.entityForm.contains('manager')) {
          this.entityForm.removeControl('manager');
          this.managerOptions = null;
          this.cd.detectChanges();
        }

        this.companyID = value;

        this._link();

        if (value === null) {
          return;
        }

        this._manager(value);
      });

      this.cd.detectChanges();
    }
  }

  private _manager(value: any) {
    this.entityForm.addControl('manager', this.fb.control(this.accident.manager ? this.accident.manager.id : null, [Validators.required]));
    this.managerOptions = {
      get: () => this.personService.getCompanyPersons(value, {type: 'accident'}),
      propName: 'fullName', autoSelect: true
    };
    this.cd.detectChanges();
  }

  private _link() {
    if (this.accident.linkType !== null && this.accident.linkType !== undefined) {
      this.entityForm.addControl('linkedDescription', this.fb.control(null));
      return;
    }

    this.linkTypeOptions = null;
    if (this.entityForm.contains('linkType')) {
      this.entityForm.removeControl('linkType');
    }
    if (this.entityForm.contains('linkedDescription')) {
      this.entityForm.removeControl('linkedDescription');
    }

    if (!this.companyID) {
      return;
    }

    this.linkTypeOptions = {get: () => of(AccidentHelper.getAccidentLinkOptions()), propName: 'label'};
    this.entityForm.addControl('linkType', this.fb.control(null));
    this.entityForm.addControl('linkedDescription', this.fb.control(null));
  }

  linkTypeUpdate(value: number) {
    let possibleControls = ['linkedEquipment', 'linkedAction', 'linkedAccident', 'linkedAnomaly'];
    for (let control of possibleControls) {
      if (this.entityForm.contains(control)) {
        this.entityForm.removeControl(control);
      }
    }

    if (!this.companyID) {
      return;
    }

    switch (value) {
      case 0:
        this.linkedEquipmentOptions = {get: () => this.equipmentService.findByCompany(this.companyID), autoSelect: true, propName: 'name'};
        this.entityForm.addControl('linkedEquipment', this.fb.control(null));
        break;
      case 1:
        this.linkedAnomalyOptions = {
          get: () => this.anomalyService.findByCompany(this.companyID),
          autoSelect: true,
          propName: 'description'
        };
        this.entityForm.addControl('linkedAnomaly', this.fb.control(null));
        break;
      case 2:
        this.linkedActionOptions = {
          get: () => this.actionService.findByCompany(this.companyID),
          autoSelect: true,
          propName: 'description'
        };
        this.entityForm.addControl('linkedAction', this.fb.control(null));
        break;
      case 3:
        this.linkedAccidentOptions = {
          get: () => this.accidentService.findByCompany(this.companyID),
          autoSelect: true,
          propName: 'description'
        };
        this.entityForm.addControl('linkedAccident', this.fb.control(null));
        break;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private _getAccident(id: number) {
    this.accidentService.find(id).subscribe((result: Accident) => {
      this.accident = result;

      // QUERY

      this.companyID = this.accident.location.company.id;
      this.onTheWayToWork = this.accident.onTheWayToWork;
      this._manager(this.accident.location.company.id);

      if (this.accident.type.form) {
        this.forms = {};
        this.formsMapping = {};
      }

      this._link();

      FormHelper.initValues(this.accident, this.entityForm);
      this.cd.detectChanges();
    });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    let types = {};

    if (!this.accident.id) {
      types['date'] = {type: FieldType.DATE};
      types['location'] = {type: FieldType.GROUP};
    } else {
      this.nbForms = 1;
      this.formsValidation = [];

      for (const key in this.forms) {
        if (this.forms.hasOwnProperty(key)) {
          this.nbForms++;
          if (this.forms[key].invalid) {
            return;
          }
        }
      }

      for (const key in this.formsMapping) {
        if (this.formsMapping.hasOwnProperty(key)) {
          const entityData = FormHelper.buildEntity(this.formsMapping[key], this.forms[key], {});
          FormHelper.submitForm(
            this.cd,
            this.forms[key],
            this.accidentDataService.putData(entityData, this.accident.id, parseInt(key.substr(5), 10)),
            () => {
              this.formsValidation.push(true);
              this._handleFormsSubmit();
            }
          );
        }
      }
    }

    const entity = FormHelper.buildEntity(this.accident, this.entityForm, types) as Accident;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      (this.accident.id) ? this.accidentService.put(entity) : this.accidentService.post(entity),
      (result: Accident) => {
        if (this.accident.id) {
          this.formsValidation.push(true);
          this._handleFormsSubmit();
        } else {
          this.toastrService.open(ToastrMessageType.CREATE);
          this.routeNameService.goTo('accident_view', {id: result.id});
        }
      }
    );
  }

  private _handleFormsSubmit() {
    if (this.formsValidation.length === this.nbForms) {
      this.toastrService.open(ToastrMessageType.UPDATE);
      this.entityForm.reset();
      this.routeNameService.goTo('accident_view', {id: this.accident.id});
    }
  }

  getAccidentDeleteURL() {
    return this.accidentService.delete(this.accident.id);
  }

  getAccidentSuccessURL() {
    return this.routeNameService.path('accidents');
  }

  getAccidentStatusLabel() {
    return AccidentHelper.getAccidentStatusLabel(this.accident.status);
  }

  changeOnTheWayToWork(event: MatCheckboxChange) {
    this.onTheWayToWork = event.checked;
    this.cd.detectChanges();
  }

  getFetchDataURL(formID: number) {
    return this.accidentDataService.getData(this.accident.id, formID);
  }
}
