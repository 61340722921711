<mat-accordion multi="true" *ngIf="equipment">
  <esomus-equipment-panel [expanded]="false" [equipment]="equipment"
                          [panelDisabled]="true"></esomus-equipment-panel>
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Portée
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px grid">
        <div fxLayout="column" fxFlex="50">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <esomus-ng-select *ngIf="companyOptions" [options]="companyOptions"
                              [control]="scopeForm.get('company')"
                              i18n-label="@@company" label="Entreprise" fxFlex="100"
                              flex="100" (valueChange)="updateSite()"></esomus-ng-select>
            <esomus-ng-select *ngIf="scopeForm.get('site')" [options]="siteOptions"
                              [control]="scopeForm.get('site')"
                              i18n-label="@@site" label="Site" fxFlex="100"
                              flex="100" (valueChange)="updateBuilding()"></esomus-ng-select>
            <esomus-ng-select *ngIf="scopeForm.get('building')" [options]="buildingOptions"
                              [control]="scopeForm.get('building')"
                              i18n-label="@@building" label="Bâtiment" fxFlex="100"
                              flex="100" (valueChange)="updateLocal()"></esomus-ng-select>
            <esomus-ng-select *ngIf="scopeForm.get('local')" [options]="localOptions"
                              [control]="scopeForm.get('local')"
                              i18n-label="@@local" label="Local" fxFlex="100"
                              flex="100"></esomus-ng-select>
          </div>
        </div>
        <div fxLayout="column" fxFlex="50">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <esomus-ng-select *ngIf="familyOptions" [options]="familyOptions"
                              [control]="scopeForm.get('family')"
                              i18n-label="@@family" label="Famille" fxFlex="100"
                              flex="100" (valueChange)="updateCategory()"></esomus-ng-select>
            <esomus-ng-select *ngIf="categoryOptions" [options]="categoryOptions"
                              [control]="scopeForm.get('category')"
                              i18n-label="@@category" label="Catégorie" fxFlex="100"
                              flex="100"></esomus-ng-select>
            <esomus-ng-select *ngIf="situationOptions" [options]="situationOptions"
                              [control]="scopeForm.get('situation')"
                              i18n-label="@@situation" label="Situation" fxFlex="100"
                              flex="100" [multiple]="true"></esomus-ng-select>
          </div>
        </div>
        <div fxLayout="column" fxFlex="100">
          <div fxLayout="row wrap" fxLayoutAlign="end center">
            <button type="button" (click)="search()" color="success" mat-flat-button>
              Rechercher
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Equipements disponible :&nbsp;<span *ngIf="nbEquipments !== null">{{nbEquipments}}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <esomus-table [options]="equipmentTable" [searchReceiver]="equipmentTableEmitter"
                  (getLength)="updateNbEquipments($event)"></esomus-table>
  </mat-expansion-panel>
  <esomus-equipment-table [expanded]="true" [link]="equipment"
                          [parentID]="equipment.id" parent="link"
                          label="Equipements liés"></esomus-equipment-table>
</mat-accordion>

<ng-template #familyCategoryCell let-row>
  <span>{{row.family.label}} / {{row.category.label}}</span>
</ng-template>
