import { NgModule } from '@angular/core';
import { RouteNamePipe } from './route-name.pipe';

@NgModule({
  declarations: [
    RouteNamePipe
  ],
  exports: [
    RouteNamePipe
  ]
})
export class PipeModule { }
