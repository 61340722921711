import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Action } from '../models/action.model';

const crudPath: CRUDPath = {
  many: `/actions/`,
  single: (id: number) => `/actions/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class ActionService extends CRUD<Action>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  findByCompany(companyID: number) {
    return this.http.get<Array<Action>>(`${environment.api.url}${this.crudPath.many}companies/${companyID}`);
  }

  postFromAnomaly(entity: Action): Observable<Action> {
    const body = this._createFormData(entity);
    return this.http.post<Action>(`${environment.api.url}${this.crudPath.many}anomaly`, body);
  }

  postFromEquipment(entity: Action): Observable<Action> {
    const body = this._createFormData(entity);
    return this.http.post<Action>(`${environment.api.url}${this.crudPath.many}equipment`, body);
  }

  postFromAccident(entity: Action): Observable<Action> {
    const body = this._createFormData(entity);
    return this.http.post<Action>(`${environment.api.url}${this.crudPath.many}accident`, body);
  }

  putFromAnomaly(entity: Action): Observable<Action> {
    const body = this._createFormData(entity);
    body.set('_method', 'PUT');
    return this.http.put<Action>(`${environment.api.url}${this.crudPath.single(entity.id)}/anomaly`, body);
  }

  putFromEquipment(entity: Action): Observable<Action> {
    const body = this._createFormData(entity);
    body.set('_method', 'PUT');
    return this.http.put<Action>(`${environment.api.url}${this.crudPath.single(entity.id)}/equipment`, body);
  }

  putFromAction(entity: Action): Observable<Action> {
    const body = this._createFormData(entity);
    body.set('_method', 'PUT');
    return this.http.put<Action>(`${environment.api.url}${this.crudPath.single(entity.id)}/action`, body);
  }

  putRealisation(entity: Action): Observable<Action> {
    const body = this._createFormData(entity);
    body.set('_method', 'PUT');
    return this.http.put<Action>(`${environment.api.url}${this.crudPath.single(entity.id)}/realisation`, body);
  }
}
