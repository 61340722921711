<mat-accordion multi="true" class="mat-edit">
  <mat-expansion-panel expanded="true" *ngIf="building">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Batîment: Edition
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <form>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px">
          <div fxLayout="column" fxFlex="30" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-img fxFlex="100" [picture]="building.picture"></esomus-img>
              <esomus-input-file accept="image/*" [control]="entityForm.controls['picture.upload']"
                                 i18n-label="@@picture" label="Photo" fxFlex="100"
                                 flex="100"></esomus-input-file>
              <esomus-input [type]="fieldType.TEXT"
                            [control]="entityForm.get('code')"
                            i18n-label="@@esomusCode" label="Code esomus" flex="100"
                            fxFlex="100"></esomus-input>
            </div>
          </div>
          <div fxLayout="column" fxFlex>
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-line-data flex="50" fxFlex="100" boldValue="Catégorie" [textValue]="building.category.label"></esomus-line-data>
              <esomus-line-data flex="50" fxFlex="100" boldValue="Fiche" [textValue]="building.itemDescription.label"></esomus-line-data>
              <esomus-input [autofocus]="true" [type]="fieldType.TEXT" [control]="entityForm.get('description')"
                            i18n-label="@@name" label="Nom" flex="50"
                            fxFlex="100"></esomus-input>
              <esomus-input [type]="fieldType.TEXT" [control]="entityForm.controls['address.line1']"
                            i18n-label="@@line1" label="Adresse" flex="50"
                            fxFlex="100"></esomus-input>
              <esomus-input [type]="fieldType.TEXT" [control]="entityForm.controls['address.coordinates']"
                            i18n-label="@@coordinates" label="Coordonnées" flex="50"
                            fxFlex="100"></esomus-input>
              <esomus-textarea [control]="entityForm.get('comment')" i18n-label="@@comment"
                               label="Commentaire" flex="100" fxFlex="100"></esomus-textarea>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel *ngIf="(building.category.form || building.itemDescription.form) && forms"
                       expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Information complémentaire
      </mat-panel-title>
    </mat-expansion-panel-header>
    <esomus-form-component *ngIf="building.category.form" [formID]="building.category.form.id"
                           [forms]="forms" [formsMapping]="formsMapping"
                           [fetchDataURL]="getFetchDataURL(building.category.form.id)"></esomus-form-component>
    <esomus-form-component *ngIf="building.itemDescription.form"
                           [formID]="building.itemDescription.form.id" [forms]="forms"
                           [formsMapping]="formsMapping"
                           [fetchDataURL]="getFetchDataURL(building.itemDescription.form.id)"></esomus-form-component>
  </mat-expansion-panel>
  <div class="btn-group-right">
    <esomus-back-button></esomus-back-button>
    <esomus-submit (submit)="submit()"></esomus-submit>
  </div>
</mat-accordion>
