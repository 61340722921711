<div class="info-box info-box-{{color}}" [fxFlex]="flex">
  <div fxFlex="100">
    <p class="info-box-text">
      <ng-container *ngIf="actionAvailable && actionCb && actionAvailable(); else noLink">
        <a class="text-underline text-pointer" (click)="actionCb()">{{line1}}</a>
      </ng-container>
      <ng-template #noLink>
        <span>{{line1}}</span>
      </ng-template>
      <ng-container *ngIf="date1">
        <br>{{date1}}
      </ng-container>
    </p>
  </div>
</div>
