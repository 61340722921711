import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import * as fromRoot from '../../reducers/index';
import * as layout from '../../shared/layout.action';
import { componentDestroyed } from '../../utils/component-destroyed';

@Component({
  selector: 'esomus-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {

  cardElevationClass: string;

  constructor(
    private store: Store<fromRoot.State>
  ) {
  }

  ngOnInit() {
    this.store.select(fromRoot.getCardElevation).pipe(
      takeUntil(componentDestroyed(this))
    ).subscribe((elevation: string) => {
      this.cardElevationClass = elevation;
    });
  }

  setLayout(radioEvent: MatRadioChange) {
    this.store.dispatch(new layout.SelectLayoutAction(radioEvent.value));
  }

  setCardElevation(selectEvent: MatSelectChange) {
    this.store.dispatch(new layout.SetCardElevationAction(selectEvent.value));
  }

  ngOnDestroy() {
  }
}
