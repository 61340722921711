import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../api/auth.service';
import { ToastrMessageType, ToastrService } from '../services/toastr.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private toastrService: ToastrService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((err) => {
      switch (err.status) {
        case 400:
          if (this.authService.getToken() === null) {
            this.authService.logout();
          }
          break;
        case 401:
          this.authService.logout(true);
          break;
        case 500:
          this.toastrService.open(ToastrMessageType.ERROR_500);
          break;
      }
      return throwError(err);
    }));
  }
}
