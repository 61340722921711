<mat-accordion multi="true" class="mat-edit">
  <mat-expansion-panel expanded="true" *ngIf="taskType">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Type de tâche: {{taskType.id ? 'Edition' : 'Création'}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <form>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px">
          <esomus-input [autofocus]="true" [type]="formFieldType.TEXT"
                        [control]="entityForm.get('label')"
                        i18n-label="@@label" label="Libellé" flex="50" fxFlex="100"></esomus-input>
          <mat-checkbox [formControl]="entityForm.get('anomaly')" fxFlex="50">Anomalie
          </mat-checkbox>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <div class="btn-group-left" fxFlex="50">
                <esomus-delete-button *ngIf="taskType.id && taskType.active"
                                      [url]="getTaskTypeDeleteURL()"
                                      [successUrl]="getTaskTypeSuccessURL()"></esomus-delete-button>
              </div>
              <div class="btn-group-right" fxFlex="50">
                <esomus-back-button></esomus-back-button>
                <esomus-submit (submit)="submit()"></esomus-submit>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>
