import { Component, Input, OnInit } from '@angular/core';
import { FormField, FormFieldOptions } from '../form-field';

@Component({
  selector: 'esomus-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.sass']
})
export class SelectComponent extends FormField implements OnInit {

  @Input() multiple: boolean;
  @Input() options: SelectOption[];

  constructor() {
    super();
    this.multiple = false;
    this.options = [];
  }

  ngOnInit() {
  }

}

/**
 * Basic <select></select>
 */
export class SelectField extends FormFieldOptions {
  type: 'select';
  options: SelectOption[];
}

export class SelectOption {
  name: string;
  value: any;
}
