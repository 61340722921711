import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { Obligation } from '../models/obligation.model';

const crudPath: CRUDPath = {
  many: `/obligations/`,
  single: (id: number) => `/obligations/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class ObligationService extends CRUD<Obligation>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }
}
